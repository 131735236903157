import React from 'react'
import { string, arrayOf, shape, bool, func } from 'prop-types'
import { Field, reduxForm, reset } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import Wrapper from 'components/shared/Wrapper'
import Page from 'components/Page/Page'
import Title from 'components/shared/Navigation/Title'
import Button from 'components/shared/Button'
import Banner from 'components/shared/Banner'
import Select from 'components/shared/Form/Select'
import Input from 'components/shared/Form/Input'
import TextArea from 'components/shared/Form/TextArea'
import * as validators from 'components/shared/Form/validators'
import * as clientContact from 'http/contact'
import { notifyError, notifySuccess } from 'utils/notify'
import errorToMessage from 'utils/errorToMessage'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'
import media from 'utils/media'

const DESCRIPTION_MAX_LENGTH = 350

const H1 = styled.h1`
  color: #4A4A4A;
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  ${media.tablet`
    font-size: 30px;
  `}
}
`

const messageValidator = validators.textAreaValidator(
  i18n('missing-message'),
  DESCRIPTION_MAX_LENGTH
)

const doContact = onSuccess => async data => {
  try {
    await clientContact.contact({
      ...data,
      topic: data.topic.id
    })
    onSuccess()
    notifySuccess(i18n('contact-success'))
  } catch (error) {
    notifyError(errorToMessage(error))
  }
}

const Contact = ({
  topics,
  invalid,
  handleSubmit,
  error,
  submitting,
  reset
}) => (
  <Page>
    <Wrapper>
      <Title title={`${i18n('contact').toUpperCase()}`} />
      <Wrapper>
        <Banner src="images/backgrounds/contact.jpg" />
      </Wrapper>
      <Row>
        <Col xs={12} sm={6} smOffset={3}>
          <Row>
            <Col xs={12}>
              <Wrapper>
                <H1>{i18n('contact-us')}</H1>
              </Wrapper>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <form name="contact" onSubmit={handleSubmit(doContact(reset))}>
                {error && i18n('contact-form-error')(error)}
                <Wrapper>
                  <Field
                    name="name"
                    type="text"
                    component={Input}
                    label={i18n('firstname')}
                    validate={[validators.fullNameValidator]}
                    disabled={submitting}
                  />
                  <Field
                    name="organisation"
                    type="text"
                    component={Input}
                    label={i18n('organisation-name')}
                    validate={[validators.companyNameValidator]}
                    disabled={submitting}
                  />
                  <Field
                    name="email"
                    type="email"
                    component={Input}
                    label={i18n('email')}
                    validate={[validators.emailValidator]}
                    disabled={submitting}
                  />
                  <Field
                    name="topic"
                    label={i18n('topic')}
                    placeholder={i18n('topic-placeholder')}
                    component={Select}
                    options={topics.map(({ name }) => ({ id: name, name }))}
                    validate={[validators.contactTopicValidator]}
                    disabled={submitting}
                  />
                  <Field
                    name="message"
                    type="textarea"
                    rows="8"
                    component={TextArea}
                    placeholder={`${DESCRIPTION_MAX_LENGTH} ${i18n(
                      'word-limit'
                    )}`}
                    label={i18n('message')}
                    validate={messageValidator}
                    disabled={submitting}
                  />
                </Wrapper>
                <Row>
                  <Col xs={12} sm={6} smOffset={6}>
                    <Button type="submit" disabled={invalid || submitting}>
                      {i18n(submitting ? 'sending' : 'send')}
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  </Page>
)

Contact.propTypes = {
  invalid: bool.isRequired,
  handleSubmit: func.isRequired,
  error: string,
  submitting: bool.isRequired,
  reset: func.isRequired,
  topics: arrayOf(shape(slugShape)).isRequired
}

const mapStateToProps = ({ data: { topics } }) => ({
  topics
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      reset: () => reset('contact')
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'contact'
  })(Contact)
)
