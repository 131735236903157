const newBusiness = {
  'add-business-button': 'Add your organisation',
  'new-business': 'New Organisation',
  'get-started': "Let's get started",
  'about-business-description':
    'First, we need to gather a few details about the organisation. This will be presented on your profile',
  poc: 'About Point of Contact',
  'poc-description':
    "We want to know a bit about the organisation's point of contact. This will not be displayed on your organisation profile.",
  'select-organisation-label': 'Select organisations you are a member of',
  'business-name': 'Organisation Name',
  'abn-acn': 'ABN or ACN',
  'missing-company-number': 'Please enter your 11-digit ABN',
  'business-email': 'Organisation Email',
  'personal-email': 'Personal Email',
  'business-website': 'Organisation Website',
  'invalid-link': 'Please enter a valid website',
  'business-phone': 'Organisation Phone Number',
  'missing-organisation-phone': 'Please enter your organisation phone number',
  'missing-organisation-phone-or-email': 'Please enter either phone or email ',
  'established-year': 'Year organisation was established',
  'business-size': 'Organisation Size',
  'size-placeholder': 'Select size',
  'missing-size': 'Please select your organisation size',
  description:
    'Make your profile stand out by telling us what makes you different',
  'missing-description': 'Please enter your description',
  'invalid-year': 'Please enter a valid year',
  'business-location': 'Organisation Location',
  'business-location-description':
    'Add your organisations, manufacturing and research locations',
  'business-capabilities': 'Organisation Capabilities',
  'business-capabilities-description':
    'These details will allow other organisations to find you on the directory',
  'industry-sector': num => `Select your industry sector (Select up to ${num})`,
  'select-categories': 'Add your product or service categories',
  'category-description':
    'A product category is a type of product or service (e.g. dairy, seafood, branding, food safety, rail transport)',
  'categories-placeholder': 'Add up to 10 categories',
  'product-categories': 'Product Categories',
  submit: 'Submit',
  'update-business-success': 'Organisation has been updated!',
  'update-business-success-description':
    'The business will receive an email to notify them they have been updated',
  'new-business-success': 'Your request has been sent!',
  'new-business-success-description':
    'Our team will be reviewing your submission within the next 3 business days. In the meantime, you can explore The Australian Food and Agribusiness Directory',
  'admin-new-business-success': 'Organisation has been added!',
  'admin-new-business-success-description':
    'The business will receive an email to notify them they have been added',
  'new-business-error': "We're sorry. There's been an error",
  explore: 'Explore the Directory!',
  'add-another-organisation': 'Add another organisation',
  'i-agree': 'I agree to the',
  'must-agree-terms': 'You must agree to the terms and conditions'
}

export default newBusiness
