const organisation = {
  'organisation-not-found-header': 'Organisation not found',
  'organisation-not-found-description': id =>
    `The organsation ${id} does not exists`,
  'back-to-search-results': '< Back to search results',
  categories: 'Categories',
  'year-established': 'Year established',
  'organisation-size': 'Organisation Size',
  'disable-account': 'Disable Account',
  'disable-organisation-modal-title': name => `Disable ${name}'s profile`,
  'disable-organisation-modal-description':
    "Once disabled, the organisation will be stored in the 'disabled organisations' tab in the admin panel and the organisation will be notifed about their profile",
  'reason-for-disabling-account': 'Reason for disabling account',
  comment: 'Comment',
  disable: 'Disable',
  disabling: 'Disabling',
  'disable-organisation-success': 'Profile disabled',
  'disable-organisation-success-description': name =>
    `You have successfully disabled ${name}'s profile. They will be sent an email notifying them.`,
  'similar-organisations-label': 'You may also be interested in:'
}

export default organisation
