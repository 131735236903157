import React from 'react'
import { array, object } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import FlexRow from 'components/shared/FlexRow'
import FlexCol from 'components/shared/FlexCol'
import OrganisationCell from 'components/shared/OrganisationCell'
import Label from 'components/shared/Label'
import i18n from 'i18n'

const StyledLabel = styled(Label)`
  font-size: 30px;
  line-height: 41px;
`

const SimilarOrganisations = ({ organisations, history }) => (
  <Row>
    <Col xs={12}>
      <Row>
        <Col xs={12}>
          <StyledLabel>{i18n('similar-organisations-label')}</StyledLabel>
        </Col>
      </Row>
      <FlexRow>
        {organisations.map(entity => (
          <FlexCol key={entity.id} xs={12} sm={4} md={3}>
            <OrganisationCell
              onHeaderClick={() => history.push(`/organisation/${entity.id}`)}
              entity={entity}
            />
          </FlexCol>
        ))}
      </FlexRow>
    </Col>
  </Row>
)

SimilarOrganisations.propTypes = {
  history: object.isRequired,
  organisations: array.isRequired
}

export default SimilarOrganisations
