import React from 'react'
import { object } from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import LocationIcon from './LocationIcon'
import Span from './Span'

const ContactInfo = ({ organisation }) => (
  <div>
    {organisation.locationAddresses.map(({ value }) => (
      <Row key={value.latitude + value.longitude}>
        <Col xs={12}>
          <LocationIcon />
          <Span small>{`${value.postal.suburb}, ${value.postal.state}`}</Span>
        </Col>
      </Row>
    ))}
    {organisation.websiteAddresses.length ? (
      <Row>
        <Col xs={12} style={{ paddingLeft: '27px' }}>
          <a
            href={`${
              organisation.websiteAddresses[0].value.startsWith('http')
                ? ''
                : 'https://'
            }${organisation.websiteAddresses[0].value}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Span small>{organisation.websiteAddresses[0].value}</Span>
          </a>
        </Col>
      </Row>
    ) : null}
    {organisation.emailAddresses.length ? (
      <Row>
        <Col xs={12} style={{ paddingLeft: '27px' }}>
          <Span small>{organisation.emailAddresses[0].value}</Span>
        </Col>
      </Row>
    ) : null}
    {organisation.phoneAddresses.length ? (
      <Row>
        <Col xs={12} style={{ paddingLeft: '27px' }}>
          <Span small>{organisation.phoneAddresses[0].value}</Span>
        </Col>
      </Row>
    ) : null}
  </div>
)

ContactInfo.propTypes = {
  organisation: object.isRequired
}

export default ContactInfo
