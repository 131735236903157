import { createActions } from 'redux-actions'

export const {
  admin: {
    loadEntities,
    start,
    finish,
    loadSuccess,
    loadFailure,
    changeFilter,
    changeFilterType,
    changeFilterText,
    changeAffiliate,
    toggleApproving,
    toggleRejecting,
    toggleDisabling,
    toggleEnabling,
    changePage,
    updateEntities,
    changeEntities
  }
} = createActions({
  // to update the status of entities
  '@admin/updateEntities': undefined,
  // to update the filtered entities
  '@admin/changeEntities': entities => ({ entities }),
  '@admin/changeFilter': filter => ({ filter }),
  '@admin/changeFilterType': type => type,
  '@admin/changeFilterText': text => text,
  '@admin/changeAffiliate': (status, index, affiliate) => ({
    status,
    index,
    affiliate
  }),
  '@admin/changePage': (status, page) => ({ status, page }),
  '@admin/toggleApproving': index => ({
    index
  }),
  '@admin/toggleRejecting': index => ({
    index
  }),
  '@admin/toggleDisabling': index => ({
    index
  }),
  '@admin/toggleEnabling': index => ({
    index
  }),
  '@admin/loadEntities': undefined,
  '@admin/start': undefined,
  '@admin/finish': undefined,
  '@admin/loadSuccess': entities => ({ entities }),
  '@admin/loadFailure': error => ({ error })
})
