import React from 'react'
import { arrayOf, shape, func, bool, string } from 'prop-types'
import CreatableSelect from 'react-select/lib/Creatable'

import ControlComponent from './ControlComponent'
import { slugShape } from 'utils/shapes'

const KeywordControlComponent = props => (
  <ControlComponent {...props} icon="search" />
)

class KeywordSearch extends React.Component {
  input = React.createRef()

  state = {
    expanded: false
  }

  onFocus = () => {
    if (!this.props.onFocus || !this.props.onFocus()) {
      // the event can be consumed
      this.setState({
        expanded: true
      })
    }
  }

  onBlur = () => {
    this.setState({
      expanded: false
    })
  }

  getValue = () => {
    const { value } = this.props
    if (this.state.expanded || !this.input.current) {
      return value
    }

    const ret = []
    const maxWidth = this.input.current.offsetWidth - 100 // reserved for More element

    let width = 0
    for (let i = 0; i < value.length; i++) {
      width += value[i].name.length * 8 + 35 // the width of a value
      if (width > maxWidth) {
        ret.push({
          id: 'more',
          name: `${value.length - i} more`
        })
        break
      }
      ret.push(value[i])
    }

    return ret
  }

  render() {
    const {
      placeholder,
      getOptionLabel,
      getOptionValue,
      filterOption,
      onCreateOption,
      getNewOptionData,
      isValidNewOption,
      onChange,
      options,
      onNavigationBar,
      noBorder
    } = this.props

    return (
      <div ref={this.input}>
        <CreatableSelect
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          hideSelectedOptions={true}
          onChange={onChange}
          getNewOptionData={getNewOptionData}
          isValidNewOption={isValidNewOption}
          onCreateOption={onCreateOption}
          createOptionPosition="first"
          filterOption={filterOption}
          placeholder={placeholder}
          value={this.getValue()}
          options={options}
          isMulti
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          noOptionsMessage={() => null}
          styles={{
            control: provided => ({
              ...provided,
              borderRadius: noBorder ? '0' : '25px 0 0 25px',
              border: 'none',
              paddingLeft: '5em',
              padding: '0.5em 0.9375em 0.5em 2.5em',
              background: `${onNavigationBar ? '#f3f3f3' : '#fff'}`,
              boxSizing: 'border-box',
              '&::placeholder': {
                transition: 'color 0.25s',
                color: '#abacac',
                '-webkit-font-smoothing': 'antialias'
              },
              boxShadow: 'none'
            }),
            input: provided => ({
              ...provided,
              order: 1
            }),
            multiValue: provided => ({
              ...provided,
              order: 2,
              backgroundColor: `${onNavigationBar ? '#fff' : '#f3f3f3'}`,
              boxShadow: 'rgba(0, 0, 0, 0.125) 0px 3px 5px',
              border: 'none',
              borderRadius: '25px'
            }),
            multiValueLabel: (provided, state) => ({
              ...provided,
              color: state.data.id === 'more' ? '#56ba47' : '#000'
            }),
            multiValueRemove: (provided, state) => ({
              ...provided,
              color: '#56ba47',
              display: state.data.id === 'more' ? 'none' : provided.display
            }),
            option: provided => ({
              ...provided,
              borderBottom: '1px solid #f3f3f3',
              textAlign: 'left'
            }),
            menuList: provided => ({
              ...provided,
              padding: '0px 5px'
            })
          }}
          components={{
            Control: KeywordControlComponent,
            DropdownIndicator: null,
            ClearIndicator: null
          }}
        />
      </div>
    )
  }
}

KeywordSearch.propTypes = {
  placeholder: string,
  onFocus: func,
  getOptionLabel: func.isRequired,
  getOptionValue: func.isRequired,
  filterOption: func,
  onCreateOption: func.isRequired,
  getNewOptionData: func.isRequired,
  isValidNewOption: func.isRequired,
  onChange: func.isRequired,
  onNavigationBar: bool,
  noBorder: bool,
  options: arrayOf(shape(slugShape)).isRequired,
  value: arrayOf(shape(slugShape)).isRequired
}

export default KeywordSearch
