import React from 'react'
import { arrayOf, string } from 'prop-types'
import styled from 'styled-components'

import Label from 'components/shared/Label'

const StyledLabel = styled(Label)`
  color: #ccc;
  font-size: 25px;
  line-height: 28px;
  font-weight: 500;
`

const IndustrySectors = ({ industries }) => (
  <StyledLabel>{industries.join(', ')}</StyledLabel>
)

IndustrySectors.propTypes = {
  industries: arrayOf(string).isRequired
}

export default IndustrySectors
