import { takeEvery, put, select } from 'redux-saga/effects'

import {
  search,
  startSearching,
  searchSuccess,
  searchFailure,
  changeIndustryFilter,
  changeSizeFilter,
  updateFilters,
  updateFilteredResult,
  setIndustryFilter,
  changeIndustryKeyword,
  changeCategoryKeyword,
  changeNameKeyword,
  changeLocationKeyword,
  updateKeywords,
  changePage,
  updatePageData
} from './actions'
import { updateData } from 'redux/data/actions'
import { notifyError } from 'utils/notify'
import errorToMessage from 'utils/errorToMessage'

import { searchEntities, getPopularEntities } from 'http/entity'
import { getIndustries, getSizes } from 'http/data'

const ITEMS_PER_PAGE = 9

const getFilters = state => state.search.filters
const getKeywords = state => state.search.keywords
const getResult = state => state.search.result

const filterEntities = ({ industries, sizes }) => entity => {
  if (industries.length > 0) {
    if (
      !entity.industries.some(industry => {
        return industries.some(({ id }) => id === industry.id)
      })
    ) {
      return false
    }
  }

  if (sizes.length > 0) {
    if (!sizes.some(size => entity.size && size.id === entity.size.id)) {
      return false
    }
  }

  return true
}

const doChangeFilter = filter => {
  return function*({ payload }) {
    const { all } = yield select(getResult)
    const filters = yield select(getFilters)
    const newFilter = [...filters[filter]]

    if (payload.isAdded) {
      newFilter.push(payload[filter])
    } else {
      newFilter.splice(newFilter.indexOf(payload[filter]), 1)
    }

    // update filters
    const newFilters = {
      ...filters,
      [filter]: newFilter
    }
    yield put(updateFilters(newFilters))
    const filtered = all.filter(filterEntities(newFilters))
    // Change Requests 20190313 not showing the result of popular search
    const pageData = createPageData(0, filtered)
    yield put(
      updateFilteredResult({
        filtered,
        ...pageData
      })
    )
  }
}

function* doSetIndustryFilter({ payload: { industry } }) {
  const filters = yield select(getFilters)
  const newFilters = {}
  for (let key in filters) newFilters[key] = []

  newFilters.industries = [industry]
  yield put(updateFilters(newFilters))

  yield put(search())
}

const doChangeKeyword = keyword => {
  return function*({ payload }) {
    const keywords = yield select(getKeywords)
    const newKeywords = [...keywords[keyword]]
    const allKeywords = [...keywords.all]

    if (payload.isAdded) {
      newKeywords.push(payload[keyword])
      allKeywords.unshift(payload[keyword])
    } else {
      newKeywords.splice(newKeywords.indexOf(payload[keyword]), 1)
      allKeywords.splice(allKeywords.indexOf(payload[keyword]), 1)
    }

    // update keywords
    yield put(
      updateKeywords({
        ...keywords,
        [keyword]: newKeywords,
        all: allKeywords
      })
    )
  }
}

function* doChangeLocationKeyword({ payload: { locations } }) {
  const keywords = yield select(getKeywords)

  yield put(
    updateKeywords({
      ...keywords,
      locations: [locations]
    })
  )
}

function* doSearch() {
  try {
    yield put(startSearching())
    const { locations, all } = yield select(getKeywords)

    const params = {
      keywords: all.map(({ id }) => id),
      locations: locations.map(({ id }) => id)
    }

    const values = yield Promise.all([
      searchEntities(params),
      getPopularEntities(),
      getSizes(params),
      getIndustries(params)
    ])

    const entities = values[0].data
    const popular = values[1].data
    const sizes = values[2].data
    const industries = values[3].data

    // filter entities
    const filters = yield select(getFilters)
    const filtered = entities.filter(filterEntities(filters))
    // Change Requests 20190313 not showing the result of popular search
    const pageData = createPageData(0, filtered)

    const result = {
      all: entities,
      filtered,
      popular,
      ...pageData
    }

    yield put(searchSuccess(result))

    // update the list of industries and sizes with the correct number of entities in each type
    yield put(
      updateData({
        industries,
        sizes
      })
    )
  } catch (error) {
    const msg = errorToMessage(error)
    yield put(searchFailure(msg))
    notifyError(msg)
  }
}

function* doChangePage({ payload: { page } }) {
  const { filtered, popular, totalPages } = yield select(getResult)
  const pageData = createPageData(
    page,
    filtered.length ? filtered : popular,
    totalPages
  )

  if (pageData) {
    yield put(updatePageData(pageData))
  }
}

const createPageData = (nextPage, entities) => {
  const totalPages = Math.ceil(entities.length / ITEMS_PER_PAGE)

  if (!totalPages) {
    return {
      totalPages: 0,
      current: [],
      currentPage: 0,
      pages: []
    }
  }

  nextPage = Math.min(nextPage, totalPages - 1)
  nextPage = Math.max(nextPage, 0)

  // The max number of displayable pages in pagination
  const MAX_VISIBLE_PAGES = 6

  // page starts at 1
  const nextIndex = nextPage * ITEMS_PER_PAGE
  let pages = []
  if (totalPages <= MAX_VISIBLE_PAGES) {
    // display all the pages
    pages = [...Array(totalPages).keys()]
  } else {
    const half = MAX_VISIBLE_PAGES / 2

    let minPage = Math.max(0, nextPage - half)
    let maxPage = Math.min(totalPages - 1, nextPage + half - 1)
    const numOfPages = maxPage - minPage + 1
    if (numOfPages < MAX_VISIBLE_PAGES) {
      // need to display more pages
      if (maxPage - nextPage < half - 1) {
        // add more pages on the left of the current page
        minPage = Math.max(0, minPage - (half - 1 - (maxPage - nextPage)))
      }

      if (nextPage - minPage < half) {
        // add more pages on the right of the current page
        maxPage = Math.min(
          totalPages - 1,
          maxPage + (half - (nextPage - minPage))
        )
      }
    }
    pages = [...Array(maxPage - minPage + 1).keys()].map(i => i + minPage)
  }

  return {
    totalPages,
    current: entities.slice(nextIndex, ITEMS_PER_PAGE * (nextPage + 1)),
    currentPage: nextPage,
    pages
  }
}

export const searchSagas = [
  takeEvery(changeIndustryFilter().type, doChangeFilter('industries')),
  takeEvery(changeSizeFilter().type, doChangeFilter('sizes')),
  takeEvery(setIndustryFilter().type, doSetIndustryFilter),
  takeEvery(changeIndustryKeyword().type, doChangeKeyword('industries')),
  takeEvery(changeCategoryKeyword().type, doChangeKeyword('categories')),
  takeEvery(changeNameKeyword().type, doChangeKeyword('names')),
  takeEvery(changeLocationKeyword().type, doChangeLocationKeyword),
  takeEvery(search().type, doSearch),
  takeEvery(changePage().type, doChangePage)
]
