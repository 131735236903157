import React from 'react'
import { func, object, arrayOf, shape } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import Page from 'components/Page/Page'
import Title from 'components/shared/Navigation/Title'
import Header from 'components/Page/Home/Header'
import PopularSearches from 'components/Page/Home/PopularSearches'
import Affiliates from 'components/shared/Affiliates'
import Wrapper from 'components/shared/Wrapper'
import i18n from 'i18n'
import { slugShape } from 'utils/shapes'
import { setIndustryFilter } from 'components/Page/Search/actions'

const Home = ({ history, industries, setIndustryFilter }) => (
  <Page>
    <Title title={`${i18n('home').toUpperCase()}`} />
    <Row>
      <Col xs={12}>
        <Header />
      </Col>
    </Row>
    <Wrapper />
    <Wrapper padding>
      <Row>
        <Col xs={12}>
          <PopularSearches
            searches={industries.filter(({ popular }) => popular)}
            onClick={industry => {
              setIndustryFilter(industry)
              history.push('/search')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Affiliates />
        </Col>
      </Row>
    </Wrapper>
  </Page>
)

Home.propTypes = {
  setIndustryFilter: func.isRequired,
  history: object.isRequired,
  industries: arrayOf(shape(slugShape)).isRequired
}

const mapStateToProps = ({ data: { industries } }) => ({
  industries
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setIndustryFilter
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
)
