import styled from 'styled-components'

import media from 'utils/media'

export default styled.div`
  width: 100%;
  overflow: hidden;
  min-height: 295;
  border-radius: 3px;
  margin: 15px 0;
  box-shadow: 0px 2px 15px 7px rgba(0, 0, 0, 0.03);
  ${media.tablet`
    margin: 15px 0px;
    min-height: 230px;
  `};
`
