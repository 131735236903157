import { takeEvery, put } from 'redux-saga/effects'

import errorToMessage from 'utils/errorToMessage'
import { notifyError } from 'utils/notify'
import {
  getTopics,
  getIndustries,
  getSizes,
  getCategories,
  getAffiliates,
  getReasons,
  getLocations
} from 'http/data'
import { getToken } from 'http/auth'
import {
  loadPredefinedData,
  startLoading,
  updateData,
  loadingFailure
} from 'redux/data/actions'
import { logInSuccess } from 'components/shared/AuthModal/actions'
import { REFRESH_TOKEN_KEY } from 'utils/constants'
import { changeLocationKeyword } from 'components/Page/Search/actions'
import { setAuthorisationHeader } from 'http/client'

export function* doLoadPredefinedData() {
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY)

  yield put(startLoading())
  try {
    const values = yield Promise.all([
      getIndustries(),
      getSizes(),
      getCategories(),
      getAffiliates(),
      getTopics(),
      getReasons(),
      getLocations()
    ])

    if (refreshToken) {
      // request a new access token
      try {
        const { data } = yield getToken(refreshToken)
        setAuthorisationHeader(data.token)
        yield put(logInSuccess(data))
      } catch (error) {
        // can not acquire an access token
        // remove the refresh token
        window.localStorage.removeItem(REFRESH_TOKEN_KEY)
      }
    }

    // set the location keyword to the user's current location
    yield put(
      changeLocationKeyword(values[6].data.find(({ isCurrent }) => isCurrent))
    )

    const industries = values[0].data
    const categories = values[2].data
    let industriesAndCategories = industries.reduce((acc, industry) => {
      acc[industry.id] = industry
      return acc
    }, {})
    industriesAndCategories = categories.reduce((acc, industry) => {
      acc[industry.id] = industry
      return acc
    }, industriesAndCategories)

    yield put(
      updateData({
        industries,
        sizes: values[1].data,
        categories,
        affiliates: values[3].data,
        topics: values[4].data,
        reasons: values[5].data,
        locations: values[6].data,
        industriesAndCategories: Object.values(industriesAndCategories)
      })
    )
  } catch (error) {
    const msg = errorToMessage(error)
    yield put(loadingFailure(msg))
    notifyError(msg)
  }
}

export const initSagas = [
  takeEvery(loadPredefinedData({}).type, doLoadPredefinedData)
]
