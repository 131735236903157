import React from 'react'
import { func } from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import Wrapper from 'components/shared/Wrapper'
import Button from 'components/shared/Button'
import Heading from 'components/shared/Heading'
import Description from 'components/shared/Description'
import FileSelector from 'components/shared/FileSelector'
import template from 'assets/template.xlsx'
import i18n from 'i18n'

class BusinessAddingModal extends React.Component {
  render() {
    const { openNewBusinessModal, openBusinessMassAddingModal } = this.props
    return (
      <Row>
        <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
          <Row>
            <Col xs={12}>
              <Wrapper>
                <Heading className="text-center">
                  {i18n('business-adding-modal-title')}
                </Heading>
                <Description>
                  {i18n('business-adding-modal-description')}
                </Description>
              </Wrapper>
            </Col>
          </Row>
          <Wrapper>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <Wrapper>
                      <Button onClick={openNewBusinessModal}>
                        {i18n('add-single-organisation')}
                      </Button>
                    </Wrapper>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Wrapper small>
                      <FileSelector
                        label={i18n('upload-csv')}
                        onFileSelected={openBusinessMassAddingModal}
                      />
                    </Wrapper>
                    <a
                      className="pull-right"
                      style={{ textDecoration: 'underline' }}
                      href={template}
                    >
                      {i18n('download-template')}
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Wrapper>
        </Col>
      </Row>
    )
  }
}

BusinessAddingModal.propTypes = {
  openNewBusinessModal: func.isRequired,
  openBusinessMassAddingModal: func.isRequired
}

export default BusinessAddingModal
