import React from 'react'
import { arrayOf, shape, object, bool, string, func } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, Image } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styled from 'styled-components'

import Page from 'components/Page/Page'
import Title from 'components/shared/Navigation/Title'
import Button from 'components/shared/Button'
import EntityList from 'components/Page/Admin/EntityList'
import BusinessAddingModal from 'components/Page/Admin/BusinessAddingModal'
import BusinessMassAddingModal from 'components/Page/Admin/BusinessMassAddingModal'
import FilterBar from 'components/Page/Admin/FilterBar'
import Wrapper from 'components/shared/Wrapper'
import Modal from 'components/shared/Modal'
import {
  changeFilterType,
  changeFilterText,
  loadEntities,
  toggleApproving,
  toggleRejecting,
  toggleDisabling,
  toggleEnabling,
  changePage,
  changeAffiliate,
  updateEntities
} from 'components/Page/Admin/actions'

import { openModal as openNewBusinessModal } from 'components/shared/NewBusinessModal/actions'
import { ENTITY_STATUS, ADMIN_FILTERS } from 'utils/constants'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'

import 'components/Page/Admin/admin.scss'

const ClickableImage = styled(Image)`
  cursor: pointer;
`

const StyledWrapper = styled(Wrapper)`
  padding: 0px 10px;
`

const filterBarOptions = Object.values(ADMIN_FILTERS)

class Admin extends React.Component {
  state = {
    showBusinessAddingModal: false,
    showBusinessMassAddingModal: false,
    csvFile: null
  }

  componentDidMount() {
    const { loadEntities } = this.props
    loadEntities()
  }

  openBusinessAddingModal = () =>
    this.setState({ ...this.state, showBusinessAddingModal: true })

  closeBusinessAddingModal = () =>
    this.setState({ ...this.state, showBusinessAddingModal: false })

  openNewBusinessModal = () => {
    this.closeBusinessAddingModal()
    this.props.openNewBusinessModal()
  }

  openBusinessMassAddingModal = csvFile => {
    this.setState({
      ...this.state,
      showBusinessMassAddingModal: true,
      showBusinessAddingModal: false,
      csvFile
    })
  }

  closeBusinessMassAddingModal = () =>
    this.setState({ ...this.state, showBusinessMassAddingModal: false })

  changeFilterType = option => this.props.changeFilterType(option.id)
  changeFilterText = ({ target: { value } }) =>
    this.props.changeFilterText(value)

  render() {
    const {
      loading,
      error,
      filter,
      entities,
      affiliates,
      sizes,
      industries,
      categories,
      toggleApproving,
      toggleRejecting,
      toggleDisabling,
      toggleEnabling,
      changePage,
      changeAffiliate,
      updateEntities
    } = this.props

    return (
      <Page>
        <Title title={`${i18n('admin').toUpperCase()}`} />
        <StyledWrapper>
          <Row>
            <Col xs={12}>
              <Wrapper>
                <FilterBar
                  typeOptions={filterBarOptions}
                  typeValue={ADMIN_FILTERS[filter.type]}
                  onFilterTypeChange={this.changeFilterType}
                  onFilterTextChange={this.changeFilterText}
                  textValue={filter.text}
                />
              </Wrapper>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <span className="pull-right">
                <ClickableImage
                  src="/icons/add.svg"
                  onClick={this.openBusinessAddingModal}
                />{' '}
                {i18n('add-organisation')}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Wrapper>
                <Tabs>
                  <TabList>
                    {ENTITY_STATUS.map(status => (
                      <Tab key={status}>{i18n(`${status}-tab`)}</Tab>
                    ))}
                  </TabList>
                  {ENTITY_STATUS.map(status => (
                    <TabPanel key={status}>
                      <EntityList
                        error={error}
                        status={status}
                        loading={loading}
                        entities={entities[status].filtered}
                        affiliates={affiliates}
                        page={entities[status].page}
                        toggleApproving={toggleApproving}
                        toggleRejecting={toggleRejecting}
                        toggleDisabling={toggleDisabling}
                        toggleEnabling={toggleEnabling}
                        changePage={changePage}
                        changeAffiliate={changeAffiliate}
                      />
                    </TabPanel>
                  ))}
                </Tabs>
              </Wrapper>
              <Row>
                <Col xs={12} sm={4} smOffset={4}>
                  <Wrapper>
                    <Button onClick={updateEntities} disabled={loading}>
                      {i18n('update-organisations')}
                    </Button>
                  </Wrapper>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledWrapper>
        <Modal
          isOpen={this.state.showBusinessAddingModal}
          contentLabel="BusinessAdding"
          onRequestClose={this.closeBusinessAddingModal}
        >
          <BusinessAddingModal
            openNewBusinessModal={this.openNewBusinessModal}
            openBusinessMassAddingModal={this.openBusinessMassAddingModal}
          />
        </Modal>
        <Modal
          isOpen={this.state.showBusinessMassAddingModal}
          contentLabel="BusinessMassAdding"
          onRequestClose={this.closeBusinessMassAddingModal}
        >
          <BusinessMassAddingModal
            csvFile={this.state.csvFile}
            closeModal={this.closeBusinessMassAddingModal}
            affiliates={affiliates}
            sizes={sizes}
            industries={industries}
            categories={categories}
          />
        </Modal>
      </Page>
    )
  }
}

Admin.propTypes = {
  loading: bool.isRequired,
  error: string,
  affiliates: arrayOf(shape(slugShape)).isRequired,
  sizes: arrayOf(shape(slugShape)).isRequired,
  industries: arrayOf(shape(slugShape)).isRequired,
  categories: arrayOf(shape(slugShape)).isRequired,
  entities: object.isRequired,
  filter: object.isRequired,
  changeFilterType: func.isRequired,
  changeFilterText: func.isRequired,
  openNewBusinessModal: func.isRequired,
  loadEntities: func.isRequired,
  toggleApproving: func.isRequired,
  toggleRejecting: func.isRequired,
  toggleDisabling: func.isRequired,
  toggleEnabling: func.isRequired,
  changePage: func.isRequired,
  changeAffiliate: func.isRequired,
  updateEntities: func.isRequired
}

const mapStateToProps = ({
  admin: { loading, entities, error, filter },
  data: { affiliates, sizes, industries, categories }
}) => ({
  loading,
  error,
  entities,
  affiliates,
  sizes,
  industries,
  categories,
  filter
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openNewBusinessModal,
      changeFilterType,
      changeFilterText,
      loadEntities,
      toggleApproving,
      toggleRejecting,
      toggleDisabling,
      toggleEnabling,
      changePage,
      changeAffiliate,
      updateEntities
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)
