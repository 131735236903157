import styled from 'styled-components'
import media from 'utils/media'

const TextArea = styled.textarea`
  width: 100%;
  font-size: 18px;
  padding: 10px 10px 5px 5px;
  border: 1px solid ${({ error, theme }) =>
    error ? theme.colours.error : theme.colours.secondary}
  ${media.tablet`
    font-size: 13px;
  `};
}`

export default TextArea
