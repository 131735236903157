const theme = {
  colours: {
    primary: '#56ba47',
    secondary: '#aaa',
    footer: '#555',
    error: '#d56',
    'navbar-active': '#56ba47',
    navbar: '#fff'
  },
  fonts: {
    'font-family-sans-serif': "'Open Sans', Helvetica, Arial, sans-serif",
    'font-family-serif': "'Times New Roman', Times, serif",
    'font-family-monospace': "Menlo, Monaco, Consolas, 'Courier New', monospace"
  }
}

theme.fonts['font-family-base'] = theme.fonts['font-family-sans-serif']

export default theme
