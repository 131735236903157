import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/shared/Button'
import i18n from 'i18n'

const LogoutButton = ({ logOut, starting }) => (
  <Button secondary="true" onClick={logOut} disabled={starting}>
    {i18n(starting ? 'logging-out' : 'logout')}
  </Button>
)

LogoutButton.propTypes = {
  logOut: PropTypes.func.isRequired,
  starting: PropTypes.bool.isRequired
}

export default LogoutButton
