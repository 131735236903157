import React from 'react'
import { oneOfType, array, string, object } from 'prop-types'
import { Image } from 'react-bootstrap'
import styled from 'styled-components'
import LazyLoad from 'react-lazy-load'

import media from 'utils/media'

const StyledWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  margin: 15px 10px 15px 0;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 2px;
  &:last-of-type {
    margin-right: 0;
  }
  ${media.tablet`
    margin: 10px 0px 10px 0;
  `};
`

const ContentWrapper = styled.div`
  width: 100%;
  padding: 15px 20px;
  ${media.tablet`
    padding: 10px 10px;
  `};
`

const StyledImage = styled(Image)`
  width: 100%;
`

const SelectableSearch = ({ className, children, img }) => (
  <StyledWrapper>
    {img && (
      <LazyLoad>
        <StyledImage src={img} />
      </LazyLoad>
    )}
    <ContentWrapper className={className}>{children}</ContentWrapper>
  </StyledWrapper>
)

SelectableSearch.propTypes = {
  className: string,
  children: oneOfType([object, array]).isRequired,
  img: string
}

export default SelectableSearch
