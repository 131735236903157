import React from 'react'
import { func } from 'prop-types'
import { withRouter } from 'react-router-dom'

import Button from 'components/shared/Button'
import i18n from 'i18n'

const AddBusinessButton = ({ onClick }) => (
  <Button secondary="true" onClick={onClick}>
    {i18n('add-business-button')}
  </Button>
)

AddBusinessButton.propTypes = {
  onClick: func.isRequired
}

export default withRouter(AddBusinessButton)
