import React from 'react'
import { object, string } from 'prop-types'
import styled from 'styled-components'

import Label from './Label'

const RadioLabel = styled(Label)`
  color: #555;
`

const Radio = ({ input, label }) => (
  <RadioLabel className="radio">
    <input {...input} type="radio" />
    <span className="checkmark" />
    {label}
  </RadioLabel>
)

Radio.propTypes = {
  input: object.isRequired,
  label: string.isRequired
}

export default Radio
