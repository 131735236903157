import { takeEvery, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import * as auth from 'http/auth'
import { setAuthorisationHeader } from 'http/client'
import errorToMessage from 'utils/errorToMessage'
import { notifyError } from 'utils/notify'
import {
  logIn,
  logOut,
  start,
  logInSuccess,
  logOutSuccess,
  failure
} from 'components/shared/AuthModal/actions'
import { changeLocationKeyword } from 'components/Page/Search/actions'
import { REFRESH_TOKEN_KEY } from 'utils/constants'

function* handleError(error) {
  const msg = errorToMessage(error)
  yield put(failure(msg))
  notifyError(msg)
}

export function* doLogIn({ payload: { email, password } }) {
  yield put(start('login'))
  try {
    const { data } = yield auth.logIn({ email, password })
    setAuthorisationHeader(data.token)
    window.localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken)
    yield put(logInSuccess(data))
    // redirect to admin page upon success
    yield put(push('/admin'))
  } catch (error) {
    yield handleError(error)
  }
}

export function* doLogOut() {
  yield put(start('logout'))
  try {
    yield auth.logOut()
    // remove auth token
    setAuthorisationHeader('')
    // remove the refresh token
    window.localStorage.removeItem(REFRESH_TOKEN_KEY)
    // reset all the states
    yield put(logOutSuccess())
    // set the location search keyword to the current one.
    const data = yield select(({ data }) => data)
    yield put(
      changeLocationKeyword(data.locations.find(({ isCurrent }) => isCurrent))
    )
    // redirect to home page
    yield put(push('/'))
  } catch (error) {
    yield handleError(error)
  }
}

export const authSagas = [
  takeEvery(logIn({}).type, doLogIn),
  takeEvery(logOut().type, doLogOut)
]
