import i18n from 'i18n'
import validator from 'validator'

const required = errorText => value => (value ? undefined : errorText)

const email = errorText => value =>
  !value || !validator.isEmail(value) ? errorText : undefined

const url = errorText => value =>
  !value || !validator.isURL(value) ? errorText : undefined

const abn = errorText => value => {
  value = value ? value.replace(/\s/g, '') : ''
  return value.length !== 11 || !validator.isInt(value) ? errorText : undefined
}

const year = errorText => value =>
  !value ||
  !validator.isNumeric(value, { no_symbols: true }) ||
  parseInt(value) < 1900
    ? errorText
    : undefined

const maxWord = (max, errorText) => value =>
  !value ||
  value
    .trim()
    .replace(/\s+/gi, ' ')
    .split(' ')
    .filter(item => item !== '').length <= max
    ? undefined
    : errorText

export const fullNameValidator = required(i18n('missing-full-name'))
export const firstNameValidator = required(i18n('missing-first-name'))
export const lastNameValidator = required(i18n('missing-last-name'))
export const companyNameValidator = required(i18n('missing-organisation-name'))
export const emailValidator = email(i18n('invalid-email'))
export const phoneValidator = required(i18n('missing-organisation-phone'))
export const textAreaValidator = (missingError, maxLength) => [
  maxWord(maxLength, i18n('max-word-error')(maxLength)),
  required(missingError)
]
export const companyNumberValidator = abn(i18n('missing-company-number'))
export const urlValidator = url(i18n('invalid-link'))
export const yearValidator = year(i18n('invalid-year'))
export const passwordValidator = required(i18n('missing-password'))
export const matchingPasswordValidator = (value, allValues) =>
  value === allValues.password ? undefined : i18n('password-mismatch')
export const contactTopicValidator = required(i18n('missing-topic'))
export const sizeValidator = required(i18n('missing-size'))
export const hasAgreedTermsValidator = required(i18n('must-agree-terms'))
