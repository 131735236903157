import React from 'react'
import { arrayOf, string, bool, shape, func } from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import Button from 'components/shared/Button'
import Modal from 'components/shared/Modal'
import DisableOrganisationModal from './DisableOrganisationModal'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'

class Footer extends React.Component {
  state = {
    isOpen: false
  }

  closeModal = () => {
    this.setState({
      isOpen: false
    })
  }

  openModal = () => {
    this.setState({
      isOpen: true
    })
  }

  render() {
    const { id, name, isOwner, reasons, onDisablingSuccess } = this.props

    return (
      <Row>
        {isOwner && (
          <Col xs={12} md={4} mdOffset={8}>
            <Button danger="true" onClick={this.openModal}>
              {i18n('disable-account')}
            </Button>
          </Col>
        )}
        <Modal
          isOpen={this.state.isOpen}
          contentLabel="DisableOrganisationModal"
          onRequestClose={this.closeModal}
        >
          <DisableOrganisationModal
            id={id}
            reasons={reasons}
            name={name}
            onSuccess={onDisablingSuccess}
          />
        </Modal>
      </Row>
    )
  }
}

Footer.propTypes = {
  reasons: arrayOf(shape(slugShape)).isRequired,
  name: string.isRequired,
  id: string.isRequired,
  isOwner: bool,
  onDisablingSuccess: func.isRequired
}

export default Footer
