import React from 'react'
import { array, func } from 'prop-types'
import { ToggleButtonGroup as Group } from 'react-bootstrap'

import ToggleButton from 'components/shared/ToggleButton'

const ToggleButtonGroup = ({ data, getKey, getName, onRemoved }) => (
  <Group type="checkbox" value={data} onChange={() => {}}>
    {data.map(item => (
      <ToggleButton
        key={getKey(item)}
        value={item}
        onClick={() => onRemoved(item)}
      >
        {getName(item)} <i className="fa fa-times-circle" />
      </ToggleButton>
    ))}
  </Group>
)

ToggleButtonGroup.propTypes = {
  data: array.isRequired,
  getKey: func.isRequired,
  getName: func.isRequired,
  onRemoved: func.isRequired
}

export default ToggleButtonGroup
