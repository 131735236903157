import React from 'react'
import { string, func } from 'prop-types'
import styled from 'styled-components'

import media from 'utils/media'
import Clickable from 'components/shared/Clickable'

const Wrapper = styled.div`
  height: 63px;
  background-color: ${({ theme }) => theme.colours.primary};
  color: #fff;
  padding: 5px 5px 5px 20px;
  ${media.tablet`
    height: 40px;
  `}
`

const Header = ({ children, onClick }) => (
  <Clickable onClick={onClick}>
    <Wrapper>
      <h4>{children}</h4>
    </Wrapper>
  </Clickable>
)

Header.propTypes = {
  onClick: func.isRequired,
  children: string.isRequired
}

export default Header
