import React from 'react'
import { array, object, func, number } from 'prop-types'
import { Row, Col, Pagination } from 'react-bootstrap'

import Wrapper from 'components/shared/Wrapper'
import FlexRow from 'components/shared/FlexRow'
import FlexCol from 'components/shared/FlexCol'
import OrganisationCell from 'components/shared/OrganisationCell'

const Grid = ({
  data,
  currentPage,
  pages,
  totalPages,
  history,
  changePage
}) => (
  <Wrapper>
    <FlexRow>
      {data.map(entity => (
        <FlexCol key={entity.id} xs={12} sm={6} md={4}>
          <OrganisationCell
            onHeaderClick={() => history.push(`/organisation/${entity.id}`)}
            entity={entity}
          />
        </FlexCol>
      ))}
    </FlexRow>
    {data.length > 0 && (
      <Row>
        <Col xs={12}>
          <Pagination>
            <Pagination.First
              disabled={currentPage === 0}
              onClick={() => changePage(0)}
            />
            <Pagination.Prev
              disabled={currentPage === 0}
              onClick={() => changePage(currentPage - 1)}
            />
            {pages.map(page => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage + 1 === totalPages}
              onClick={() => changePage(currentPage + 1)}
            />
            <Pagination.Last
              disabled={currentPage + 1 === totalPages}
              onClick={() => changePage(totalPages - 1)}
            />
          </Pagination>
        </Col>
      </Row>
    )}
  </Wrapper>
)

Grid.propTypes = {
  history: object.isRequired,
  data: array.isRequired,
  currentPage: number.isRequired,
  pages: array.isRequired,
  totalPages: number.isRequired,
  changePage: func.isRequired
}

export default Grid
