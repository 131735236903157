import React from 'react'
import { string, bool, func } from 'prop-types'
import styled from 'styled-components'

import Span from './Span'
import Clickable from 'components/shared/Clickable'
import VerticalAlignWrapper from 'components/shared/VerticalAlignWrapper'
import VerticalAlignItem from 'components/shared/VerticalAlignWrapper/VerticalAlignItem'
import EditIcon from './EditIcon'
import i18n from 'i18n'
import media from 'utils/media'

const Wrapper = styled(VerticalAlignWrapper)`
  padding: 0 40px;
  height: 80px;
  background-color: ${({ theme }) => theme.colours.primary};
  color: #fff;
  ${media.tablet`
    padding: 0 10px;
    height: 60px;
  `}
`

export const H1 = styled(VerticalAlignItem.withComponent('h1'))`
  line-height: 61px;
`
export const StyledClickable = VerticalAlignItem.withComponent(Clickable)

const Header = ({ children, isOwner, openEditOrganisationModal }) => (
  <Wrapper>
    <H1>{children}</H1>
    {isOwner && (
      <StyledClickable onClick={openEditOrganisationModal} right>
        <Span style={{ color: '#fff' }}>
          {i18n('edit')} <EditIcon />
        </Span>
      </StyledClickable>
    )}
  </Wrapper>
)

Header.propTypes = {
  isOwner: bool.isRequired,
  children: string.isRequired,
  openEditOrganisationModal: func.isRequired
}

export default Header
