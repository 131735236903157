import React from 'react'
import { array } from 'prop-types'
import styled from 'styled-components'

import Wrapper from 'components/shared/Wrapper'
import Paragraph from 'components/shared/Paragraph'
import FilterSection from './FilterSection'
import i18n from 'i18n'

import media from 'utils/media'

const StyledWrapper = styled(Wrapper)`
  border-radius: 3px 14px 14px 14px;
  background-color: #f6f6f6;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
`

const StyledParagraph = styled(Paragraph)`
  color: #4A4A4A;
  line-height: 25px;
}
`

const Header = styled.div`
  padding: 10px;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 2px 12px 7px rgba(0, 0, 0, 0.03);
  ${media.tablet`
    padding: 5px;
  `};
`

const Content = styled.div`
  background-color: #f3f3f3;
  padding: 20px 0 10px 10px;
  ${media.tablet`
    padding: 10px 0 5px 10px;
  `};
`

const Filter = ({ sections }) => (
  <StyledWrapper>
    <Header>
      <StyledParagraph lg={true}>{i18n('filter-by')}</StyledParagraph>
    </Header>
    <Content>
      {sections.map(({ label, filters, checked, onChange }) => (
        <Wrapper small key={label}>
          <FilterSection
            label={label}
            filters={filters}
            onChange={onChange}
            checked={checked}
          />
        </Wrapper>
      ))}
    </Content>
  </StyledWrapper>
)

Filter.propTypes = {
  sections: array.isRequired
}

export default Filter
