import React from 'react'
import { arrayOf, string } from 'prop-types'
import styled from 'styled-components'

import Paragraph from 'components/shared/Paragraph'

const StyledParagraph = styled(Paragraph)`
  color: rgba(85, 85, 85, 0.28);
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
`

const IndustrySectors = ({ industries }) => (
  <StyledParagraph>{industries.join(', ')}</StyledParagraph>
)

IndustrySectors.propTypes = {
  industries: arrayOf(string).isRequired
}

export default IndustrySectors
