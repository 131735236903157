import React from 'react'
import { array, func } from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import Wrapper from 'components/shared/Wrapper'
import Button from 'components/shared/Button'
import Heading from 'components/shared/Heading'
import Description from 'components/shared/Description'
import Map from 'components/shared/Map'
import i18n from 'i18n'

const BusinessLocation = ({
  addresses,
  addAddress,
  removeAddress,
  onSubmit
}) => (
  <Row>
    <Col xs={10} xsOffset={1} sm={6} smOffset={3}>
      <Wrapper>
        <Heading className="text-center">{i18n('business-location')}</Heading>
        <Description className="text-center">
          {i18n('business-location-description')}
        </Description>
      </Wrapper>
      <Row>
        <Col xs={12}>
          <Wrapper>
            <Map
              locations={addresses}
              onLocationSelected={addAddress}
              onLocationRemoved={removeAddress}
              disabled
            />
          </Wrapper>
          <Button onClick={onSubmit} disabled={addresses.length === 0}>
            {i18n('next')}
          </Button>
        </Col>
      </Row>
    </Col>
  </Row>
)

BusinessLocation.propTypes = {
  addresses: array.isRequired,
  onSubmit: func.isRequired,
  addAddress: func.isRequired,
  removeAddress: func.isRequired
}

export default BusinessLocation
