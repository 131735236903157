import React from 'react'
import { arrayOf, shape, array, number, string, bool, func } from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Row, Col, Image } from 'react-bootstrap'
import ReactTable, { ReactTableDefaults } from 'react-table'
import 'react-table/react-table.css'

import Pagination from './Pagination'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'
import { PENDING_ENTITY, ACTIVE_ENTITY, DISABLED_ENTITY } from 'utils/constants'
import { renderHeader, headerStyle } from './helpers'

const Link = styled.a`
  color: ${props => props.theme.colours.primary} !important;
  text-decoration: none !important;
`

const GapFiller = styled.div`
  background-color: #f3f3f3;
  min-height: 20px;
`

const sortCheckboxes = (a, b) => (a ? 1 : b ? -1 : 0)

class EntityList extends React.Component {
  state = {
    isSortDesc: {}
  }

  columns = [
    {
      id: i18n('name'),
      status: [PENDING_ENTITY, ACTIVE_ENTITY, DISABLED_ENTITY],
      Header: () => renderHeader(i18n('name'), this.state.isSortDesc),
      accessor: 'poc.firstName'
    },
    {
      id: i18n('organisation-name'),
      status: [PENDING_ENTITY, ACTIVE_ENTITY, DISABLED_ENTITY],
      Header: () =>
        renderHeader(i18n('organisation-name'), this.state.isSortDesc),
      accessor: 'name'
    },
    {
      id: i18n('website'),
      status: [PENDING_ENTITY, ACTIVE_ENTITY, DISABLED_ENTITY],
      Header: () => renderHeader(i18n('website'), this.state.isSortDesc),
      accessor: 'websiteAddresses[0].value',
      Cell: row => (
        <Link
          href={`${row.value.startsWith('http') ? '' : 'https://'}${row.value}`}
          target="_blank"
        >
          {row.value}
        </Link>
      )
    },
    {
      id: i18n('business-email'),
      status: [PENDING_ENTITY, ACTIVE_ENTITY, DISABLED_ENTITY],
      Header: () => renderHeader(i18n('business-email'), this.state.isSortDesc),
      accessor: 'emailAddresses[0].value'
    },
    {
      id: i18n('business-phone'),
      status: [PENDING_ENTITY, ACTIVE_ENTITY, DISABLED_ENTITY],
      Header: () => renderHeader(i18n('business-phone'), this.state.isSortDesc),
      accessor: 'phoneAddresses[0].value'
    },
    {
      id:
        this.props.status === ACTIVE_ENTITY
          ? i18n('last-active')
          : this.props.status === DISABLED_ENTITY
          ? i18n('date-disabled')
          : i18n('request-date'),
      status: [PENDING_ENTITY, ACTIVE_ENTITY, DISABLED_ENTITY],
      Header: () =>
        renderHeader(
          this.props.status === ACTIVE_ENTITY
            ? i18n('last-active')
            : this.props.status === DISABLED_ENTITY
            ? i18n('date-disabled')
            : i18n('request-date'),
          this.state.isSortDesc
        ),
      accessor: 'date',
      Cell: row => new Date(row.value).toLocaleDateString(),
      width: 150
    },
    {
      status: [DISABLED_ENTITY],
      Header: renderHeader(i18n('reason')),
      sortable: false,
      accessor: 'reason',
      width: 200
    },
    {
      id: i18n('reject'),
      status: [PENDING_ENTITY],
      Header: () => renderHeader(i18n('reject'), this.state.isSortDesc),
      Cell: row => (
        <input
          type="checkbox"
          checked={!!row.original.rejecting}
          onChange={() => this.props.toggleRejecting(row.index)}
        />
      ),
      width: 100,
      accessor: 'rejecting',
      sortMethod: sortCheckboxes
    },
    {
      id: i18n('disable'),
      status: [ACTIVE_ENTITY],
      Header: () => renderHeader(i18n('disable'), this.state.isSortDesc),
      Cell: row => (
        <input
          type="checkbox"
          checked={!!row.original.disabling}
          onChange={() => this.props.toggleDisabling(row.index)}
        />
      ),
      width: 100,
      accessor: 'disabling',
      sortMethod: sortCheckboxes
    },
    {
      id: i18n('approve'),
      status: [PENDING_ENTITY],
      Header: () => renderHeader(i18n('approve'), this.state.isSortDesc),
      Cell: row => (
        <input
          type="checkbox"
          checked={!!row.original.approving}
          onChange={() => this.props.toggleApproving(row.index)}
        />
      ),
      width: 100,
      accessor: 'approving',
      sortMethod: sortCheckboxes
    },
    {
      id: i18n('enable'),
      status: [DISABLED_ENTITY],
      Header: () => renderHeader(i18n('enable'), this.state.isSortDesc),
      Cell: row => (
        <input
          type="checkbox"
          checked={!!row.original.enabling}
          onChange={() => this.props.toggleEnabling(row.index)}
        />
      ),
      width: 100,
      accessor: 'enabling',
      sortMethod: sortCheckboxes
    },
    {
      status: [PENDING_ENTITY, ACTIVE_ENTITY, DISABLED_ENTITY],
      Expander: ({ isExpanded }) => {
        if (isExpanded) {
          return <Image src="/icons/down.svg" />
        } else {
          return <Image src="/icons/right.svg" />
        }
      },
      sortable: false,
      expander: true
    } // Remove N/A columns based on the given status
  ].filter(column => column.status.indexOf(this.props.status) > -1)

  render() {
    const { status, page, changePage, entities, loading, error } = this.props

    return (
      <div>
        <Row>
          <Col xs={12}>
            <GapFiller />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ReactTable
              data={entities}
              columns={this.columns}
              loading={loading}
              noDataText={`${loading ? '' : error || i18n('no-entity')}`}
              defaultPageSize={7}
              page={page}
              collapseOnDataChange={false}
              onPageChange={newPage => changePage(status, newPage)}
              PaginationComponent={props => (
                <Pagination {...props} exports={entities} />
              )}
              style={{
                border: 'none'
              }}
              column={{
                ...ReactTableDefaults.column,
                headerStyle,
                style: {
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  padding: '20px 20px 10px 20px',
                  backgroundColor: '#f3f3f3'
                }
              }}
              onSortedChange={(newSorted, column, shiftKey) => {
                const isSortDesc = {}
                newSorted.forEach(
                  column => (isSortDesc[column.id] = column.desc)
                )
                this.setState({
                  isSortDesc
                })
              }}
              SubComponent={({ original }) => {
                const data = [
                  {
                    slug: i18n('locations'),
                    content: original.locationAddresses
                      .map(
                        ({ value: { postal } }) =>
                          `${postal.suburb} ${postal.state}`
                      )
                      .join(', ')
                  },
                  {
                    slug: i18n('poc-email'),
                    content: original.poc ? original.poc.email : ''
                  },
                  {
                    slug: i18n('business-number'),
                    content: original.abn
                  },
                  {
                    slug: i18n('organisation-size'),
                    content: original.size ? original.size.name : ''
                  },
                  {
                    slug: i18n('year-established'),
                    content: original.year
                  },
                  {
                    slug: i18n('industry-sectors'),
                    content: original.industries
                      .map(({ name }) => name)
                      .join(', ')
                  },
                  {
                    slug: i18n('product-categories'),
                    content: original.categories
                      .map(({ name }) => name)
                      .join(', ')
                  }
                ]
                const columns = [
                  {
                    accessor: 'slug',
                    width: 300
                  },
                  {
                    accessor: 'content'
                  }
                ]
                return (
                  <ReactTable
                    data={data}
                    columns={columns}
                    minRows={0}
                    showPagination={false}
                    column={{
                      ...ReactTableDefaults.column,
                      headerStyle: {
                        display: 'none'
                      },
                      style: {
                        backgroundColor: 'white',
                        borderLeft: 'none',
                        borderRight: 'none'
                      }
                    }}
                  />
                )
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

EntityList.propTypes = {
  status: string.isRequired,
  page: number.isRequired,
  entities: array.isRequired,
  loading: bool.isRequired,
  error: string,
  affiliates: arrayOf(shape(slugShape)).isRequired,
  toggleApproving: func.isRequired,
  toggleRejecting: func.isRequired,
  toggleDisabling: func.isRequired,
  toggleEnabling: func.isRequired,
  changePage: func.isRequired,
  changeAffiliate: func.isRequired
}

export default withTheme(EntityList)
