const auth = {
  'missing-password': 'Please enter your password',
  password: 'Password',
  'login-form': 'Login to the Australian Food and Agribusiness Directory!',
  'forgot-password': 'Forgot your password?',
  'forgot-password-form': 'Forgotten password',
  'forgot-password-form-description':
    'Please enter your email address below and click Reset Password. We will then send you a reset password link',
  'reset-password-form': 'Reset Password',
  'reset-password-form-description':
    'Just enter your new password below. Your new password must be at least 6 characters',
  'reset-password': 'Reset Password',
  'resetting-password': 'Resetting Password',
  'confirm-password': 'Confirm Pasword',
  'password-mismatch': "The passwords don't match",
  'forgot-password-email-sent': 'Password Reset sent!',
  'forgot-password-email-sent-description':
    'We have just emailed you instructions on how to reset your password',
  login: 'Login',
  'logging-in': 'Logging in',
  'back-to-login': 'Back to login',
  'reset-password-success': 'Your password has successfully been reset!'
}

export default auth
