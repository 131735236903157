import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import i18n from 'i18n'

const Wrapper = styled.div`
  margin: 20px 20px 30px 20px;
`

const Link = styled.a`
  cursor: pointer;
  padding-left: 5px;
  color: #555;
  text-decoration: underline;
`

const Clickable = styled.span`
  padding: 6px 10px;
  text-decoration: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: none;
  &:last-of-type {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  background-color: ${({ active, theme }) =>
    active ? theme.colours.primary : '#fff'};
  color: ${({ active, disabled, theme }) =>
    disabled ? '#555' : active ? '#fff' : theme.colours.primary} !important;
  cursor: ${({ disabled, active }) =>
    disabled || active ? 'auto' : 'pointer'};
`

const exportCSV = data => {
  const template = [
    {
      header: 'Affiliation',
      getContent: entity => entity.affiliates[0]
    },
    {
      header: 'Organisation Name',
      getContent: entity => entity.name
    },
    {
      header: 'Size',
      getContent: entity => entity.size.name
    },
    {
      header: 'Industry Sectors',
      getContent: entity => entity.industries.map(({ name }) => name).toString()
    },
    {
      header: 'Product Categories',
      getContent: entity => entity.categories.map(({ name }) => name).toString()
    },
    {
      header: 'Established Year',
      getContent: entity => entity.year
    },
    {
      header: 'Website',
      getContent: entity => entity.websiteAddresses[0].value
    },
    {
      header: 'Address',
      getContent: entity =>
        entity.locationAddresses
          .reduce((acc, { value: { postal: { suburb, state } } }) => {
            acc += ` ${suburb} ${state};`
            return acc
          }, '')
          .slice(0, -1) // remove the last semi-colon
    },
    {
      header: 'POC Name',
      getContent: entity => `${entity.poc.firstName} ${entity.poc.lastName}`
    },
    {
      header: 'POC Email',
      getContent: entity => entity.poc.email
    }
  ]

  const standardise = content => {
    content += ''
    if (!content) return ''
    let result = content.replace(/"/g, '""')
    if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
    return result
  }

  let csvContent = 'data:text/csv;charset=utf-8,'
  csvContent += template.map(({ header }) => header).join(',') + '\r\n'
  data.forEach(entity => {
    csvContent += template
      .reduce((acc, { getContent }) => {
        acc += `${standardise(getContent(entity))},`
        return acc
      }, '')
      .slice(0, -1) // remove the last comma
    csvContent += '\r\n'
  })

  window.open(encodeURI(csvContent))
}

// how many visible pages in pagination
const MAX_PAGE_NUM = 12 // an even number

const getVisiblePages = (totalPages, currentPage) => {
  if (totalPages <= MAX_PAGE_NUM) {
    return [...Array(totalPages).keys()]
  }

  let minPage = 0
  let maxPage = totalPages
  const half = MAX_PAGE_NUM / 2

  minPage = Math.max(0, currentPage - half)
  maxPage = Math.min(totalPages - 1, currentPage + half - 1)
  const numOfPages = maxPage - minPage + 1
  if (numOfPages < MAX_PAGE_NUM) {
    // need to display more pages
    if (maxPage - currentPage < half - 1) {
      // add more pages on the left of the current page
      minPage = Math.max(0, minPage - (half - 1 - (maxPage - currentPage)))
    }

    if (currentPage - minPage < half) {
      // add more pages on the right of the current page
      maxPage = Math.min(
        totalPages - 1,
        maxPage + (half - (currentPage - minPage))
      )
    }
  }

  return [...Array(maxPage - minPage + 1).keys()].map(i => i + minPage)
}

class Pagination extends Component {
  getSafePage = page => {
    if (Number.isNaN(page)) {
      page = this.props.page
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1)
  }

  changePage = page => {
    page = this.getSafePage(page)
    if (this.props.page !== page) {
      this.props.onPageChange(page)
    }
  }

  render() {
    const { pages, page, canPrevious, canNext, exports } = this.props

    if (!pages) return null

    const visiblePages = getVisiblePages(pages, page)

    return (
      <Wrapper>
        <Clickable
          onClick={() => this.changePage(page - 1)}
          disabled={!canPrevious}
        >
          {'<<'}
        </Clickable>
        {visiblePages[0] ? (
          <Clickable disabled={true}>{'...'}</Clickable>
        ) : null}
        {visiblePages.map(value => (
          <Clickable
            key={value}
            onClick={() => {
              this.changePage(this.getSafePage(value))
            }}
            active={value === page}
          >
            {value + 1}
          </Clickable>
        ))}
        {visiblePages[visiblePages.length - 1] < pages - 1 ? (
          <Clickable disabled={true}>{'...'}</Clickable>
        ) : null}
        <Clickable
          onClick={() => this.changePage(page + 1)}
          disabled={!canNext}
        >
          {'>>'}
        </Clickable>
        <Link
          className="pull-right"
          disabled={!exports.length}
          onClick={() => exportCSV(exports)}
        >
          {i18n('download-csv')}
        </Link>
      </Wrapper>
    )
  }
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  canNext: PropTypes.bool.isRequired,
  canPrevious: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  exports: PropTypes.array.isRequired
}

export default Pagination
