import { ENTITY_FIELDS } from 'utils/constants'

const {
  NAME,
  ABN,
  SIZE,
  YEAR,
  DESCRIPTION,
  PHONE,
  WEBSITE,
  EMAIL,
  INDUSTRIES,
  CATEGORIES,
  POC_FIRSTNAME,
  POC_LASTNAME,
  POC_EMAIL
} = ENTITY_FIELDS

export const getSelectedIndustries = (formValues, allIndustries) => {
  if (!formValues) return {}

  return allIndustries.reduce((acc, { id }) => {
    if (formValues[id]) acc[id] = formValues[id]
    return acc
  }, {})
}

export const organisationToFormValues = ({
  id,
  emailAddresses,
  phoneAddresses,
  websiteAddresses,
  locationAddresses,
  poc,
  ...organisation
}) => {
  return {
    formValues: {
      [ABN]: organisation[ABN],
      [NAME]: organisation[NAME],
      [EMAIL]: organisation[EMAIL],
      [YEAR]: `${organisation[YEAR] ? organisation[YEAR] : ''}`,
      [SIZE]: organisation[SIZE],
      [DESCRIPTION]: organisation[DESCRIPTION],
      [EMAIL]: emailAddresses[0].value,
      [PHONE]: phoneAddresses[0].value,
      [WEBSITE]: websiteAddresses[0].value,
      [POC_FIRSTNAME]: poc && poc[POC_FIRSTNAME],
      [POC_LASTNAME]: poc && poc[POC_LASTNAME],
      [POC_EMAIL]: poc && poc[POC_EMAIL]
    },
    id,
    addresses: locationAddresses.map(({ value }) => value),
    [CATEGORIES]: organisation[CATEGORIES],
    [INDUSTRIES]: organisation[INDUSTRIES].map(({ id }) => id)
  }
}

export const formValuesToOrganisation = ({
  id,
  addresses,
  categories,
  name,
  abn,
  businessEmail,
  website,
  phone,
  description,
  size,
  year,
  firstName,
  lastName,
  email,
  industries
}) => ({
  id,
  [ABN]: abn,
  [NAME]: name,
  [INDUSTRIES]: industries,
  [CATEGORIES]: categories.map(({ id }) => id),
  [YEAR]: parseInt(year),
  [SIZE]: size && size.id,
  [DESCRIPTION]: description,
  phoneAddresses: [phone],
  websiteAddresses: [website],
  emailAddresses: [businessEmail],
  locationAddresses: addresses,
  poc: {
    [POC_FIRSTNAME]: firstName,
    [POC_LASTNAME]: lastName,
    [POC_EMAIL]: email
  }
})
