import React from 'react'
import { array, number, func } from 'prop-types'
import { Collapse, Image } from 'react-bootstrap'
import styled from 'styled-components'

import Checkbox from 'components/shared/Checkbox'
import Paragraph from 'components/shared/Paragraph'
import Span from 'components/shared/Span'
import Clickable from 'components/shared/Clickable'
import media from 'utils/media'

const isDisabled = (id, selected, maxSelectable) =>
  selected.length >= maxSelectable && selected.indexOf(id) === -1

const StyledParagraph = styled(Paragraph)`
  color: #555;
  padding-left: 20px;
  font-size: 12px;
  line-height: 21px;
  ${media.tablet`
    padding-left: 15px;
    font-size: 10px;
  `};
`

const StyledSpan = styled(Span)`
  color: ${({ theme }) => theme.colours.primary}
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  margin-right: 5px;
  ${media.tablet`
    font-size: 10px;
  `};
`

class CheckboxList extends React.Component {
  state = {
    isOpen: false
  }

  toggleExpansion = () => this.setState({ isOpen: !this.state.isOpen })

  render() {
    const { data, selected, maxSelectable, onChange } = this.props
    const { isOpen } = this.state
    const nonCollapsible = data.slice(0, 5)
    const collapsible = data.slice(5)
    return (
      <div>
        {nonCollapsible.map(({ id, name, description }) => (
          <div key={id}>
            <Checkbox
              checked={selected.indexOf(id) > -1}
              disabled={isDisabled(id, selected, maxSelectable)}
              onChange={checked => onChange(id, checked)}
              name={id}
              label={name}
              bold={true}
              description={description}
            />
            {description && <StyledParagraph>{description}</StyledParagraph>}
          </div>
        ))}
        <Collapse in={isOpen}>
          <div>
            {collapsible.map(({ id, name, description }) => (
              <div key={id}>
                <Checkbox
                  checked={selected.indexOf(id) > -1}
                  disabled={isDisabled(id, selected, maxSelectable)}
                  onChange={checked => onChange(id, checked)}
                  name={id}
                  label={name}
                  bold={true}
                />
                {description && (
                  <StyledParagraph>{description}</StyledParagraph>
                )}
              </div>
            ))}
          </div>
        </Collapse>
        <Clickable onClick={this.toggleExpansion}>
          <StyledSpan>{`See ${
            isOpen ? 'less' : 'more'
          } industry sectors`}</StyledSpan>
          <Image src={`/icons/green-${isOpen ? 'up' : 'right'}.svg`} />
        </Clickable>
      </div>
    )
  }
}

CheckboxList.propTypes = {
  data: array.isRequired,
  selected: array.isRequired,
  maxSelectable: number.isRequired,
  onChange: func.isRequired
}

export default CheckboxList
