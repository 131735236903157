import React from 'react'
import { Row, Col } from 'react-bootstrap'

import LoginForm from './LoginForm'
import ForgotPassword from './ForgotPassword'

class AuthModal extends React.Component {
  state = {
    login: true
  }

  onLoginFormToggle = () => {
    this.setState({ login: false })
  }

  onForgotPasswordFormToggle = () => {
    this.setState({ login: true })
  }

  render() {
    return (
      <Row>
        <Col xs={12}>
          {this.state.login ? (
            <LoginForm onFormToggle={this.onLoginFormToggle} />
          ) : (
            <ForgotPassword onFormToggle={this.onForgotPasswordFormToggle} />
          )}
        </Col>
      </Row>
    )
  }
}

export default AuthModal
