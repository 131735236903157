import client from './client'

export const searchEntities = params => client.get('api/v1/entity', { params })
export const getPopularEntities = () =>
  client.get('api/v1/entity', { params: { popular: true } })
export const getEntity = id => client.get(`api/v1/entity/${id}`)
export const getSimilarEntities = id =>
  client.get(`api/v1/entity/${id}/similar`)

export const addEntity = data => client.post('api/v1/entity', data)
export const addEntities = data => client.post('api/v1/admin/entity', data)
export const updateEntities = data => client.put('api/v1/admin/entity', data)

export const getPendingEntities = () => client.get('api/v1/admin/entity')

export const getDisabledEntities = () =>
  client.get('api/v1/admin/entity?disabled=true')

export const getActiveEntities = () =>
  client.get('api/v1/admin/entity?active=true')

export const approveEntities = entityIds => {
  if (!entityIds || entityIds.length === 0) {
    return Promise.resolve(0)
  }
  return client.post('api/v1/admin/entity/approve', entityIds)
}

export const rejectEntities = entityIds => {
  if (!entityIds || entityIds.length === 0) {
    return Promise.resolve(0)
  }
  return client.post('api/v1/admin/entity/reject', entityIds)
}

export const disableEntities = entityIds => {
  if (!entityIds || entityIds.length === 0) {
    return Promise.resolve(0)
  }
  return client.post('api/v1/admin/entity/disable', entityIds)
}

export const enableEntities = entityIds => {
  if (!entityIds || entityIds.length === 0) {
    return Promise.resolve(0)
  }
  return client.post('api/v1/admin/entity/enable', entityIds)
}

export const updateAffiliates = entities => {
  if (!entities || entities.length === 0) {
    return Promise.resolve(0)
  }
  return client.put('api/v1/admin/entity/affiliate', entities)
}
