import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from 'react-bootstrap'

import Wrapper from 'components/shared/Wrapper'
import Button from 'components/shared/Button'
import Clickable from 'components/shared/Clickable'
import Heading from 'components/shared/Heading'
import Description from 'components/shared/Description'
import * as auth from 'http/auth'
import i18n from 'i18n'
import * as validators from 'components/shared/Form/validators'
import Input from 'components/shared/Form/Input'

const doResetPassword = onSuccess => ({ email }) =>
  auth.resetPassword(email).then(onSuccess)

const ForgotPasswordForm = ({
  invalid,
  submitting,
  onFormToggle,
  onSuccess,
  handleSubmit
}) => (
  <form onSubmit={handleSubmit(doResetPassword(onSuccess))}>
    <Wrapper>
      <Heading className="text-center">{i18n('forgot-password-form')}</Heading>
      <Description className="text-center">
        {i18n('forgot-password-form-description')}
      </Description>
    </Wrapper>
    <Wrapper>
      <Field
        name="email"
        type="email"
        component={Input}
        label={i18n('email')}
        validate={[validators.emailValidator]}
        disabled={submitting}
      />
    </Wrapper>
    <Row>
      <Col xs={12} sm={6} smOffset={6}>
        <Wrapper small>
          <Button type="submit" disabled={invalid || submitting}>
            {i18n(submitting ? 'resetting-password' : 'reset-password')}
          </Button>
        </Wrapper>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Clickable
          className="pull-right"
          style={{ textDecoration: 'underline' }}
          onClick={onFormToggle}
        >
          {i18n('back-to-login')}
        </Clickable>
      </Col>
    </Row>
  </form>
)

ForgotPasswordForm.propTypes = {
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onFormToggle: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default reduxForm({
  form: 'forgotPassword'
})(ForgotPasswordForm)
