import React from 'react'
import { bool, func, arrayOf, shape } from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Navbar,
  Nav,
  NavItem,
  Image as BootstrapImage,
  FormGroup,
  InputGroup
} from 'react-bootstrap'
import { bindActionCreators } from 'redux'

import NavigationItem from './NavigationItem'
import AddBusinessButton from './AddBusinessButton'
import LogoutButton from './LogoutButton'
import Modal from 'components/shared/Modal'
import NewBusinessModal from 'components/shared/NewBusinessModal'
import SearchBar from 'components/shared/SearchBar'
import { logOut } from 'components/shared/AuthModal/actions'
import {
  openModal,
  closeModal
} from 'components/shared/NewBusinessModal/actions'
import { navigationShape } from './shapes'
import i18n from 'i18n'
import media from 'utils/media'

const Image = styled(BootstrapImage)`
  height: 60px;
  width: 60px;
`

const SearchGroup = styled(props => (
  <Navbar.Form {...props}>
    <FormGroup style={{ display: 'inline' }}>
      <InputGroup style={{ display: 'table' }}>
        <SearchBar onNavigationBar={true} />
        <span
          className="input-group-addon"
          style={{ width: '1%', opacity: 0 }}
        />
      </InputGroup>
    </FormGroup>
  </Navbar.Form>
))`
  border: none !important;
  padding: 0 0 0 10px !important
  margin-top: 20px !important;
  ${media.tablet`
    margin-top: 0px !important;
    margin-left: 50px !important;
  `};

  ${media.desktop`
    margin-top: 10px !important;
  `};
`

const Navigation = ({
  rhs,
  authenticated,
  starting,
  isOpen,
  logOut,
  openModal,
  closeModal,
  displaySearchBar
}) => (
  <Navbar fluid collapseOnSelect>
    <Navbar.Header className="pull-left">
      <Navbar.Toggle style={{ marginTop: '20px' }} />
      <Navbar.Brand className="hidden-xs hidden-sm">
        <Link to="/">
          <Image src="/images/logos/fial-logo-76x76.png" />
        </Link>
      </Navbar.Brand>
    </Navbar.Header>
    <Navbar.Header>
      {displaySearchBar && <SearchGroup className="visible-xs visible-sm" />}
    </Navbar.Header>
    <Navbar.Collapse>
      <Nav className="pull-right">
        {rhs.map(({ path, slug }, i) => (
          <NavigationItem key={path} eventKey={i + 1} path={path} slug={slug} />
        ))}
        <NavItem className="visible-xs visible-sm">
          {authenticated ? (
            <div onClick={logOut}>
              {i18n(starting ? 'logging-out' : 'logout')}
            </div>
          ) : (
            <div onClick={() => openModal()}>{i18n('add-business-button')}</div>
          )}
        </NavItem>
        <NavItem className="hidden-xs hidden-sm">
          {authenticated ? (
            <LogoutButton logOut={logOut} starting={starting} />
          ) : (
            <AddBusinessButton onClick={() => openModal()} />
          )}
        </NavItem>
      </Nav>
      {displaySearchBar && <SearchGroup className="hidden-xs hidden-sm" />}
    </Navbar.Collapse>
    <Modal
      isOpen={isOpen}
      contentLabel="NewBusinessModal"
      onRequestClose={closeModal}
    >
      <NewBusinessModal closeModal={closeModal} authenticated={authenticated} />
    </Modal>
  </Navbar>
)

Navigation.propTypes = {
  authenticated: bool.isRequired,
  starting: bool.isRequired,
  logOut: func.isRequired,
  openModal: func.isRequired,
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
  displaySearchBar: bool.isRequired,
  rhs: arrayOf(shape(navigationShape)).isRequired
}

const mapStateToProps = ({
  navigation: { rhs },
  auth: { authenticated, starting },
  business: { isOpen },
  router: {
    location: { pathname }
  }
}) => ({
  rhs,
  authenticated,
  isOpen,
  starting,
  displaySearchBar: ['/', '/admin'].indexOf(pathname) === -1
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logOut,
      openModal,
      closeModal
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation)
