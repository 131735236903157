import React from 'react'
import { arrayOf, shape, func } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'

import Wrapper from 'components/shared/Wrapper'
import Button from 'components/shared/Button'
import Heading from 'components/shared/Heading'
import Description from 'components/shared/Description'
import Input from 'components/shared/Form/Input'
import Select from 'components/shared/Form/Select'
import TextArea from 'components/shared/Form/TextArea'
import { ENTITY_FIELDS, NEW_BUSINESS_FORM } from 'utils/constants'
import * as validators from 'components/shared/Form/validators'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'

const DESCRIPTION_MAX_LENGTH = 50
const descriptionValidator = validators.textAreaValidator(
  i18n('missing-description'),
  DESCRIPTION_MAX_LENGTH
)

const validate = (values, { isAdmin }) => {
  const errors = {}

  errors[ENTITY_FIELDS.NAME] = validators.companyNameValidator(
    values[ENTITY_FIELDS.NAME]
  )
  errors[ENTITY_FIELDS.WEBSITE] = validators.urlValidator(
    values[ENTITY_FIELDS.WEBSITE]
  )

  if (isAdmin) {
    // either phone or email must be valid
    if (
      validators.emailValidator(values[ENTITY_FIELDS.EMAIL]) &&
      validators.phoneValidator(values[ENTITY_FIELDS.PHONE])
    ) {
      errors[ENTITY_FIELDS.EMAIL] = errors[ENTITY_FIELDS.PHONE] = i18n(
        'missing-organisation-phone-or-email'
      )
    }
  } else {
    errors[ENTITY_FIELDS.EMAIL] = validators.emailValidator(
      values[ENTITY_FIELDS.EMAIL]
    )
    errors[ENTITY_FIELDS.PHONE] = validators.phoneValidator(
      values[ENTITY_FIELDS.PHONE]
    )
    errors[ENTITY_FIELDS.ABN] = validators.companyNumberValidator(
      values[ENTITY_FIELDS.ABN]
    )
    errors[ENTITY_FIELDS.YEAR] = validators.yearValidator(
      values[ENTITY_FIELDS.YEAR]
    )
    errors[ENTITY_FIELDS.SIZE] = validators.sizeValidator(
      values[ENTITY_FIELDS.SIZE]
    )
    for (let i = 0; i < descriptionValidator.length; i++) {
      errors[ENTITY_FIELDS.DESCRIPTION] = descriptionValidator[i](
        values[ENTITY_FIELDS.DESCRIPTION]
      )
      if (errors[ENTITY_FIELDS.DESCRIPTION]) break
    }
  }
  return errors
}

const AboutBusiness = ({ handleSubmit, onSubmit, sizes }) => (
  <Row>
    <Col xs={10} xsOffset={1} sm={6} smOffset={3}>
      <Wrapper>
        <Heading className="text-center">{i18n('get-started')}</Heading>
        <Description className="text-center">
          {i18n('about-business-description')}
        </Description>
      </Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Field
            name={ENTITY_FIELDS.NAME}
            type="text"
            component={Input}
            label={i18n('business-name')}
          />
          <Field
            name={ENTITY_FIELDS.ABN}
            type="text"
            component={Input}
            label={i18n('abn-acn')}
          />
          <Field
            name={ENTITY_FIELDS.EMAIL}
            type="text"
            component={Input}
            label={i18n('business-email')}
          />
          <Field
            name={ENTITY_FIELDS.WEBSITE}
            type="text"
            component={Input}
            label={i18n('business-website')}
          />
          <Field
            name={ENTITY_FIELDS.PHONE}
            type="text"
            component={Input}
            label={i18n('business-phone')}
          />
          <Field
            name={ENTITY_FIELDS.YEAR}
            type="text"
            component={Input}
            label={i18n('established-year')}
          />
          <Field
            name={ENTITY_FIELDS.SIZE}
            placeholder={i18n('size-placeholder')}
            component={Select}
            label={i18n('business-size')}
            options={sizes}
          />
          <Field
            name={ENTITY_FIELDS.DESCRIPTION}
            type="textarea"
            rows="3"
            component={TextArea}
            placeholder={`${DESCRIPTION_MAX_LENGTH} ${i18n('word-limit')}`}
            label={i18n('description')}
          />
        </Wrapper>
        <Row>
          <Col xs={12}>
            <Button type="submit">{i18n('next')}</Button>
          </Col>
        </Row>
      </form>
    </Col>
  </Row>
)

AboutBusiness.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  sizes: arrayOf(shape(slugShape)).isRequired
}

export default reduxForm({
  form: NEW_BUSINESS_FORM,
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(AboutBusiness)
