import styled from 'styled-components'

import Paragraph from 'components/shared/Paragraph'
import media from 'utils/media'

export default styled(Paragraph)`
  color: #555;
  font-size: 20px;
  line-height: 28px;
  ${media.tablet`
    font-size: 15px;
    line-height: 20px;
  `};
`
