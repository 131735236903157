import React from 'react'
import { func, bool } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'

import Wrapper from 'components/shared/Wrapper'
import Button from 'components/shared/Button'
import Heading from 'components/shared/Heading'
import Description from 'components/shared/Description'
import Checkbox from 'components/shared/Form/Checkbox'
import Input from 'components/shared/Form/Input'
import { ENTITY_FIELDS, NEW_BUSINESS_FORM } from 'utils/constants'
import * as validators from 'components/shared/Form/validators'
import i18n from 'i18n'

const HAS_AGREED_TERMS = 'hasAgreed'

const validate = (values, { isAdmin }) => {
  const errors = {}

  if (!isAdmin) {
    errors[ENTITY_FIELDS.POC_FIRSTNAME] = validators.firstNameValidator(
      values[ENTITY_FIELDS.POC_FIRSTNAME]
    )
    errors[ENTITY_FIELDS.POC_LASTNAME] = validators.lastNameValidator(
      values[ENTITY_FIELDS.POC_LASTNAME]
    )
    errors[HAS_AGREED_TERMS] = validators.hasAgreedTermsValidator(
      values[HAS_AGREED_TERMS]
    )
  }
  return errors
}

const PointOfContact = ({ handleSubmit, onSubmit, isAdmin }) => (
  <Row>
    <Col xs={10} xsOffset={1} sm={6} smOffset={3}>
      <Wrapper>
        <Heading className="text-center">{i18n('poc')}</Heading>
        <Description className="text-center">
          {i18n('poc-description')}
        </Description>
      </Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Field
            name={ENTITY_FIELDS.POC_FIRSTNAME}
            type="text"
            component={Input}
            label={i18n('firstname')}
          />
          <Field
            name={ENTITY_FIELDS.POC_LASTNAME}
            type="text"
            component={Input}
            label={i18n('lastname')}
          />
          <Field
            name={ENTITY_FIELDS.POC_EMAIL}
            type="text"
            component={Input}
            label={i18n('personal-email')}
          />
          {!isAdmin && (
            <div>
              <Field
                name={HAS_AGREED_TERMS}
                type="checkbox"
                component={Checkbox}
                value="agree"
                label={
                  <span>
                    {`${i18n('i-agree')} `}
                    <a
                      style={{ color: '#555', textDecoration: 'underline' }}
                      href="https://fial.com.au/tandcs"
                      target="blank"
                    >
                      {i18n('terms')}
                    </a>
                  </span>
                }
              />
            </div>
          )}
        </Wrapper>
        <Row>
          <Col xs={12}>
            <Button type="submit">{i18n('submit')}</Button>
          </Col>
        </Row>
      </form>
    </Col>
  </Row>
)

PointOfContact.propTypes = {
  isAdmin: bool.isRequired,
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  invalid: bool.isRequired
}

export default reduxForm({
  form: NEW_BUSINESS_FORM,
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(PointOfContact)
