export const SITE_TITLE =
  process.env.REACT_APP_TITLE || 'Australian Food & Agribusiness Directory'

export const AFAD_API_URI =
  process.env.REACT_APP_AFAD_API_URI || 'http://localhost:3001'

export const NOTIFICATION_TIMEOUT =
  process.env.REACT_APP_NOTIFICATION_TIMEOUT || 2000

// screen size break points
export const XL = 1140 // pixel
export const LG = 992 // pixel
export const MD = 768 // pixel
export const SM = 576 // pixel

export const PENDING_ENTITY = 'pending'
export const ACTIVE_ENTITY = 'active'
export const DISABLED_ENTITY = 'disabled'

export const NEW_BUSINESS_FORM = 'newBusiness'

export const ENTITY_STATUS = [PENDING_ENTITY, ACTIVE_ENTITY, DISABLED_ENTITY]

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

export const REFRESH_TOKEN_KEY = 'refreshToken'

export const ADMIN_PATHS = [
  'api/v1/entity',
  'api/v1/auth/logout',
  'api/v1/admin/entity/approve',
  'api/v1/admin/entity/reject',
  'api/v1/admin/entity/disable',
  'api/v1/admin/entity/enable',
  'api/v1/admin/entity'
]

export const ADMIN_FILTERS = {
  industry: {
    id: 'industry',
    name: 'Industry Sector',
    isDefault: true,
    filter: text => entity =>
      entity.industries.some(industry =>
        industry.name.toLowerCase().includes(text)
      )
  },
  category: {
    id: 'category',
    name: 'Product Category',
    isDefault: false,
    filter: text => entity =>
      entity.categories.some(category =>
        category.name.toLowerCase().includes(text)
      )
  },
  name: {
    id: 'name',
    name: 'Organisation name',
    isDefault: false,
    filter: text => entity => entity.name.toLowerCase().includes(text)
  },
  poc: {
    id: 'poc',
    name: 'User Name',
    isDefault: false,
    filter: text => entity =>
      entity.poc.firstName.toLowerCase().includes(text) ||
      entity.poc.lastName.toLowerCase().includes(text)
  }
}

export const ENTITY_FIELDS = {
  NAME: 'name',
  ABN: 'abn',
  SIZE: 'size',
  YEAR: 'year',
  DESCRIPTION: 'description',
  PHONE: 'phone',
  WEBSITE: 'website',
  EMAIL: 'businessEmail',
  ADDRESSES: 'addresses',
  INDUSTRIES: 'industries',
  CATEGORIES: 'categories',
  POC_FIRSTNAME: 'firstName',
  POC_LASTNAME: 'lastName',
  POC_EMAIL: 'email'
}

export const MII_MARKETS = [
  'China',
  'Hong Kong',
  'Indonesia',
  'Japan',
  'Malaysia',
  'Singapore',
  'South Korea',
  'Vietnam'
]

export const MII_PRODUCTS = {
  '011': 'Beef',
  '016': 'Meat, salted or dried',
  '017': 'Meat, prepared or preserved',
  '012': 'Meat (excl beef), f.c.f.',
  '022': 'Milk, cream, whey & yoghurt',
  '023': 'Butter',
  '024': 'Cheese & curd',
  '025': "Birds' eggs",
  '034': 'Fish, f.c.f.',
  '035': 'Fish, salted or dried',
  '036': 'Crustaceans, f.c.f.',
  '037': 'Seafood, prepared or preserved',
  '041': 'Wheat',
  '042': 'Rice',
  '043': 'Barley',
  '044': 'Maize',
  '046': 'Wheat flour',
  '047': 'Other cereal flours',
  '048': 'Cereal preparations',
  '054': 'Vegetables, f.c.f.',
  '056': 'Vegetables, prepared or preserved',
  '057': 'Fruit & nuts',
  '058': 'Fruit, prepared or preserved',
  '059': 'Fruit juices',
  '061': 'Sugars, molasses & honey',
  '062': 'Sugar confectionery',
  '071': 'Coffee & substitutes',
  '072': 'Cocoa',
  '073': 'Chocolate & preparations of cocoa',
  '074': 'Tea & mate',
  '075': 'Spices',
  '091': 'Margarine',
  '111': 'Non-alcoholic beverages, nes',
  '112': 'Alcoholic beverages',
  '411': 'Animal oils & fats',
  '421': 'Fixed vegetable oils & fats, soft',
  '422': 'Fixed vegetable oils & fats, hard',
  '431': 'Other animal or veg oils, fats & waxes'
}
