import React from 'react'
import { compose } from 'recompose'
import { withGoogleMap, GoogleMap as ReactGoogleMap } from 'react-google-maps'

import Markers from './Markers'

const options = {
  disableDefaultUI: true,
  gestureHandling: 'none',
  zoomControl: false,
  styles: [
    { elementType: 'geometry', stylers: [{ color: '#cccccc' }] },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#FFFFFF' }]
    }
  ]
}

const GoogleMap = compose(withGoogleMap)(
  ({ disabled, locations, onMarkerClicked }) => (
    <ReactGoogleMap
      defaultZoom={4}
      center={{ lat: -24.994167, lng: 134.866944 }}
      options={disabled ? options : {}}
    >
      <Markers locations={locations} onClick={onMarkerClicked} />
    </ReactGoogleMap>
  )
)

export default GoogleMap
