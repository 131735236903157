import React from 'react'
import { bool } from 'prop-types'
import Select from 'react-select'

const getOptionLabel = option => option.name

const getOptionValue = option => option.id

const StyledSelect = ({ borderRight, ...props }) => (
  <Select
    styles={{
      control: provided => ({
        ...provided,
        minHeight: '46px',
        border: 'none',
        borderRadius: 0,
        borderBottom: '1px solid #aaa !important',
        borderRight: borderRight ? '1px solid #000' : 0,
        // This line disable the blue border
        boxShadow: 0,
        '&:hover': {
          border: 0
        }
      }),
      option: provided => ({
        ...provided,
        borderBottom: '1px solid #f3f3f3',
        textAlign: 'left'
      }),
      menuList: provided => ({
        ...provided,
        padding: '0px 5px'
      })
    }}
    getOptionLabel={getOptionLabel}
    getOptionValue={getOptionValue}
    {...props}
    components={{ IndicatorSeparator: null }}
  />
)

StyledSelect.propTypes = {
  borderRight: bool
}

export default StyledSelect
