const contact = {
  'contact-us': 'Contact Us',
  'contact-form-error': error =>
    `There was an error submitting the form. : ${error}`,
  'contact-success': 'Your message has been sent successfully!',
  topic: 'Topic',
  'topic-placeholder': 'Select a topic',
  'missing-topic': 'Please select a topic',
  'select-affiliate-label': 'Are you associated with any of the following'
}

export default contact
