import { handleActions } from 'redux-actions'

import {
  updateFilters,
  updateFilteredResult,
  updateKeywords,
  updatePageData,
  startSearching,
  searchSuccess,
  searchFailure
} from './actions'

export const INITIAL_STATE = {
  error: null,
  searching: false,
  result: {
    all: [],
    filtered: [],
    popular: [],
    current: [],
    currentPage: 0,
    pages: [],
    totalPages: 0
  },
  keywords: {
    all: [], // containing all keywords
    industries: [],
    categories: [],
    names: [],
    locations: []
  },
  filters: {
    industries: [],
    sizes: []
  }
}

export default handleActions(
  {
    [updateFilters]: (state, { payload: { filters } }) => ({
      ...state,
      filters
    }),
    [updateKeywords]: (state, { payload: { keywords } }) => ({
      ...state,
      keywords
    }),
    [updatePageData]: (state, { payload: { data } }) => ({
      ...state,
      result: {
        ...state.result,
        ...data
      }
    }),
    [startSearching]: state => ({
      ...state,
      searching: true,
      error: null
    }),
    [searchSuccess]: (state, { payload: { result } }) => ({
      ...state,
      searching: false,
      result
    }),
    [updateFilteredResult]: (state, { payload: { result } }) => ({
      ...state,
      result: {
        ...state.result,
        ...result
      }
    }),
    [searchFailure]: (state, { payload: { error } }) => ({
      ...state,
      searching: false,
      error
    })
  },
  INITIAL_STATE
)
