/* eslint-disable camelcase */
import axios from 'axios'

import store from 'store'
import { logOut } from 'components/shared/AuthModal/actions'
import { AFAD_API_URI, REFRESH_TOKEN_KEY, ADMIN_PATHS } from 'utils/constants'

let token

const client = axios.create({
  baseURL: AFAD_API_URI
})
client.defaults.headers.post['Content-Type'] = 'application/json'

export const setAuthorisationHeader = accessToken => (token = accessToken)

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

client.interceptors.request.use(
  config => {
    if (ADMIN_PATHS.some(url => config.url.includes(url)) && token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)

client.interceptors.response.use(
  response => response,
  error => {
    const {
      config,
      response: { status }
    } = error
    const originalRequest = config
    if (status === 419) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        delete client.defaults.headers.common['Authorization']
        const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY)
        client
          .post('api/v1/auth/token', {
            refreshToken
          })
          .then(
            ({ data: { token } }) => {
              isAlreadyFetchingAccessToken = false
              setAuthorisationHeader(token)
              onAccessTokenFetched(token)
            },
            () => {
              // can't request a new access token
              // log out
              subscribers = []
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched()
              store.dispatch(logOut())
            }
          )
      }

      const retryOriginalRequest = new Promise((resolve, reject) => {
        addSubscriber(access_token => {
          if (access_token) {
            originalRequest.headers.Authorization = `Bearer ${access_token}`
            return resolve(axios(originalRequest))
          }
          reject(new Error('Invalid Refresh Token'))
        })
      })
      return retryOriginalRequest
    }
    return Promise.reject(error)
  }
)

export default client
