import React from 'react'
import { oneOfType, object, string } from 'prop-types'
import styled from 'styled-components'

import Button from 'components/shared/Button'

const StyledButton = styled(Button)`
  border-radius: 0 25px 25px 0 !important;
  height: 100%;
  border: none;
  color: #fff;
`

const SearchButton = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
)

SearchButton.propTypes = {
  children: oneOfType([object, string]).isRequired
}

export default SearchButton
