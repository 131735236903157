import React from 'react'
import { array, func, object, string } from 'prop-types'
import styled from 'styled-components'

import InputWithIcon from 'components/shared/InputWithIcon'
import Select from 'components/shared/Select'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

const FilterBar = ({
  onFilterTypeChange,
  onFilterTextChange,
  typeValue,
  typeOptions,
  textValue
}) => (
  <Wrapper small>
    <div style={{ width: '250px' }}>
      <Select
        borderRight={true}
        onChange={onFilterTypeChange}
        value={typeValue}
        options={typeOptions}
        components={{ IndicatorSeparator: null }}
      />
    </div>
    <div style={{ flex: 1 }}>
      <InputWithIcon
        icon="search"
        value={textValue}
        onChange={onFilterTextChange}
      />
    </div>
  </Wrapper>
)

FilterBar.propTypes = {
  onFilterTypeChange: func.isRequired,
  onFilterTextChange: func.isRequired,
  typeOptions: array.isRequired,
  typeValue: object.isRequired,
  textValue: string
}

export default FilterBar
