import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SITE_TITLE } from 'utils/constants'
import { setTitle } from './actions'

class Title extends React.Component {
  title = this.props.title
    ? `${SITE_TITLE} - ${this.props.title}`
    : `${SITE_TITLE}`

  componentDidMount() {
    this.props.setTitle(this.title)
  }

  render() {
    document.title = this.title
    return null
  }
}

Title.propTypes = {
  title: PropTypes.string,
  setTitle: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(Title)
