import { all } from 'redux-saga/effects'

import { authSagas } from 'components/shared/AuthModal/sagas'
import { searchSagas } from 'components/Page/Search/sagas'
import { newBusinessSagas } from 'components/shared/NewBusinessModal/sagas'
import { adminSagas } from 'components/Page/Admin/sagas'
import { initSagas } from './initSagas'

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...searchSagas,
    ...initSagas,
    ...newBusinessSagas,
    ...adminSagas
  ])
}
