import React from 'react'
import { arrayOf, string } from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import TagIcon from './TagIcon'
import i18n from 'i18n'

import Span from './Span'

const Categories = ({ categories }) => (
  <Row>
    <Col xs={12}>
      <TagIcon />
      <Span bold>{i18n('categories')}</Span>
    </Col>
    <Col xs={12} style={{ paddingLeft: '30px' }}>
      <Span small>{categories.join(', ')}</Span>
    </Col>
  </Row>
)

Categories.propTypes = {
  categories: arrayOf(string).isRequired
}

export default Categories
