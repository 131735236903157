import { createActions } from 'redux-actions'

export const {
  auth: {
    openAuthModal,
    closeAuthModal,
    logIn,
    logOut,
    start,
    logInSuccess,
    logOutSuccess,
    failure
  }
} = createActions({
  '@auth/logIn': ({ email, password }) => ({
    email,
    password
  }),
  '@auth/logOut': undefined,
  '@auth/start': action => ({ action }),
  '@auth/logInSuccess': data => data,
  '@auth/logOutSuccess': undefined,
  '@auth/failure': error => ({ error }),
  '@auth/openAuthModal': undefined,
  '@auth/closeAuthModal': undefined
})
