import React from 'react'
import { array, object } from 'prop-types'
import styled from 'styled-components'

import OrganisationCell from 'components/shared/OrganisationCell'
import Wrapper from 'components/shared/Wrapper'
import Map from 'components/shared/Map'

const StyledWrapper = styled(Wrapper)`
  margin-top: 50px;
`

const processData = data => {
  const locations = []
  data.forEach(entity => {
    entity.locationAddresses.forEach(address =>
      locations.push({
        id: entity.id,
        latitude: address.value.latitude,
        longitude: address.value.longitude
      })
    )
  })

  return {
    selectedEntity: data[Math.floor(Math.random() * data.length)],
    locations,
    data
  }
}

class MapView extends React.Component {
  state = processData(this.props.data)

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return processData(nextProps.data)
    }
    return null
  }

  getEntity = location => {
    return this.props.data.find(({ id }) => {
      return location.id === id
    })
  }

  onMarkerClicked = location =>
    this.setState({
      selectedEntity: this.getEntity(location)
    })

  render() {
    const { selectedEntity, locations } = this.state
    return (
      <StyledWrapper>
        <Map
          locations={locations}
          onMarkerClicked={this.onMarkerClicked}
          mapOnly
        />
        {selectedEntity && (
          <OrganisationCell
            onHeaderClick={() =>
              this.props.history.push(`/organisation/${selectedEntity.id}`)
            }
            entity={selectedEntity}
          />
        )}
      </StyledWrapper>
    )
  }
}

MapView.propTypes = {
  data: array.isRequired,
  history: object.isRequired
}

export default MapView
