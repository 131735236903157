import React from 'react'

const Loading = () => (
  <div className="pb-container loading">
    <button className="pb-button">
      <svg className="pb-progress-circle" viewBox="0 0 41 41">
        <path d="M38,20.5 C38,30.1685093 30.1685093,38 20.5,38" />
      </svg>
      <svg className="pb-checkmark" viewBox="0 0 70 70">
        <path d="m31.5,46.5l15.3,-23.2" />
        <path d="m31.5,46.5l-8.5,-7.1" />
      </svg>
      <svg className="pb-cross" viewBox="0 0 70 70">
        <path d="m35,35l-9.3,-9.3" />
        <path d="m35,35l9.3,9.3" />
        <path d="m35,35l-9.3,9.3" />
        <path d="m35,35l9.3,-9.3" />
      </svg>
    </button>
  </div>
)

export default Loading
