import React from 'react'
import { arrayOf, shape, func, object, bool } from 'prop-types'
import { Row, Col, Image } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import KeywordSearch from './KeywordSearch'
import LocationSearch from './LocationSearch'
import SearchButton from './SearchButton'
import Modal from 'components/shared/Modal'
import StyledWrapper from 'components/shared/Wrapper'
import { SM, XL } from 'utils/constants'
import {
  changeCategoryKeyword,
  changeNameKeyword,
  changeLocationKeyword,
  changeIndustryKeyword,
  search
} from 'components/Page/Search/actions'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
`

class SearchBar extends React.Component {
  state = {
    isSearchModalOpen: false
  }

  getOptionLabel = option => option.name

  getOptionValue = option => option.id

  filterOption = (option, input) => {
    if (input) {
      const { name, synonym } = option.data
      const lowerCaseInput = input.toLowerCase()
      if (name.toLowerCase().includes(lowerCaseInput)) {
        return true
      }

      if (synonym && synonym.toLowerCase().includes(lowerCaseInput)) {
        return true
      }
    }
    return false
  }

  onLocationChange = option => {
    this.props.changeLocationKeyword(option)
  }

  getNewOptionData = input => {
    if (!input) return undefined
    return {
      id: 'name',
      name: `Add new keyword '${input}'`
    }
  }

  onCreateOption = name => {
    this.props.changeNameKeyword(
      {
        id: name.toLowerCase(),
        name
      },
      true
    )
  }

  isValidNewOption = input =>
    !this.props.nameKeywords.some(({ id }) => id === input.toLowerCase())

  onChange = (options, actionMeta) => {
    const {
      categories,
      industries,
      changeCategoryKeyword,
      changeIndustryKeyword,
      changeNameKeyword
    } = this.props
    const isAdded = actionMeta.action === 'select-option'
    const value = isAdded ? actionMeta.option : actionMeta.removedValue

    if (categories.find(({ id }) => value.id === id)) {
      return changeCategoryKeyword(value, isAdded)
    }

    if (industries.find(({ id }) => value.id === id)) {
      return changeIndustryKeyword(value, isAdded)
    }

    if (!isAdded) {
      changeNameKeyword(value, false)
    }
  }

  search = () => {
    this.props.search()
    this.props.history.push('/search')
  }

  onFocus = () => {
    if (this.props.screenSize.width <= SM) {
      this.openSearchModal()
      return true
    }

    return false
  }

  openSearchModal = () =>
    this.setState({
      isSearchModalOpen: true
    })

  closeSearchModal = () =>
    this.setState({
      isSearchModalOpen: false
    })

  renderKeywordSearch = (shortText, onModal = false) => (
    <KeywordSearch
      placeholder={i18n(shortText ? 'search' : 'searchbar-placeholder')}
      onFocus={onModal ? null : this.onFocus}
      onNavigationBar={onModal || this.props.onNavigationBar}
      getOptionLabel={this.getOptionLabel}
      getOptionValue={this.getOptionValue}
      filterOption={this.filterOption}
      onChange={this.onChange}
      getNewOptionData={this.getNewOptionData}
      onCreateOption={this.onCreateOption}
      isValidNewOption={this.isValidNewOption}
      value={this.props.allKeywords}
      noBorder={onModal}
      options={this.props.industriesAndCategories}
    />
  )

  renderLocationSearch = (onModal = false) => (
    <LocationSearch
      onNavigationBar={onModal || this.props.onNavigationBar}
      getOptionLabel={this.getOptionLabel}
      getOptionValue={this.getOptionValue}
      onChange={this.onLocationChange}
      value={this.props.locationKeywords}
      options={this.props.locations}
      noBorder={onModal}
    />
  )

  render() {
    const { screenSize } = this.props

    const smallScreen = screenSize.width <= SM
    const shortText = screenSize.width <= XL

    return (
      <Wrapper>
        <div style={{ flex: 1 }}>{this.renderKeywordSearch(shortText)}</div>
        {!smallScreen && (
          <div style={{ flex: '0 0 110px' }}>{this.renderLocationSearch()}</div>
        )}
        <div style={{ flex: 0 }}>
          <SearchButton onClick={this.search}>
            {shortText ? (
              <Image src="/icons/search-white.svg" />
            ) : (
              i18n('search')
            )}
          </SearchButton>
        </div>
        <Modal
          isOpen={this.state.isSearchModalOpen}
          contentLabel="SearchModal"
          onRequestClose={this.closeSearchModal}
        >
          <Row>
            <Col xs={12}>
              <StyledWrapper />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <StyledWrapper small>
                {this.renderKeywordSearch(shortText, true)}
              </StyledWrapper>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>{this.renderLocationSearch(true)}</Col>
          </Row>
        </Modal>
      </Wrapper>
    )
  }
}

SearchBar.propTypes = {
  history: object.isRequired,
  industriesAndCategories: arrayOf(shape(slugShape)).isRequired,
  categories: arrayOf(shape(slugShape)).isRequired,
  industries: arrayOf(shape(slugShape)).isRequired,
  locations: arrayOf(shape(slugShape)).isRequired,
  allKeywords: arrayOf(shape(slugShape)).isRequired,
  nameKeywords: arrayOf(shape(slugShape)).isRequired,
  locationKeywords: arrayOf(shape(slugShape)).isRequired,
  screenSize: object.isRequired,
  changeNameKeyword: func.isRequired,
  changeCategoryKeyword: func.isRequired,
  changeIndustryKeyword: func.isRequired,
  changeLocationKeyword: func.isRequired,
  search: func.isRequired,
  onNavigationBar: bool
}

const mapStateToProps = ({
  data: {
    industriesAndCategories,
    categories,
    industries,
    locations,
    size: screenSize
  },
  search: {
    keywords: {
      all: allKeywords,
      names: nameKeywords,
      locations: locationKeywords
    }
  }
}) => ({
  nameKeywords,
  locationKeywords,
  allKeywords,
  industriesAndCategories,
  categories,
  industries,
  locations,
  screenSize
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeCategoryKeyword,
      changeNameKeyword,
      changeLocationKeyword,
      changeIndustryKeyword,
      search
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchBar)
)
