import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import Label from 'components/shared/Label'
import Wrapper from 'components/shared/Wrapper'
import Radio from 'components/shared/Form/Radio'

const RadioList = ({ name, label, data }) => (
  <Wrapper small>
    <Label>{label}</Label>
    {data.map(item => (
      <Field
        key={item.id}
        name={name}
        type="radio"
        component={Radio}
        value={item.id}
        label={item.name}
      />
    ))}
  </Wrapper>
)

RadioList.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}

export default RadioList
