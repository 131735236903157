import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import Wrapper from 'components/shared/Wrapper'
import Page from 'components/Page/Page'
import Title from 'components/shared/Navigation/Title'
import Banner from 'components/shared/Banner'
import Paragraph from 'components/shared/Paragraph'
import Affiliates from 'components/shared/Affiliates'
import i18n from 'i18n'
import media from 'utils/media'

const H1 = styled.h1`
  color: #4A4A4A;
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  ${media.tablet`
    font-size: 30px;
  `}
}
`

const StyledParagraph = styled(Paragraph)`
  color: #555;
  font-size: 16px;
  line-height: 28px;
  ${media.tablet`
    font-size: 14px;
  `}
}
`

const About = () => (
  <Page>
    <Title title={`${i18n('about').toUpperCase()}`} />
    <Banner src="images/backgrounds/about.jpg" />
    <Wrapper />
    <Wrapper padding>
      <Row>
        <Col xs={12}>
          <Wrapper>
            <H1>{i18n('about-header')}</H1>
          </Wrapper>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Wrapper>
            <StyledParagraph>{i18n('about-description')}</StyledParagraph>
          </Wrapper>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Affiliates />
        </Col>
      </Row>
    </Wrapper>
  </Page>
)

export default About
