import React from 'react'
import { array, func } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import Label from 'components/shared/Label'
import Wrapper from 'components/shared/Wrapper'
import SelectableSearch from './SelectableSearch'
import media from 'utils/media'
import i18n from 'i18n'

const StyledLabel = styled(Label)`
  font-size: 30px;
  line-height: 41px;
  ${media.tablet`
    font-size: 12px;
    line-height: 16px;
  `};
`

const PopularSearches = ({ searches, onClick }) => (
  <Wrapper>
    <Row>
      <Col xs={12}>
        <Wrapper small>
          <StyledLabel>{i18n('popular-searches')}</StyledLabel>
        </Wrapper>
      </Col>
    </Row>
    <Row>
      {searches.map(search => (
        <SelectableSearch
          key={search.id}
          name={search.name}
          img={`images/${search.id}.jpg`}
          onClick={() => onClick(search)}
        />
      ))}
    </Row>
  </Wrapper>
)

PopularSearches.propTypes = {
  searches: array.isRequired,
  onClick: func.isRequired
}

export default PopularSearches
