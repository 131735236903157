const search = {
  result: (num, location) =>
    `${num} organisation${num === 1 ? '' : 's'} matching your search${
      location ? ` in ${location}` : ''
    }`,
  'filter-by': 'Filter By',
  filter: 'Filter',
  'back-to-results': '< Back to search results',
  'see-business-profile': 'See full organisation profile',
  'map-view': 'Map View',
  'searchbar-placeholder': 'Search product or industry sector',
  'explore-further': 'Explore further',
  'explore-further-description':
    'The Australian Food and Agribusiness Directory is part of a connected network. Explore our partner websites and connections',
  'read-report': 'Read Industry report',
  'no-result-description':
    'To get more results, try adjusting your search term, removing your filters, or broadening your location',
  'popular-search-result': 'Here are some popular searches results:'
}

export default search
