import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import styled from 'styled-components'

export const Button = styled(({ children, negative, size, ...props }) => (
  <BootstrapButton {...props} bsSize={size || 'large'} block>
    {children}
  </BootstrapButton>
))`
  &&&& {
    background-color: ${({ theme: { colours }, negative, secondary, danger }) =>
      secondary
        ? '#fff'
        : negative
        ? colours.secondary
        : danger
        ? colours.error
        : colours.primary};
    color: ${({ theme: { colours }, secondary }) =>
      secondary ? colours.primary : '#fff'};
    border-color: ${({ theme: { colours }, secondary }) =>
      secondary ? colours.primary : ''};
    border-radius: 25px;
    outline: none;
  }
  &:hover:enabled {
    box-shadow: rgba(0, 0, 0, 0.125) 0px 3px 5px;
  }
`

export default Button
