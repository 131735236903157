import React from 'react'
import { object, string } from 'prop-types'

import Label from './Label'
import StyledTextArea from 'components/shared/TextArea'
import Paragraph from 'components/shared/Paragraph'
import Wrapper from 'components/shared/Wrapper'

export const TextArea = ({
  input,
  label,
  meta: { touched, error, warning },
  disabled,
  ...rest
}) => (
  <Wrapper small>
    <Label error={touched && error}>{label}</Label>
    <StyledTextArea
      className="form-control"
      {...input}
      {...rest}
      error={touched && error}
    />
    {touched &&
      ((error && (
        <Paragraph className="pull-right" error={touched && error}>
          {error}
        </Paragraph>
      )) ||
        (warning || <Paragraph className="pull-right">{warning}</Paragraph>))}
  </Wrapper>
)

TextArea.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
  label: string.isRequired,
  placeholder: string
}

export default TextArea
