import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import Label from 'components/shared/Label'
import Wrapper from 'components/shared/Wrapper'
import AffiliateLogo from './AffiliateLogo'
import media from 'utils/media'
import i18n from 'i18n'

const AFFILIATES = [
  {
    logo: 'aip',
    url: 'http://aipack.com.au'
  },
  {
    logo: 'aifst',
    url: 'https://www.aifst.asn.au'
  },
  {
    logo: 'fial',
    url: 'https://fial.com.au'
  },
  {
    logo: 'gov',
    url:
      'https://www.industry.gov.au/strategies-for-the-future/industry-growth-centres'
  }
]

const StyledLabel = styled(Label)`
  font-size: 30px;
  line-height: 41px;
  ${media.tablet`
    font-size: 12px;
    line-height: 16px;
  `};
`

const Affiliates = () => (
  <Wrapper>
    <Row>
      <Col xs={12}>
        <Wrapper>
          <StyledLabel>{i18n('sponsors')}</StyledLabel>
        </Wrapper>
      </Col>
    </Row>
    <Row>
      {AFFILIATES.map(({ logo, url }) => (
        <Col xs={6} sm={4} md={3} key={logo}>
          <AffiliateLogo logo={logo} url={url} />
        </Col>
      ))}
    </Row>
  </Wrapper>
)

export default Affiliates
