import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'

import Wrapper from 'components/shared/Wrapper'
import Paragraph from 'components/shared/Paragraph'
import SearchBar from 'components/shared/SearchBar'
import media from 'utils/media'
import i18n from 'i18n'

const BackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/images/backgrounds/home.jpg);
`

const ContentWrapper = styled.div`
  margin-top: 80px;
  ${media.tablet`
    margin-top: 10px;
  `};
`

const Heading = styled.h1`
  font-size: 45px;
  font-weight: 600;
  line-height: 61px;
  line-spacing: 1.2px;
  color: #fff;
  ${media.tablet`
    font-size: 26px;
    line-height: 36px;
  `};
`

const StyledParagraph = styled(Paragraph)`
  color: #fff;
  font-size: 24px;
  line-height: 35px;
  ${media.tablet`
    font-size: 14px;
    line-height: 26px;
  `};
`

const Header = () => (
  <Wrapper className="home-heading">
    <BackgroundImage />
    <ContentWrapper>
      <Row>
        <Col
          xs={12}
          sm={8}
          smOffset={2}
          md={6}
          mdOffset={3}
          className="text-center"
        >
          <Row>
            <Col xs={10} xsOffset={1} className="text-center">
              <Heading>{i18n('heading')}</Heading>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center">
              <Wrapper>
                <StyledParagraph>{i18n('caption')}</StyledParagraph>
              </Wrapper>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center">
              <SearchBar />
            </Col>
          </Row>
        </Col>
      </Row>
    </ContentWrapper>
  </Wrapper>
)

export default Header
