import styled from 'styled-components'
import media from 'utils/media'

const Label = styled.label`
  font-size: 24px;
  margin-right: 20px;
  font-weight: normal;
  color: ${({ error, theme }) =>
    error ? theme.colours.error : theme.colours.secondary};
  ${media.tablet`
    margin-right: 10px;
    font-size: 15px;
  `};
`

export default Label
