import React from 'react'
import { object, string } from 'prop-types'

import Label from './Label'
import StyledInput from 'components/shared/Input'
import Paragraph from 'components/shared/Paragraph'
import Wrapper from 'components/shared/Wrapper'
import InputWithCaption from 'components/shared/InputWithCaption'

const Input = ({
  input,
  label,
  type,
  caption,
  meta: { touched, error, warning },
  disabled
}) => (
  <Wrapper small>
    {label ? <Label error={touched && error}>{label}</Label> : null}
    {caption ? (
      <InputWithCaption
        {...input}
        type={type}
        error={touched && error}
        caption={caption}
        disabled={disabled}
      />
    ) : (
      <StyledInput
        {...input}
        type={type}
        error={touched && error}
        disabled={disabled}
      />
    )}
    {touched &&
      ((error && (
        <Paragraph className="pull-right" error={touched && error}>
          {error}
        </Paragraph>
      )) ||
        (warning || <Paragraph className="pull-right">{warning}</Paragraph>))}
  </Wrapper>
)

Input.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
  label: string.isRequired,
  caption: string
}

export default Input
