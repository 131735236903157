const common = {
  next: 'Next',
  previous: 'Previous',
  search: 'Search',
  edit: 'Edit',
  send: 'Send',
  sending: 'Sending',
  number: 'Number',
  name: 'Name',
  fullname: 'Full Name',
  'missing-full-name': 'Please enter your full name',
  firstname: 'First Name',
  'missing-first-name': 'Please enter your first name',
  lastname: 'Last Name',
  'missing-last-name': 'Please enter your last name',
  'organisation-name': 'Organisation Name',
  'missing-organisation-name': 'Please enter your organisation name',
  message: 'Message',
  'missing-message': 'Please enter your message',
  'work-email': 'Work Email',
  'invalid-email': 'Invalid email',
  'invalid-address': 'Invalid address',
  contact: 'Contact',
  email: 'Email',
  privacy: 'Privacy Policy',
  terms: 'Terms & Conditions',
  'admin-login': 'Admin Login',
  disclaimer: 'Disclaimer',
  'disclaimer-content':
    'The information contained in the Australian Food and Agribusiness Directory (AFAD) was prepared by Food Innovation Australia Ltd (FIAL) and its partners Australia Institute of Food Science & Technology (AIFST) and Australian Institute of Packaging (AIP). The content has been compiled from information and material supplied by third party sources and publicly available information, which may (in part) be inaccurate or incomplete. While we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the accuracy, reliability or currency with respect to the website or the information contained on the website for any purpose. Use or reliance upon this information is subject to and conditional upon the user not holding FIAL & partners liable in any way for any impact or consequence arising from any of the above factors or the user’s reliance on the contents of the AFAD.',
  'word-limit': 'Word limit',
  'max-word-error': max => `Please enter ${max} words or less`,
  website: 'Website',
  select: 'Select'
}

export default common
