import { handleActions } from 'redux-actions'

import {
  start,
  finish,
  loadSuccess,
  loadFailure,
  changePage,
  changeFilter,
  changeAffiliate,
  toggleApproving,
  toggleRejecting,
  toggleEnabling,
  toggleDisabling,
  changeEntities
} from './actions'

import deepClone from 'utils/deepClone'
import { ADMIN_FILTERS, ENTITY_STATUS } from 'utils/constants'

const replaceEntity = (state, status, newEntity) => {
  const replace = newEntity => entity => {
    if (entity.id === newEntity.id) return newEntity
    return entity
  }

  return {
    ...state,
    entities: {
      ...state.entities,
      [status]: {
        ...state.entities[status],
        all: state.entities[status].all.map(replace(newEntity)),
        filtered: state.entities[status].filtered.map(replace(newEntity))
      }
    }
  }
}

export const INITIAL_STATE = {
  loading: false,
  filter: {
    type: Object.values(ADMIN_FILTERS).find(({ isDefault }) => isDefault).id,
    text: ''
  },
  entities: ENTITY_STATUS.reduce((acc, status) => {
    acc[status] = {
      page: 0,
      all: [],
      filtered: []
    }
    return acc
  }, {}),
  toApprove: [],
  toReject: [],
  toEnable: [],
  toDisable: [],
  error: null
}

export default handleActions(
  {
    [start]: state => ({
      ...state,
      loading: true
    }),
    [finish]: state => ({
      ...state,
      loading: false
    }),
    [loadSuccess]: (
      state,
      {
        payload: {
          entities: { pending, active, disabled }
        }
      }
    ) => ({
      ...state,
      loading: false,
      entities: {
        pending: {
          page: 0,
          all: pending,
          filtered: pending
        },
        active: {
          page: 0,
          all: active,
          filtered: active
        },
        disabled: {
          page: 0,
          all: disabled,
          filtered: disabled
        }
      }
    }),
    [loadFailure]: (state, { payload: { error } }) => ({
      ...state,
      loading: false,
      error
    }),
    [changePage]: (state, { payload: { status, page } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [status]: {
          ...state.entities[status],
          page
        }
      }
    }),
    [changeAffiliate]: (state, { payload: { status, index, affiliate } }) => {
      const newEntity = deepClone(state.entities[status].filtered[index])
      newEntity.newAffiliate = affiliate
      return replaceEntity(state, status, newEntity)
    },
    [toggleApproving]: (state, { payload: { index } }) => {
      const newEntity = deepClone(state.entities.pending.filtered[index])
      newEntity.approving = !newEntity.approving
      newEntity.rejecting = false
      return replaceEntity(state, 'pending', newEntity)
    },
    [toggleRejecting]: (state, { payload: { index } }) => {
      const newEntity = deepClone(state.entities.pending.filtered[index])
      newEntity.rejecting = !newEntity.rejecting
      newEntity.approving = false
      return replaceEntity(state, 'pending', newEntity)
    },
    [toggleEnabling]: (state, { payload: { index } }) => {
      const newEntity = deepClone(state.entities.disabled.filtered[index])
      newEntity.enabling = !newEntity.enabling
      return replaceEntity(state, 'disabled', newEntity)
    },
    [toggleDisabling]: (state, { payload: { index } }) => {
      const newEntity = deepClone(state.entities.active.filtered[index])
      newEntity.disabling = !newEntity.disabling
      return replaceEntity(state, 'active', newEntity)
    },
    [changeFilter]: (state, { payload: { filter } }) => ({
      ...state,
      filter
    }),
    [changeEntities]: (state, { payload: { entities } }) => ({
      ...state,
      entities
    })
  },
  INITIAL_STATE
)
