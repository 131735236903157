import React from 'react'
import { array, object, func, arrayOf, shape } from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import Switch from 'react-switch'
import styled from 'styled-components'

import Grid from 'components/Page/Search/Grid'
import MapView from 'components/Page/Search/MapView'
import Paragraph from './Paragraph'
import MIIIntegration from './MIIIntegration'
import Wrapper from 'components/shared/Wrapper'
import Clickable from 'components/shared/Clickable'
import Filters from 'components/Page/Search/Filters'
import Span from 'components/shared/Span'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'
import media from 'utils/media'

const StyledSpan = styled(Span)`
  color: #4a4a4a;
  margin-right: 10px;
  font-size: 18px;
  line-height: 25px;
  ${media.tablet`
    font-size: 16px;
  `};
`

const NoResult = styled(Span)`
  color: #555;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  ${media.tablet`
    font-size: 12px;
  `};
`

const StyledButton = styled(Button)`
  background-color: #eee !important;
  border: none !important;
`

const BlankGap = styled.div`
  margin-top: 100px;
  ${media.tablet`
    margin-top: 30px;
  `};
`

class Result extends React.Component {
  state = {
    showMap: false,
    showFilters: false
  }

  toggleMapView = () =>
    this.setState({
      ...this.state,
      showMap: !this.state.showMap
    })

  toggleFiltersView = () =>
    this.setState({
      ...this.state,
      showFilters: !this.state.showFilters
    })

  render() {
    const { showMap, showFilters } = this.state
    const {
      result,
      filterSections,
      history,
      changePage,
      categories
    } = this.props

    const { filtered, current, currentPage, pages, totalPages } = result

    return (
      <Wrapper>
        <Row>
          {!showFilters && (
            <Col xs={9}>
              {showMap || filtered.length ? (
                <div>
                  <Paragraph>{i18n('result')(filtered.length)}</Paragraph>
                  {filtered.length === 0 && (
                    <Wrapper small>
                      <NoResult>{i18n('no-result-description')}</NoResult>
                    </Wrapper>
                  )}
                </div>
              ) : null}
            </Col>
          )}
          <Col xs={12} smHidden mdHidden lgHidden>
            <Wrapper small>
              <Clickable onClick={this.toggleFiltersView}>
                {showFilters ? (
                  i18n('back-to-results')
                ) : (
                  <StyledButton>{i18n('filter')}</StyledButton>
                )}
              </Clickable>
            </Wrapper>
          </Col>
          <Col xs={3} xsHidden smHidden>
            <StyledSpan>{i18n('map-view')}</StyledSpan>
            <Switch
              onChange={this.toggleMapView}
              checked={this.state.showMap}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {showMap && <MapView history={history} data={filtered} />}
            {showFilters && <Filters sections={filterSections} />}
            {!showMap && !showFilters && (
              <div>
                {!filtered.length ? (
                  <Row>
                    <Col xs={12} className="text-center">
                      <BlankGap />
                      <Paragraph>{i18n('result')(0)}</Paragraph>
                      <Wrapper small>
                        <NoResult>{i18n('no-result-description')}</NoResult>
                      </Wrapper>
                    </Col>
                  </Row>
                ) : null}
                <Grid
                  history={history}
                  data={current}
                  currentPage={currentPage}
                  pages={pages}
                  totalPages={totalPages}
                  changePage={changePage}
                  categories={categories}
                />
                <MIIIntegration categories={categories} />
              </div>
            )}
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

Result.propTypes = {
  history: object.isRequired,
  result: object.isRequired,
  filterSections: array.isRequired,
  changePage: func.isRequired,
  categories: arrayOf(shape(slugShape)).isRequired
}

export default Result
