import styled from 'styled-components'
import { ToggleButton } from 'react-bootstrap'
import Category from 'components/shared/Category'

export default styled(Category.withComponent(ToggleButton))`
  &&&.active {
    background: none;
    box-shadow: rgba(0, 0, 0, 0.125) 0px 3px 5px;
    border: none;
    color: ${props => props.theme.colours.primary};
  }
`
