import React from 'react'
import { object, bool, arrayOf, shape, func } from 'prop-types'
import styled from 'styled-components'

import Header from './Header'
import Footer from './Footer'
import Cell from './Cell'
import Content from './Content'
import IndustrySectors from './IndustrySectors'
import ContactInfo from './ContactInfo'
import Categories from './Categories'
import OrganisationInfo from './OrganisationInfo'
import Wrapper from 'components/shared/Wrapper'
import { slugShape } from 'utils/shapes'

const StyledWrapper = styled(Wrapper)`
  border-bottom: solid 1px #f3f3f3;
`

const Organisation = ({
  isOwner,
  reasons,
  organisation,
  openEditOrganisationModal,
  onDisablingSuccess
}) => (
  <Wrapper>
    <Cell>
      <Header
        isOwner={isOwner}
        openEditOrganisationModal={openEditOrganisationModal}
      >
        {organisation.name}
      </Header>
      <Content>
        <Wrapper small>
          <IndustrySectors
            industries={organisation.industries.map(({ name }) => name)}
          />
        </Wrapper>
        <StyledWrapper small>
          <ContactInfo organisation={organisation} />
        </StyledWrapper>
        <StyledWrapper small>
          <Categories
            categories={organisation.categories.map(({ name }) => name)}
          />
        </StyledWrapper>
        <OrganisationInfo organisation={organisation} />
      </Content>
    </Cell>
    <Footer
      reasons={reasons}
      id={organisation.id}
      name={organisation.name}
      isOwner={isOwner}
      onDisablingSuccess={onDisablingSuccess}
    />
  </Wrapper>
)

Organisation.propTypes = {
  reasons: arrayOf(shape(slugShape)).isRequired,
  organisation: object.isRequired,
  isOwner: bool.isRequired,
  openEditOrganisationModal: func.isRequired,
  onDisablingSuccess: func.isRequired
}

export default Organisation
