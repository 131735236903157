import { routerReducer as routing } from 'react-router-redux'
import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import navigation from 'components/shared/Navigation/reducer'
import auth from 'components/shared/AuthModal/reducer'
import search from 'components/Page/Search/reducer'
import business from 'components/shared/NewBusinessModal/reducer'
import admin from 'components/Page/Admin/reducer'
import data from 'redux/data/reducer'
import { logOutSuccess } from 'components/shared/AuthModal/actions'

const appReducer = combineReducers({
  routing,
  data,
  navigation,
  form,
  auth,
  admin,
  business,
  search
})

const rootReducer = (state, action) => {
  if (action.type === logOutSuccess().type) {
    // keep data and clear the others
    state = { data: state.data }
  }

  return appReducer(state, action)
}

export default rootReducer
