import { createActions } from 'redux-actions'

export const {
  data: {
    loadPredefinedData,
    startLoading,
    updateData,
    loadingFailure,
    changeScreenSize
  }
} = createActions({
  '@data/loadPredefinedData': undefined,
  '@data/startLoading': undefined,
  '@data/updateData': data => ({ data }),
  '@data/changeScreenSize': (width, height) => ({ size: { width, height } }),
  '@data/loadingFailure': error => ({ error })
})
