import { takeEvery, put, select } from 'redux-saga/effects'
import { reset as resetForm } from 'redux-form'

import { NEW_BUSINESS_FORM } from 'utils/constants'
import { notifyError } from 'utils/notify'
import { addEntity, updateEntities } from 'http/entity'
import errorToMessage from 'utils/errorToMessage'

import { addNewBusiness, startAdding, finishAdding, reset } from './actions'

export function* doAddNewBusiness({ payload: { business } }) {
  try {
    const isUpdate = yield select(state => state.business.isUpdate)
    yield put(startAdding())
    if (isUpdate) {
      yield updateEntities(business)
    } else {
      yield addEntity(business)
    }
    yield put(finishAdding())
    yield put(reset())
    yield put(resetForm(NEW_BUSINESS_FORM))
  } catch (error) {
    const msg = errorToMessage(error)
    yield put(finishAdding(msg))
    notifyError(msg)
  }
}

export const newBusinessSagas = [
  takeEvery(addNewBusiness().type, doAddNewBusiness)
]
