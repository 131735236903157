import React from 'react'
import { string, func } from 'prop-types'
import { Col } from 'react-bootstrap'
import styled from 'styled-components'

import Clickable from 'components/shared/Clickable'
import ImageCell from 'components/shared/ImageCell'
import media from 'utils/media'

const H4 = styled.h4`
  color: #555;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  ${media.tablet`
    font-size: 12px;
    line-height: 21px;
  `};
`

const SelectableSearch = ({ name, img, onClick }) => (
  <Col xs={12} sm={6} md={3}>
    <Clickable onClick={onClick}>
      <ImageCell img={img}>
        <H4 className="text-center">{name}</H4>
      </ImageCell>
    </Clickable>
  </Col>
)

SelectableSearch.propTypes = {
  name: string.isRequired,
  img: string.isRequired,
  onClick: func.isRequired
}

export default SelectableSearch
