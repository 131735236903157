import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { arrayOf, shape } from 'prop-types'
import styled from 'styled-components'

import Paragraph from './Paragraph'
import Span from 'components/shared/Span'
import FlexRow from 'components/shared/FlexRow'
import FlexCol from 'components/shared/FlexCol'
import ImageCell from 'components/shared/ImageCell'
import { slugShape } from 'utils/shapes'
import { MII_MARKETS, MII_PRODUCTS } from 'utils/constants'
import i18n from 'i18n'
import media from 'utils/media'

const H4 = styled.h4`
  color: #555;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  ${media.tablet`
    font-size: 12px;
    line-height: 21px;
  `};
`

const StyledParagraph = styled(Paragraph)`
  font-size: 16px;
  color: #000
  line-height: 25px;
  ${media.tablet`
    font-size: 14px;
  `};
`

const StyledSpan = styled(Span)`
  font-size: 14px;
  color: #000
  line-height: 25px;
  ${media.tablet`
    font-size: 12px;
  `};
`

const Button = styled.a`
  color: #fff;
  background-color: ${({ theme }) => theme.colours.primary} !important;
`

const AFCButton = styled(Button)`
  position: absolute;
  bottom: 30px;
  left: 40%;
`

const getMiiReport = categories => {
  const sitcs = []

  categories.forEach(category => {
    if (category.sitc) {
      sitcs.push(category.sitc)
    }
  })

  if (!sitcs.length) {
    // no sitc from the given categories so pick up a random one
    const products = Object.keys(MII_PRODUCTS)
    sitcs.push(products[Math.floor(Math.random() * products.length)])
  }

  const sitc = sitcs[Math.floor(Math.random() * sitcs.length)]
  const marketIndex = Math.floor(Math.random() * MII_MARKETS.length)
  let compareMarketIndex = 0
  do {
    compareMarketIndex = Math.floor(Math.random() * MII_MARKETS.length)
  } while (compareMarketIndex === marketIndex)

  return [
    {
      market: MII_MARKETS[marketIndex],
      product: MII_PRODUCTS[sitc],
      imgURL: `/images/mii/${sitc}.jpg`,
      url: `https://mii.fial.com.au/research-product?market=${encodeURI(
        MII_MARKETS[marketIndex]
      )}&productCode=${sitc}`
    },
    {
      market: MII_MARKETS[compareMarketIndex],
      product: MII_PRODUCTS[sitc],
      imgURL: `/images/mii/${sitc}.jpg`,
      url: `https://mii.fial.com.au/compare-product?market=${encodeURI(
        MII_MARKETS[marketIndex]
      )}&compareMarket=${encodeURI(
        MII_MARKETS[compareMarketIndex]
      )}&productCode=${sitc}`
    }
  ]
}

class MIIIntegration extends React.Component {
  miiReport = getMiiReport(this.props.categories)

  render() {
    const productReport = this.miiReport[0]
    const compareMarkets = this.miiReport[1]
    return (
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <Paragraph>{i18n('explore-further')}</Paragraph>
              <StyledParagraph>
                {i18n('explore-further-description')}
              </StyledParagraph>
            </Col>
          </Row>
          <FlexRow>
            <FlexCol xs={12} sm={6} md={4}>
              <ImageCell className="text-center" img={productReport.imgURL}>
                <H4>{`Exporting ${productReport.product} to ${
                  productReport.market
                }`}</H4>
                <Button
                  className="btn btn-info"
                  href={productReport.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i18n('read-report')}
                </Button>
              </ImageCell>
            </FlexCol>
            <FlexCol xs={12} sm={6} md={4}>
              <ImageCell className="text-center">
                <H4>Connect with Retail buyers in Asia</H4>
                <StyledSpan>
                  The Australian Food Catalogue is an initiative by created in
                  partnership with FIAL and government organisations to connect
                  international buyers to local suppliers
                </StyledSpan>
                <AFCButton
                  className="btn btn-info"
                  href="https://www.australianfoodcatalogue.com.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Explore
                </AFCButton>
              </ImageCell>
            </FlexCol>
            <FlexCol xs={12} sm={6} md={4}>
              <ImageCell img={compareMarkets.imgURL} className="text-center">
                <H4>{`Compare ${compareMarkets.product} exports in Asia`}</H4>
                <Button
                  className="btn btn-info"
                  href={compareMarkets.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i18n('read-report')}
                </Button>
              </ImageCell>
            </FlexCol>
          </FlexRow>
        </Col>
      </Row>
    )
  }
}

MIIIntegration.propTypes = {
  categories: arrayOf(shape(slugShape)).isRequired
}

export default MIIIntegration
