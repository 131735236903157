import React from 'react'
import { withProps } from 'recompose'
import { object, array, func, bool } from 'prop-types'
import { withScriptjs } from 'react-google-maps'
import styled from 'styled-components'

import ToggleButtonGroup from 'components/shared/ToggleButtonGroup'
import LocationSeachInput from 'components/shared/LocationSearchInput'
import GoogleMap from './GoogleMap'
import { GOOGLE_API_KEY } from 'utils/constants'
import media from 'utils/media'
import i18n from 'i18n'

const Wrapper = styled.div`
  margin-bottom: 25px;
  ${media.tablet`
    margin-bottom: 15px;
  `};
`

const Map = ({
  locations,
  mapOnly,
  containerElement,
  mapElement,
  onLocationSelected,
  onLocationRemoved,
  onMarkerClicked,
  disabled
}) => (
  <div>
    {!mapOnly && (
      <Wrapper>
        <LocationSeachInput
          placeholder={`${i18n('search')}...`}
          onLocationSelected={onLocationSelected}
        />
        <ToggleButtonGroup
          data={locations}
          onRemoved={onLocationRemoved}
          getKey={location => location.latitude + location.longitude}
          getName={location =>
            `${location.postal.streetNo} ${location.postal.streetName} ${
              location.postal.suburb
            } ${location.postal.state}`
          }
        />
      </Wrapper>
    )}
    <GoogleMap
      containerElement={containerElement}
      mapElement={mapElement}
      locations={locations}
      onMarkerClicked={onMarkerClicked}
      disabled={disabled}
    />
  </div>
)

Map.propTypes = {
  containerElement: object.isRequired,
  mapElement: object.isRequired,
  locations: array.isRequired,
  onLocationSelected: func,
  onMarkerClicked: func,
  onLocationRemoved: func,
  mapOnly: bool,
  disabled: bool
}

Map.defaultProps = {
  mapOnly: false,
  onMarkerClicked: () => {}
}

export default withProps({
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: '100%' }} />,
  containerElement: <div style={{ height: '600px' }} />,
  mapElement: <div style={{ height: '100%' }} />
})(withScriptjs(Map))
