import styled from 'styled-components'
import media from 'utils/media'

const Paragraph = styled.p`
  font-size: ${({ xs, md, lg, xlg }) =>
    xlg ? '20px' : lg ? '18px' : md ? '16px' : xs ? '12px' : '14px'};
  font-weight: ${({ bold }) => (bold ? '400' : '100')};
  line-height: ${({ xs }) => (xs ? '28px' : '36px')};
  color: ${({ error, theme, color }) =>
    error ? theme.colours.error : color || theme.colours.secondary};
  ${media.tablet`
    font-size: ${({ xs, md, lg, xlg }) =>
      xlg ? '16px' : lg ? '15px' : md ? '13px' : xs ? '9px' : '11px'};
  `};
`

export default Paragraph
