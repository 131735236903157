import React from 'react'
import { bool, string, func, object, oneOfType, array } from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'

import Wrapper from 'components/shared/Wrapper'
import ExitImage from 'components/shared/ExitImage'
import media from 'utils/media'

const StyledWrapper = styled(Wrapper)`
  padding: 10px;
  width: ${({ width }) => width || '100%'};
  height: 100%;

  ${media.tablet`
    width: 100%;
  `};
`

class ModalComponent extends React.Component {
  componentDidMount() {
    Modal.setAppElement('body')
  }

  onModalClose = () => {
    this.props.onModalClose()
  }

  render() {
    const {
      children,
      isOpen,
      onRequestClose,
      contentLabel,
      customStyle,
      width
    } = this.props

    return (
      <Modal
        closeTimeoutMS={100}
        isOpen={isOpen}
        contentLabel={contentLabel}
        style={{
          content: {
            padding: '0',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0'
          },
          ...customStyle
        }}
        onRequestClose={onRequestClose}
        shouldReturnFocusAfterClose={false}
      >
        <StyledWrapper width={width}>
          <Wrapper>
            <ExitImage onClick={onRequestClose} />
          </Wrapper>
          <div style={{ padding: '0 20px' }}>{children}</div>
        </StyledWrapper>
      </Modal>
    )
  }
}

ModalComponent.propTypes = {
  isOpen: bool.isRequired,
  contentLabel: string.isRequired,
  onModalClose: func,
  onRequestClose: func.isRequired,
  children: oneOfType([object, array]).isRequired,
  customStyle: object,
  width: string
}

ModalComponent.defaultProps = {
  customStyle: {}
}

export default ModalComponent
