import { handleActions } from 'redux-actions'

import { setTitle } from './actions'
import {
  logInSuccess,
  logOutSuccess
} from 'components/shared/AuthModal/actions'

export const PUBLIC_NAV = [
  { path: '/about', slug: 'about' },
  { path: '/contact', slug: 'contact' }
]

export const PRIVATE_NAV = [
  { path: '/admin', slug: 'admin' },
  { path: '/directory', slug: 'directory' }
]

export const INITIAL_STATE = {
  lhs: [],
  rhs: PUBLIC_NAV,
  title: null
}

export default handleActions(
  {
    [setTitle]: (state, { payload: { title } }) => ({ ...state, title }),
    [logInSuccess]: state => ({ ...state, rhs: PRIVATE_NAV }),
    [logOutSuccess]: state => ({ ...state, rhs: PUBLIC_NAV })
  },
  INITIAL_STATE
)
