import React from 'react'
import { object } from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import i18n from 'i18n'
import QuoteIcon from './QuoteIcon'
import Span from './Span'

const Categories = ({ organisation }) => (
  <Row>
    {organisation.abn && (
      <Col xs={12}>
        <Span bold>{`${i18n('abn').toUpperCase()}:`}</Span>
        <Span>{organisation.abn}</Span>
      </Col>
    )}
    {organisation.size && (
      <Col xs={12}>
        <Span bold>{`${i18n('organisation-size')}:`}</Span>
        <Span small>{organisation.size.name}</Span>
      </Col>
    )}
    {organisation.year && (
      <Col xs={12}>
        <Span bold>{`${i18n('year-established')}: `}</Span>
        <Span>{organisation.year}</Span>
      </Col>
    )}
    {organisation.description && organisation.description.length && (
      <Col xs={12}>
        <QuoteIcon />
        <Span>{organisation.description}</Span>
      </Col>
    )}
  </Row>
)

Categories.propTypes = {
  organisation: object.isRequired
}

export default Categories
