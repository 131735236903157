import React from 'react'
import { func, object, arrayOf, shape } from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import Page from 'components/Page/Page'
import Title from 'components/shared/Navigation/Title'
import Loading from 'components/shared/Loading'
import Wrapper from 'components/shared/Wrapper'
import Organisation from 'components/Page/Organisation'
import OrganisationNotFound from 'components/Page/Organisation/OrganisationNotFound'
import SimilarOrganisations from 'components/Page/Organisation/SimilarOrganisations'
import { openModal as openNewBusinessModal } from 'components/shared/NewBusinessModal/actions'
import { getEntity, getSimilarEntities } from 'http/entity'
import { notifyError } from 'utils/notify'
import errorToMessage from 'utils/errorToMessage'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #555;
  line-height: 19px;
  &:hover {
    color: #555;
    text-decoration: none;
  }
`

const OrganisationWrapper = styled(Wrapper)`
  background-color: ${({ error }) => (error ? '#fff' : '#f3f3f3')};
  padding: 30px 0;
`

class OrganisationContainer extends React.Component {
  state = {
    loading: true,
    organisation: null,
    similarOrganisations: [],
    isOwner: false
  }

  componentDidMount() {
    this.loadOrganisation()
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    const oldID = prevProps.match.params.organisationId
    const newID = match.params.organisationId

    if (oldID !== newID) {
      // load the new organisation
      this.loadOrganisation()
    }
  }

  loadOrganisation = async () => {
    const { user, match } = this.props
    const id = match.params.organisationId

    this.setState({
      loading: true,
      organisation: null,
      similarOrganisations: [],
      isOwner: false
    })

    try {
      const values = await Promise.all([getEntity(id), getSimilarEntities(id)])
      const organisation = values[0].data
      const similarOrganisations = values[1].data

      if (!organisation) {
        return this.setState({
          ...this.state,
          loading: false
        })
      }

      this.setState({
        ...this.state,
        loading: false,
        organisation,
        isOwner: user ? user.roles[0] === organisation.affiliates[0] : false,
        similarOrganisations
      })
    } catch (error) {
      notifyError(errorToMessage(error))
      this.setState({
        ...this.state,
        loading: false
      })
    }
  }

  openEditOrganisationModal = () =>
    this.props.openNewBusinessModal(this.state.organisation)

  onDisablingSuccess = () => this.props.history.push('/search')

  render() {
    const { loading, organisation, isOwner, similarOrganisations } = this.state
    const { match, reasons, history } = this.props
    return (
      <Page>
        <Title title={`${i18n('organisation').toUpperCase()}`} />
        {loading && <Loading />}
        {!loading && (
          <OrganisationWrapper error={!organisation}>
            <Row>
              <Col xs={12} sm={10} smOffset={1}>
                <Wrapper small>
                  <StyledLink to="/search">
                    {i18n('back-to-search-results')}
                  </StyledLink>
                </Wrapper>
                {!organisation && (
                  <OrganisationNotFound id={match.params.organisationId} />
                )}
                {organisation && (
                  <Organisation
                    openEditOrganisationModal={this.openEditOrganisationModal}
                    reasons={reasons}
                    isOwner={isOwner}
                    organisation={organisation}
                    similarOrganisations={similarOrganisations}
                    onDisablingSuccess={this.onDisablingSuccess}
                  />
                )}
              </Col>
            </Row>
          </OrganisationWrapper>
        )}
        {!loading && similarOrganisations.length && (
          <Wrapper>
            <Row>
              <Col xs={12} sm={10} smOffset={1}>
                <SimilarOrganisations
                  history={history}
                  organisations={similarOrganisations}
                />
              </Col>
            </Row>
          </Wrapper>
        )}
      </Page>
    )
  }
}

OrganisationContainer.propTypes = {
  history: object.isRequired,
  reasons: arrayOf(shape(slugShape)).isRequired,
  match: object.isRequired,
  user: object,
  openNewBusinessModal: func.isRequired
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openNewBusinessModal
    },
    dispatch
  )

const mapStateToProps = ({ auth: { user }, data: { reasons } }) => ({
  user,
  reasons: reasons
    .map(({ name }) => ({ id: name, name }))
    .concat({ id: 'other', name: 'Other' })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrganisationContainer))
