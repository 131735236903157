import { handleActions } from 'redux-actions'

import {
  openModal,
  closeModal,
  startAdding,
  finishAdding,
  addAddress,
  removeAddress,
  addCategory,
  removeCategory,
  changeIndustry,
  reset
} from './actions'
import { organisationToFormValues } from './helpers'

export const INITIAL_STATE = {
  isOpen: false,
  isUpdate: false,
  loading: false,
  success: false,
  id: '',
  errorMsg: '',
  addresses: [],
  categories: [],
  industries: [],
  initialValues: {}
}

export default handleActions(
  {
    [addAddress]: (state, { payload: { address } }) => ({
      ...state,
      addresses: state.addresses.concat(address)
    }),
    [removeAddress]: (state, { payload: { address } }) => ({
      ...state,
      addresses: state.addresses.filter(
        ({ latitude, longitude }) =>
          address.latitude !== latitude || address.longitude !== longitude
      )
    }),
    [addCategory]: (state, { payload: { category } }) => ({
      ...state,
      categories: state.categories.concat(category)
    }),
    [removeCategory]: (state, { payload: { category } }) => ({
      ...state,
      categories: state.categories.filter(({ id }) => category.id !== id)
    }),
    [changeIndustry]: (state, { payload: { industry, isAdded } }) => ({
      ...state,
      industries: isAdded
        ? state.industries.concat(industry)
        : state.industries.filter(item => item !== industry),
      // remove the selected categories which belong to the removed industry
      categories: isAdded
        ? state.categories
        : state.categories.filter(category => category.industry !== industry)
    }),
    [startAdding]: state => ({
      ...state,
      loading: true
    }),
    [finishAdding]: (state, { payload: { error } }) => ({
      ...state,
      loading: false,
      success: error === undefined,
      errorMsg: error
    }),
    [openModal]: (state, { payload: { organisation } }) => {
      const newState = {
        ...state,
        isOpen: true
      }

      if (!organisation) return newState

      const {
        formValues,
        id,
        addresses,
        categories,
        industries
      } = organisationToFormValues(organisation)

      return {
        ...state,
        isOpen: true,
        isUpdate: true,
        initialValues: formValues,
        id,
        addresses,
        categories,
        industries
      }
    },
    [closeModal]: () => ({
      ...INITIAL_STATE
    }),
    [reset]: state => ({
      ...INITIAL_STATE,
      isOpen: state.isOpen,
      isUpdate: state.isUpdate
    })
  },
  INITIAL_STATE
)
