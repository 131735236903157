import React from 'react'
import { arrayOf, shape, func, bool } from 'prop-types'
import Select from 'react-select'

import ControlComponent from './ControlComponent'
import { slugShape } from 'utils/shapes'

const LocationControlComponent = props => (
  <ControlComponent {...props} icon="location-grey" />
)

const LocationSearch = ({
  getOptionLabel,
  getOptionValue,
  onChange,
  options,
  value,
  onNavigationBar,
  noBorder
}) => (
  <Select
    styles={{
      control: provided => ({
        ...provided,
        paddingLeft: '40px',
        borderRadius: '0',
        transition: 'background 0.25s, border-color 0.25s, color 0.25s',
        padding: '0.5em 0.9375em 0.5em 2.5em',
        width: '100%',
        border: 'none',
        borderRight: 'none',
        borderLeft: noBorder ? 'none' : '1px solid #aaa',
        background: `${onNavigationBar ? '#f3f3f3' : '#fff'}`,
        boxSizing: 'border-box',
        '&::placeholder': {
          transition: 'color 0.25s',
          color: '#abacac',
          '-webkit-font-smoothing': 'antialias'
        },
        boxShadow: 'none'
      })
    }}
    getOptionLabel={getOptionLabel}
    getOptionValue={getOptionValue}
    onChange={onChange}
    value={value}
    options={options}
    components={{
      Control: LocationControlComponent,
      DropdownIndicator: null
    }}
  />
)

LocationSearch.propTypes = {
  getOptionLabel: func.isRequired,
  getOptionValue: func.isRequired,
  onChange: func.isRequired,
  onNavigationBar: bool,
  noBorder: bool,
  options: arrayOf(shape(slugShape)).isRequired,
  value: arrayOf(shape(slugShape)).isRequired
}

export default LocationSearch
