import React from 'react'
import { func, object } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Page from 'components/Page/Page'
import Title from 'components/shared/Navigation/Title'
import Wrapper from 'components/shared/Wrapper'
import i18n from 'i18n'
import ResetPasswordForm from 'components/Page/ResetPassword/ResetPasswordForm'
import Success from 'components/Page/ResetPassword/Success'
import { openAuthModal } from 'components/shared/AuthModal/actions'

class ResetPassword extends React.Component {
  state = {
    success: false
  }

  onSuccess = () => this.setState({ success: true })

  render() {
    const { match, openAuthModal } = this.props
    const { success } = this.state
    return (
      <Page>
        <Title title={`${i18n('reset-password').toUpperCase()}`} />
        <Wrapper>
          <Row>
            <Col xs={12} sm={6} smOffset={3}>
              {success ? (
                <Success openAuthModal={openAuthModal} />
              ) : (
                <ResetPasswordForm
                  resetToken={match.params.resetToken}
                  onSuccess={this.onSuccess}
                />
              )}
            </Col>
          </Row>
        </Wrapper>
      </Page>
    )
  }
}

ResetPassword.propTypes = {
  openAuthModal: func.isRequired,
  match: object.isRequired
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openAuthModal
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(ResetPassword)
