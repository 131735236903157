import React from 'react'
import { object, func } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Wrapper from 'components/shared/Wrapper'
import Header from './Header'
import Cell from './Cell'
import Content from './Content'
import LocationIcon from './LocationIcon'
import IndustrySectors from './IndustrySectors'
import Span from 'components/shared/Span'
import i18n from 'i18n'

const StyledSpan = styled(Span)`
  color: #555;
  font-size: 12px;
  line-height: 28px;
  margin-left: 10px;
`
const StyledLink = styled(Link)`
  position: absolute;
  bottom: 10%;
  left: 20%;
  color: ${({ theme }) => theme.colours.primary} !important;
  &:hover {
    text-decoration: none;
  }
`

const OrganisationCell = ({ entity, onHeaderClick }) => (
  <Cell>
    <Header onClick={onHeaderClick}>{entity.name}</Header>
    <Content>
      <Wrapper>
        <Row>
          <Col xs={12}>
            <IndustrySectors
              industries={entity.industries.map(({ name }) => name)}
            />
          </Col>
        </Row>
        {entity.locationAddresses.map(({ value }) => (
          <Row key={value.latitude + value.longitude}>
            <Col xs={12}>
              <LocationIcon />
              <StyledSpan>{`${value.postal.suburb}, ${
                value.postal.state
              }`}</StyledSpan>
            </Col>
          </Row>
        ))}
        {entity.phoneAddresses.length ? (
          <Row>
            <Col xs={12} style={{ paddingLeft: '23px' }}>
              <StyledSpan>{entity.phoneAddresses[0].value}</StyledSpan>
            </Col>
          </Row>
        ) : null}
        {entity.websiteAddresses.length ? (
          <Row>
            <Col xs={12} style={{ paddingLeft: '23px' }}>
              <a
                href={`${
                  entity.websiteAddresses[0].value.startsWith('http')
                    ? ''
                    : 'https://'
                }${entity.websiteAddresses[0].value}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledSpan>{entity.websiteAddresses[0].value}</StyledSpan>
              </a>
            </Col>
          </Row>
        ) : null}
      </Wrapper>
      <StyledLink to={`/organisation/${entity.id}`}>
        {i18n('see-business-profile')}
      </StyledLink>
    </Content>
  </Cell>
)

OrganisationCell.propTypes = {
  onHeaderClick: func.isRequired,
  entity: object.isRequired
}

export default OrganisationCell
