import styled from 'styled-components'

import media from 'utils/media'

export default styled.h2`
  color: #555;
  font-size: 30px;
  line-height: 35px;
  ${media.tablet`
    font-size: 20px;
    line-height: 25px;
  `};
`
