import React from 'react'
import { object, bool, func } from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { Grid, Row, Col } from 'react-bootstrap'

import Clickable from 'components/shared/Clickable'
import Span from 'components/shared/Span'
import i18n from 'i18n'

const StyledGrid = styled(Grid)`
  color: white;
  background-color: ${({ theme }) => theme.colours.footer};
  width: 100%;
`

const Wrapper = styled.div`
  padding: 20px 0;
`

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 19px;
  &:hover {
    text-decoration: none;
  }
`

const StyledSpan = styled(Span)`
  font-size: 11px
  line-height: 17px;
`

const Anchor = StyledLink.withComponent('a')

const Footer = ({ authenticated, theme, openAuthModal }) => (
  <StyledGrid fluid>
    <Row>
      <Col xs={12}>
        <Wrapper style={{ borderBottom: 'solid 1px white' }}>
          <StyledSpan xs={true} color={theme.colours.primary}>{`${i18n(
            'disclaimer'
          )}: `}</StyledSpan>
          <StyledSpan xs={true}>{i18n('disclaimer-content')}</StyledSpan>
        </Wrapper>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Wrapper>
          <Anchor href="https://fial.com.au/privacy-policy" target="blank">
            <Span>{`${i18n('privacy')} | `}</Span>
          </Anchor>
          <Anchor href="https://fial.com.au/tandcs" target="blank">
            <Span>{`${i18n('terms')} | `}</Span>
          </Anchor>
          <StyledLink to="/contact">
            <Span>{`${i18n('contact')} | `}</Span>
          </StyledLink>
          <StyledLink to="/about">
            <Span>{`${i18n('about')}`}</Span>
          </StyledLink>
          {!authenticated ? (
            <Clickable onClick={openAuthModal}>
              <Span>{` | ${i18n('admin-login')}`}</Span>
            </Clickable>
          ) : null}
        </Wrapper>
      </Col>
    </Row>
  </StyledGrid>
)

Footer.propTypes = {
  authenticated: bool.isRequired,
  theme: object.isRequired,
  openAuthModal: func.isRequired
}

export default withTheme(Footer)
