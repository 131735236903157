import React from 'react'
import { bool, func } from 'prop-types'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Home from 'pages/Home'
import About from 'pages/About'
import Contact from 'pages/Contact'
import NotFound from 'pages/NotFound'
import ResetPassword from 'pages/ResetPassword'
import Search from 'pages/Search'
import OrganisationContainer from 'pages/OrganisationContainer'
import Admin from 'pages/Admin'
import Loading from 'components/shared/Loading'
import userIsPublic from 'utils/userIsPublic'
import userIsAdmin from 'utils/userIsAdmin'
import { loadPredefinedData } from 'redux/data/actions'

class App extends React.Component {
  componentDidMount() {
    this.props.loadPredefinedData()
  }

  render() {
    if (!this.props.loaded) {
      return <Loading />
    }

    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={userIsPublic(About)} />
        <Route exact path="/contact" component={userIsPublic(Contact)} />
        <Route exact path="/search" component={Search} />
        <Route
          exact
          path="/organisation/:organisationId"
          component={OrganisationContainer}
        />
        <Route
          exact
          path="/reset-password/:resetToken"
          component={ResetPassword}
        />
        <Route exact path="/admin" component={userIsAdmin(Admin)} />
        <Route
          exact
          path="/admin/new-organisation"
          component={userIsAdmin(Admin)}
        />
        <Route exact path="/directory" component={userIsAdmin(Search)} />
        <Route component={NotFound} />
      </Switch>
    )
  }
}

App.propTypes = {
  loadPredefinedData: func.isRequired,
  loaded: bool.isRequired
}

const mapStateToProps = ({ data: { loaded } }) => ({
  loaded
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadPredefinedData
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
)
