import React from 'react'
import { string } from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import Paragraph from 'components/shared/Paragraph'
import i18n from 'i18n'

const OrganisationNotFound = ({ id }) => (
  <Row>
    <Col xs={12} className="text-center">
      <h1>{i18n('organisation-not-found-header')}</h1>
      <Paragraph error>
        {i18n('organisation-not-found-description')(id)}
      </Paragraph>
    </Col>
  </Row>
)

OrganisationNotFound.propTypes = {
  id: string.isRequired
}

export default OrganisationNotFound
