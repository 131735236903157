import React from 'react'

import SuccessScreen from 'components/shared/SuccessScreen'
import i18n from 'i18n'

const EmailSent = () => (
  <SuccessScreen
    heading={i18n('forgot-password-email-sent')}
    description={i18n('forgot-password-email-sent-description')}
  />
)

export default EmailSent
