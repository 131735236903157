import React from 'react'
import PropTypes from 'prop-types'
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap'
import { NavItem } from 'react-bootstrap'
import i18n from 'i18n'

const NavigationItem = ({ eventKey, path, slug }) =>
  path === '/' ? (
    <IndexLinkContainer key={path} to={path}>
      <NavItem eventKey={eventKey}>{i18n(slug)}</NavItem>
    </IndexLinkContainer>
  ) : (
    <LinkContainer key={path} to={path}>
      <NavItem eventKey={eventKey}>{i18n(slug).toUpperCase()}</NavItem>
    </LinkContainer>
  )

NavigationItem.propTypes = {
  eventKey: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired
}

export default NavigationItem
