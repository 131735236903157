import styled from 'styled-components'
import media from 'utils/media'

export default styled.div`
  margin-bottom: ${({ small, medium }) =>
    small ? '25px' : medium ? '35px' : '50px'};
  padding: 0 ${({ padding }) => (padding ? '50px' : '0')};
  ${media.tablet`
    margin-bottom: ${({ small, medium }) =>
      small ? '15px' : medium ? '20px' : '30px'};
    padding: 0 ${({ padding }) => (padding ? '30px' : '0')};
  `};
`
