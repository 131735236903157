import styled from 'styled-components'

import Span from 'components/shared/Span'

export default styled(Span)`
  color: #555;
  margin-left: 10px;
  font-size: 14px;
  line-height: 28px;
`
