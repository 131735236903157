import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer } from 'react-toastify'

import theme from 'theme'
import App from 'App'
import * as serviceWorker from 'serviceWorker'
import store, { history } from 'store'
import { NOTIFICATION_TIMEOUT } from 'utils/constants'

import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss'
import 'font-awesome/css/font-awesome.min.css'
import './index.scss'

const target = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <div style={{ height: '100%' }}>
          <App />
          <ToastContainer autoClose={NOTIFICATION_TIMEOUT} />
        </div>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  target
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
