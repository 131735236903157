// TODO: enhance this for other languages

import common from './en/common'
import auth from './en/auth'
import navigation from './en/navigation'
import home from './en/home'
import newBusiness from './en/newBusiness'
import notfound from './en/notfound'
import about from './en/about'
import contact from './en/contact'
import search from './en/search'
import admin from './en/admin'
import organisation from './en/organisation'

const en = {
  ...common,
  ...auth,
  ...navigation,
  ...home,
  ...newBusiness,
  ...notfound,
  ...about,
  ...contact,
  ...search,
  ...admin,
  ...organisation
}

const all = { en }

const i18n = (key, locale = 'en') =>
  all[locale][key] !== undefined ? all[locale][key] : key

export default i18n
