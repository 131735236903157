import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import styled, { withTheme } from 'styled-components'
import { Image } from 'react-bootstrap'

import Input from 'components/shared/Input'

const StyledImage = styled(Image)`
  width: 18px;
`

const renderSuggestion = suggestion => (
  <div>
    <StyledImage src="/icons/add.svg" /> {suggestion.name}
  </div>
)

class AutoCompleteInput extends React.Component {
  state = {
    value: '',
    suggestions: []
  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.props.getSuggestions(value)
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  // handle Enter key in the search input
  // Note: onSuggestionSelected is called first if a suggestion is selected.
  // In the case, value is empty and handleKeyPress does nothing. Otherwise,
  // the input value is notified to the parent component.
  handleKeyPress = ({ key }) => {
    const { value } = this.state

    if (key === 'Enter' && value) {
      this.props.onEnter(value)
      this.setState({
        value: '',
        suggestions: []
      })
    }
  }

  // handle the changes of search input
  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    })
  }

  // handle Enter/Click/Tap event on the suggestion list
  onSuggestionSelected = (event, { suggestion, method }) => {
    this.props.onSuggestionSelected(suggestion)
    this.setState({
      value: '',
      suggestions: []
    })
  }

  render() {
    const { value, suggestions } = this.state
    const {
      placeholder,
      disabled,
      theme,
      getSuggestionValue,
      multiSection,
      renderSectionTitle,
      getSectionSuggestions
    } = this.props

    const inputProps = {
      placeholder,
      value,
      disabled,
      onKeyPress: this.handleKeyPress,
      onChange: this.onChange
    }

    return (
      <Autosuggest
        suggestions={suggestions}
        shouldRenderSuggestions={value => true}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={this.onSuggestionSelected}
        renderSuggestion={this.props.renderSuggestion || renderSuggestion}
        multiSection={multiSection}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        focusInputOnSuggestionClick={false}
        inputProps={inputProps}
        renderInputComponent={inputProps => <Input {...inputProps} />}
        theme={{
          container: {
            height: '50px'
          },
          suggestionsContainer: {
            position: 'relative',
            maxHeight: '300px',
            overflow: 'auto',
            backgroundColor: 'white',
            zIndex: 999,
            boxShadow:
              '0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 5px 0 rgba(0, 0, 0, 0.25)'
          },
          suggestionsList: {
            listStyle: 'none'
          },
          suggestion: {
            padding: '5px',
            color: '#555',
            fontSize: '18px'
          },
          suggestionHighlighted: {
            color: theme.colours.primary,
            fontWeight: 'bold'
          }
        }}
      />
    )
  }
}

AutoCompleteInput.propTypes = {
  getSuggestionValue: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  multiSection: PropTypes.bool,
  renderSectionTitle: PropTypes.func,
  getSectionSuggestions: PropTypes.func,
  onEnter: PropTypes.func,
  theme: PropTypes.object.isRequired
}

AutoCompleteInput.defaultProps = {
  onEnter: () => {}
}

export default withTheme(AutoCompleteInput)
