import { handleActions } from 'redux-actions'

import {
  openAuthModal,
  closeAuthModal,
  start,
  logInSuccess,
  logOutSuccess,
  failure
} from './actions'

export const INITIAL_STATE = {
  isAuthModalOpen: false,
  starting: false,
  authenticated: false,
  user: null,
  token: null,
  error: null
}

export default handleActions(
  {
    [start]: state => ({ ...state, starting: true }),
    [logInSuccess]: (state, { payload: { user, token } }) => ({
      ...INITIAL_STATE,
      authenticated: true,
      user,
      token
    }),
    [logOutSuccess]: () => INITIAL_STATE,
    [failure]: (state, { payload: { error } }) => ({
      ...INITIAL_STATE,
      isAuthModalOpen: state.isAuthModalOpen,
      error
    }),
    [openAuthModal]: state => ({ ...state, isAuthModalOpen: true }),
    [closeAuthModal]: state => ({ ...state, isAuthModalOpen: false })
  },
  INITIAL_STATE
)
