import React from 'react'
import { oneOfType, bool, string, object, func } from 'prop-types'
import styled from 'styled-components'

import media from 'utils/media'

const StyledLabel = styled.label`
  line-height: 25px;
  font-weight: ${({ bold }) => (bold ? 500 : 100)};
  font-size: ${({ small }) => (small ? '14px' : '16px')};
  color: ${({ color }) => color || '#555'};
  ${media.tablet`
    font-size: ${({ small }) => (small ? '12px' : '14px')};
  `};
`

const Checkbox = ({
  className,
  bold,
  small,
  checked,
  disabled,
  onChange,
  name,
  label
}) => (
  <div className={`checkbox ${className || ''}`}>
    <input
      type="checkbox"
      id={name}
      checked={checked}
      disabled={disabled}
      onChange={evt => {
        if (!disabled) onChange(evt.target.checked)
      }}
    />
    <StyledLabel small={small} bold={bold} htmlFor={name}>
      {label}
    </StyledLabel>
  </div>
)

Checkbox.propTypes = {
  className: string,
  checked: bool.isRequired,
  disabled: bool,
  small: bool,
  bold: bool,
  name: string.isRequired,
  label: oneOfType([string, object]).isRequired,
  onChange: func.isRequired
}

export default Checkbox
