import React from 'react'
import { components } from 'react-select'
import styled from 'styled-components'
import { Image } from 'react-bootstrap'

const StyledImage = styled(Image)`
  position: absolute;
  left: 0.5em;
  bottom: 25%;
  padding-left: 0.5em;
  height: 1.3em;
`

const Wrapper = styled.div`
  position: relative;
`

const ControlComponent = ({ icon, ...props }) => (
  <Wrapper>
    <components.Control {...props} />
    <StyledImage src={`/icons/${icon}.svg`} />
  </Wrapper>
)

export default ControlComponent
