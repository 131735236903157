import React from 'react'
import { string, arrayOf, shape, func } from 'prop-types'
import { Row, Col, Collapse, Image } from 'react-bootstrap'
import styled from 'styled-components'

import Span from 'components/shared/Span'
import Clickable from 'components/shared/Clickable'
import FilterItem from './FilterItem'
import media from 'utils/media'
import { slugShape } from 'utils/shapes'

const Wrapper = styled.div`
  padding: 0 10px;
  ${media.tablet`
    padding: 0 5px;
  `};
`

const StyledSpan = styled(Span)`
  color: #555;
  font-weight: 500;
  line-height: 25px;
  font-size: 16px;
  margin-right: 15px;
  ${media.tablet`
    font-size: 14px;
    margin-right: 10px;
  `};
`

class FilterSection extends React.Component {
  state = {
    isOpen: true
  }

  toggleSection = () => this.setState({ isOpen: !this.state.isOpen })

  render() {
    const { label, filters, checked, onChange } = this.props
    const { isOpen } = this.state

    return (
      <Row>
        <Col xs={12}>
          <Wrapper>
            <StyledSpan>{label}</StyledSpan>
            <Clickable onClick={this.toggleSection}>
              <Image src={`/icons/${isOpen ? 'down' : 'right'}.svg`} />
            </Clickable>
            <Collapse in={isOpen}>
              <div>
                {filters.map(filter => (
                  <FilterItem
                    key={filter.id}
                    onChange={checked => onChange(filter, checked)}
                    checked={!!checked.find(({ id }) => filter.id === id)}
                    name={filter.name}
                    caption={filter.caption}
                    count={filter.count}
                  />
                ))}
              </div>
            </Collapse>
          </Wrapper>
        </Col>
      </Row>
    )
  }
}

FilterSection.propTypes = {
  label: string.isRequired,
  onChange: func.isRequired,
  filters: arrayOf(shape(slugShape)).isRequired,
  checked: arrayOf(shape(slugShape)).isRequired
}

export default FilterSection
