import React from 'react'
import { string, func, object, bool } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import Button from 'components/shared/Button'
import Wrapper from 'components/shared/Wrapper'
import Loading from 'components/shared/Loading'
import SuccessScreen from 'components/shared/SuccessScreen'
import Heading from 'components/shared/Heading'
import Description from 'components/shared/Description'
import i18n from 'i18n'

const FinalStep = ({
  history,
  loading,
  error,
  isUpdate,
  authenticated,
  startOver,
  closeModal
}) => (
  <Row>
    <Col xs={10} xsOffset={1} sm={6} smOffset={3}>
      <Wrapper className="text-center">
        {loading && <Loading />}
        {error && (
          <div>
            <Heading>{i18n('new-business-error')}</Heading>
            <Description>{error}</Description>
          </div>
        )}
        {!loading && !error && (
          <SuccessScreen
            heading={i18n(
              `${
                isUpdate ? 'update' : `${authenticated ? 'admin-' : ''}new`
              }-business-success`
            )}
            description={i18n(
              `${
                isUpdate ? 'update' : `${authenticated ? 'admin-' : ''}new`
              }-business-success-description`
            )}
          >
            <Row>
              <Col xs={6} xsOffset={3}>
                <Wrapper>
                  <Button
                    onClick={() => {
                      if (!isUpdate) {
                        history.push(`${authenticated ? '/admin' : '/'}`)
                      }
                      closeModal()
                    }}
                  >
                    {i18n(
                      isUpdate
                        ? 'return-to-organisation'
                        : authenticated
                        ? 'return-to-admin'
                        : 'explore'
                    )}
                  </Button>
                </Wrapper>
              </Col>
            </Row>
            {authenticated && !isUpdate && (
              <Wrapper>
                <Row>
                  <Col xs={6} xsOffset={3}>
                    <Button secondary="true" onClick={startOver}>
                      {i18n('add-another-organisation')}
                    </Button>
                  </Col>
                </Row>
              </Wrapper>
            )}
          </SuccessScreen>
        )}
      </Wrapper>
    </Col>
  </Row>
)

FinalStep.propTypes = {
  error: string,
  authenticated: bool,
  startOver: func.isRequired,
  closeModal: func.isRequired,
  history: object.isRequired,
  loading: bool.isRequired,
  isUpdate: bool.isRequired
}

export default withRouter(FinalStep)
