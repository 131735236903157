import React from 'react'
import { string, bool, arrayOf, shape, array, func, object } from 'prop-types'
import { Grid, Row, Col, ProgressBar, Image } from 'react-bootstrap'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getFormValues, reduxForm } from 'redux-form'

import Title from 'components/shared/Navigation/Title'
import AboutBusiness from './AboutBusiness'
import BusinessLocation from './BusinessLocation'
import BusinessCapabilities from './BusinessCapabilities'
import PointOfContact from './PointOfContact'
import FinalStep from './FinalStep'
import {
  addAddress,
  removeAddress,
  addCategory,
  removeCategory,
  changeIndustry,
  addNewBusiness
} from './actions'
import media from 'utils/media'
import { slugShape } from 'utils/shapes'
import { formValuesToOrganisation } from './helpers'
import { NEW_BUSINESS_FORM } from 'utils/constants'
import i18n from 'i18n'

const ABOUT_BUSINESS = 1
const BUSINESS_LOCATIONS = 2
const BUSINESS_CAPABILITIES = 3
const POINT_OF_CONTACT = 4
const FINAL_STEP = 5

const StyledProgressBar = styled(ProgressBar)`
  height: 30px;
  ${media.desktop`
    height: 20px;
  `};
  ${media.tablet`
    height: 10px;
    margin-top: 5px;
  `};
`

const StyledImage = styled(Image)`
  width: 20px;
  cursor: pointer;
  ${media.desktop`
    width: 10px;
  `};
`

const DIV = styled.div`
  text-align: top;
  font-size: 20px;
  cursor: pointer;
  ${media.desktop`
    font-size: 15px;
  `};
`

const Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  ${media.tablet`padding-left: 10px;padding-right: 10px;`};
`

const ContentWrapper = styled.div`
  margin: 50px 0;
  ${media.desktop`
    margin: 35px 0;
  `};
  ${media.tablet`
    margin: 25px 0;
  `};
`

class NewBusinessModal extends React.Component {
  state = {
    step: 1
  }

  nextStep = () => {
    const currentStep = this.state.step

    let nextStep = currentStep + 1

    if (nextStep === FINAL_STEP) {
      this.addBusiness()
    }

    this.setState({
      step: nextStep
    })
  }

  previousStep = () => {
    const currentStep = this.state.step
    let previousStep = currentStep - 1

    this.setState({
      step: previousStep
    })
  }

  addBusiness = () => {
    const {
      formValues,
      id,
      addresses,
      selectedCategories,
      selectedIndustries
    } = this.props

    this.props.addNewBusiness(
      formValuesToOrganisation({
        ...formValues,
        id,
        addresses,
        categories: selectedCategories,
        industries: selectedIndustries
      })
    )
  }

  startOver = () => {
    this.setState({
      step: 1
    })
  }

  render() {
    const { step } = this.state
    const {
      selectedIndustries,
      errorMsg,
      loading,
      isUpdate,
      authenticated,
      sizes,
      industries,
      categories,
      addresses,
      closeModal,
      selectedCategories,
      addAddress,
      removeAddress,
      addCategory,
      removeCategory,
      changeIndustry,
      isAdmin
    } = this.props

    return (
      <Wrapper>
        <Title title={i18n('new-business')} />
        <Grid fluid>
          <Row>
            <Col xs={1}>
              {step > 1 && (step < FINAL_STEP || errorMsg) && (
                <DIV className="text-center" onClick={this.previousStep}>
                  <StyledImage src="/icons/back.svg" />
                </DIV>
              )}
            </Col>
            <Col xs={10}>
              <StyledProgressBar
                style={{ verticalAlign: 'middle' }}
                bsStyle="success"
                now={(step / FINAL_STEP) * 100}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ContentWrapper>
                {step === ABOUT_BUSINESS && (
                  <AboutBusiness
                    onSubmit={this.nextStep}
                    sizes={sizes}
                    isAdmin={isAdmin}
                  />
                )}
                {step === BUSINESS_LOCATIONS && (
                  <BusinessLocation
                    onSubmit={this.nextStep}
                    addresses={addresses}
                    addAddress={addAddress}
                    removeAddress={removeAddress}
                  />
                )}
                {step === BUSINESS_CAPABILITIES && (
                  <BusinessCapabilities
                    onSubmit={this.nextStep}
                    industries={industries}
                    categories={categories}
                    selectedIndustries={selectedIndustries}
                    selectedCategories={selectedCategories}
                    addCategory={addCategory}
                    removeCategory={removeCategory}
                    changeIndustry={changeIndustry}
                  />
                )}
                {step === POINT_OF_CONTACT && (
                  <PointOfContact onSubmit={this.nextStep} isAdmin={isAdmin} />
                )}
                {step === FINAL_STEP && (
                  <FinalStep
                    loading={loading}
                    isUpdate={isUpdate}
                    error={errorMsg}
                    closeModal={closeModal}
                    authenticated={authenticated}
                    startOver={this.startOver}
                  />
                )}
              </ContentWrapper>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
    )
  }
}

NewBusinessModal.propTypes = {
  errorMsg: string,
  loading: bool.isRequired,
  authenticated: bool,
  sizes: arrayOf(shape(slugShape)).isRequired,
  industries: arrayOf(shape(slugShape)).isRequired,
  categories: arrayOf(shape(slugShape)).isRequired,
  closeModal: func.isRequired,
  addNewBusiness: func.isRequired,
  addAddress: func.isRequired,
  removeAddress: func.isRequired,
  addCategory: func.isRequired,
  removeCategory: func.isRequired,
  changeIndustry: func.isRequired,
  formValues: object,
  addresses: array.isRequired,
  id: string,
  selectedCategories: arrayOf(shape(slugShape)).isRequired,
  selectedIndustries: arrayOf(string).isRequired,
  initialValues: object,
  isUpdate: bool.isRequired,
  isAdmin: bool.isRequired
}

const mapStateToProps = ({
  auth: { user },
  data: { sizes, industries, categories },
  business: {
    id,
    addresses,
    categories: selectedCategories,
    industries: selectedIndustries,
    initialValues,
    loading,
    errorMsg,
    isUpdate
  },
  ...state
}) => ({
  errorMsg,
  loading,
  sizes: sizes.map(size => ({
    ...size,
    name: `${size.name} (${size.caption})`
  })),
  initialValues,
  id,
  industries,
  categories,
  addresses,
  selectedCategories,
  selectedIndustries,
  isUpdate,
  isAdmin: !!user,
  formValues: getFormValues(NEW_BUSINESS_FORM)(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addNewBusiness,
      addAddress,
      removeAddress,
      addCategory,
      removeCategory,
      changeIndustry
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: NEW_BUSINESS_FORM
  })(NewBusinessModal)
)
