import React from 'react'
import { object, string, bool, oneOfType } from 'prop-types'

import Checkbox from 'components/shared/Checkbox'
import Paragraph from 'components/shared/Paragraph'
import Wrapper from 'components/shared/Wrapper'

const FormCheckbox = ({ input, label, meta: { touched, error }, disabled }) => (
  <Wrapper small>
    <Checkbox
      checked={input.checked}
      onChange={input.onChange}
      name={input.name}
      label={label}
      disabled={disabled}
    />
    {touched && error && <Paragraph error={!!error}>{error}</Paragraph>}
  </Wrapper>
)

FormCheckbox.propTypes = {
  meta: object.isRequired,
  input: object.isRequired,
  disabled: bool,
  label: oneOfType([string, object]).isRequired
}

export default FormCheckbox
