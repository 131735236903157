import React from 'react'
import { string, bool, number, func } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import Span from 'components/shared/Span'
import Checkbox from 'components/shared/Checkbox'
import media from 'utils/media'

const StyledCheckbox = styled(Checkbox)`
  display: inline-block !important;
`

const Count = styled(Span)`
  color: #555;
  line-height: 25px;
  font-size: 14px;
  ${media.tablet`
    font-size: 12px;
  `};
`

const Caption = styled(Span)`
  font-size: 10px;
  color: #555;
  line-height: 25px;
  ${media.tablet`
    font-size: 8px;
  `};
`

const FilterItem = ({ checked, onChange, name, caption, count }) => (
  <Row>
    <Col xs={12}>
      <StyledCheckbox
        small={true}
        name={name}
        label={name}
        checked={checked}
        onChange={onChange}
      />
      {caption && <Caption>{` (${caption})`}</Caption>}
      <Count>{` (${count})`}</Count>
    </Col>
  </Row>
)

FilterItem.propTypes = {
  name: string.isRequired,
  caption: string,
  checked: bool.isRequired,
  onChange: func.isRequired,
  count: number.isRequired
}

export default FilterItem
