import styled from 'styled-components'

import media from 'utils/media'

export default styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 300px;
  margin: 15px 10px 15px 0;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 2px;
  &:last-of-type {
    margin-right: 0;
  }
  ${media.tablet`
    margin: 15px 0px;
    min-height: 200px;
  `};
`
