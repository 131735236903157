import React from 'react'
import { Image } from 'react-bootstrap'

export const renderHeader = (text, isSortDesc = {}) => (
  <div>
    {text}
    {isSortDesc[text] && <Image src="/icons/triangle_down.png" />}
    {isSortDesc[text] !== undefined && !isSortDesc[text] && (
      <Image src="/icons/triangle_up.png" />
    )}
  </div>
)

export const headerStyle = {
  width: '100%',
  padding: '20px 0 10px 20px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  backgroundColor: '#FBFBFB',
  fontStyle: 'italic',
  textAlign: 'left',
  boxShadow: 'none'
}

export const cellStyle = {
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  padding: '10px 5px'
}

const textToId = text =>
  text
    ? text
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '-')
        .replace(/[-]+/g, '-')
        .replace(/(^-|-$)/g, '')
    : ''

export const parseEntities = ({
  csvContent,
  idField,
  allFields,
  arrayFields,
  lowerCaseFields,
  numericFields
}) => {
  const headers = {}
  const entities = {}

  // the CSV file must have at least 2 rows (one for headers and the other for content)
  if (csvContent.length < 2) {
    return false
  }

  csvContent
    .shift()
    .forEach(
      (item, index) => (headers[item.replace(/\s/g, '').toLowerCase()] = index)
    )

  // error if any required field is missing
  if (allFields.some(field => headers[field.toLowerCase()] === undefined)) {
    return false
  }

  // collect and clean
  csvContent.forEach(data => {
    const id = textToId(data[headers[idField]])

    if (!id) return

    const entity = entities[id] || {}

    entities[id] = allFields.reduce((acc, field) => {
      let value = data[headers[field.toLowerCase()]]
      if (arrayFields.indexOf(field) > -1) {
        acc[field] = acc[field] || []
      }

      if (value) {
        if (lowerCaseFields.indexOf(field) > -1) {
          // covert normal text to ID text (i.e. trim and replace space by -)
          value = textToId(value)
        } else if (numericFields.indexOf(field) > -1) {
          value = parseInt(value)
        }

        if (arrayFields.indexOf(field) > -1) {
          // add 'value' only if it has not been added before
          if (acc[field].indexOf(value) === -1) {
            acc[field].push(value)
          }
        } else if (value) {
          acc[field] = value
        }
      }
      return acc
    }, entity)
  })

  return Object.values(entities)
}
