import styled from 'styled-components'
import media from 'utils/media'

const Input = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 10px 10px 5px 5px;
  border: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
  border-bottom: 1px solid ${({ error, theme }) =>
    error ? theme.colours.error : theme.colours.secondary}
  ${media.tablet`
    font-size: 12px;
  `};
}`

export default Input
