import React from 'react'
import { array, string, object, oneOfType } from 'prop-types'

import SuccessIcon from './SuccessIcon'
import Heading from 'components/shared/Heading'
import Description from 'components/shared/Description'
import Wrapper from 'components/shared/Wrapper'

const SuccessScreen = ({ heading, description, children }) => (
  <Wrapper className="text-center">
    <Wrapper>
      <Wrapper>
        <SuccessIcon />
      </Wrapper>
      <Heading>{heading}</Heading>
      {description && <Description>{description}</Description>}
    </Wrapper>
    {children || null}
  </Wrapper>
)

SuccessScreen.propTypes = {
  heading: string.isRequired,
  description: string,
  children: oneOfType([object, array])
}

export default SuccessScreen
