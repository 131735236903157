import React from 'react'
import { object, func } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import Span from 'components/shared/Span'
import Wrapper from 'components/shared/Wrapper'
import Input from './Input'
import { ENTITY_FIELDS } from 'utils/constants'

const StyledSpan = styled(Span)`
  color: #000;
`

const stringToArray = str => str.split(',')

const {
  ABN,
  SIZE,
  YEAR,
  DESCRIPTION,
  ADDRESSES,
  INDUSTRIES,
  CATEGORIES,
  POC_FIRSTNAME,
  POC_LASTNAME,
  POC_EMAIL
} = ENTITY_FIELDS

const BusinessEditor = ({ entity, onChange }) => {
  return (
    <div
      style={{
        paddingLeft: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.125) 0px 3px 5px'
      }}
    >
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={4}>
              <h3>Locations</h3>
              <Input
                value={entity[ADDRESSES]}
                onChange={({ target: { value } }) =>
                  onChange(ADDRESSES, stringToArray(value))
                }
              />
            </Col>
            <Col xs={4} xsOffset={1}>
              <h3>ABN</h3>
              <Input
                value={entity[ABN]}
                onChange={({ target: { value } }) => onChange(ABN, value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <h3>Organisation Size</h3>
              <Input
                value={entity[SIZE]}
                onChange={({ target: { value } }) => onChange(SIZE, value)}
              />
            </Col>
            <Col xs={4} xsOffset={1}>
              <h3>Year</h3>
              <Input
                value={entity[YEAR]}
                onChange={({ target: { value } }) => onChange(YEAR, value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <h3>Industries</h3>
              <Input
                value={entity[INDUSTRIES]}
                onChange={({ target: { value } }) =>
                  onChange(INDUSTRIES, stringToArray(value))
                }
              />
            </Col>
            <Col xs={4} xsOffset={1}>
              <h3>Categories</h3>
              <Input
                value={entity[CATEGORIES]}
                onChange={({ target: { value } }) =>
                  onChange(CATEGORIES, stringToArray(value))
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <h3>Description</h3>
              <Input
                value={entity[DESCRIPTION]}
                onChange={({ target: { value } }) =>
                  onChange(DESCRIPTION, value)
                }
              />
            </Col>
            <Col xs={4} xsOffset={1}>
              <h3>Point of contact</h3>
              <Wrapper small>
                <Row>
                  <Col xs={4}>
                    <StyledSpan small>First Name</StyledSpan>
                  </Col>
                  <Col xs={8}>
                    <Input
                      value={entity[POC_FIRSTNAME]}
                      onChange={({ target: { value } }) =>
                        onChange(POC_FIRSTNAME, value)
                      }
                    />
                  </Col>
                </Row>
              </Wrapper>
              <Wrapper small>
                <Row>
                  <Col xs={4}>
                    <StyledSpan small>Last Name</StyledSpan>
                  </Col>
                  <Col xs={8}>
                    <Input
                      value={entity[POC_LASTNAME]}
                      onChange={({ target: { value } }) =>
                        onChange(POC_LASTNAME, value)
                      }
                    />
                  </Col>
                </Row>
              </Wrapper>
              <Wrapper small>
                <Row>
                  <Col xs={4}>
                    <StyledSpan small>Email</StyledSpan>
                  </Col>
                  <Col xs={8}>
                    <Input
                      value={entity[POC_EMAIL]}
                      onChange={({ target: { value } }) =>
                        onChange(POC_EMAIL, value)
                      }
                    />
                  </Col>
                </Row>
              </Wrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

BusinessEditor.propTypes = {
  onChange: func.isRequired,
  entity: object.isRequired
}

export default BusinessEditor
