import React from 'react'
import { string } from 'prop-types'
import { Image } from 'react-bootstrap'
import styled from 'styled-components'

import media from 'utils/media'

const Logo = styled(Image)`
  height: 50px;
  margin-right: 30px;
  ${media.desktop`
    height: 30px;
    margin-right: 15px;
  `};
  ${media.tablet`
    height: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
  `};
`

const AffiliateLogo = ({ logo, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="text-center"
  >
    <Logo src={`/images/logos/${logo}.png`} />
  </a>
)

AffiliateLogo.propTypes = {
  url: string.isRequired,
  logo: string.isRequired
}

export default AffiliateLogo
