const errorToMessage = error => {
  if (error.response && error.response.data) {
    return error.response.data.error
  } else if (error.message) {
    return error.message
  }

  return 'Unknown error'
}

export default errorToMessage
