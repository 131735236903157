import React from 'react'
import { oneOfType, object, array, func, bool } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import ReactResizeDetector from 'react-resize-detector'
import { Grid } from 'react-bootstrap'

import media from 'utils/media'
import {
  openAuthModal,
  closeAuthModal
} from 'components/shared/AuthModal/actions'
import { changeScreenSize } from 'redux/data/actions'
import Navigation from 'components/shared/Navigation'
import Footer from 'components/shared/Footer'
import Modal from 'components/shared/Modal'
import AuthModal from 'components/shared/AuthModal'

const StyledGrid = styled(Grid)`
  min-height: 100%;
  padding-bottom: 160px;
  margin-bottom: -160px;
  ${media.tablet`
    padding-bottom: 330px;
    margin-bottom: -230px;
  `};
`

const Page = ({
  children,
  isAuthModalOpen,
  openAuthModal,
  closeAuthModal,
  changeScreenSize,
  authenticated
}) => (
  <div style={{ height: '100%' }}>
    <StyledGrid fluid>
      <Navigation />
      {children}
    </StyledGrid>
    <Footer openAuthModal={openAuthModal} authenticated={authenticated} />
    <Modal
      isOpen={isAuthModalOpen}
      contentLabel="Auth"
      onRequestClose={closeAuthModal}
      customStyle={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)'
        }
      }}
      width="400px"
    >
      <AuthModal />
    </Modal>
    <ReactResizeDetector handleWidth handleHeight onResize={changeScreenSize} />
  </div>
)

Page.propTypes = {
  children: oneOfType([object, array]).isRequired,
  isAuthModalOpen: bool.isRequired,
  authenticated: bool.isRequired,
  openAuthModal: func.isRequired,
  closeAuthModal: func.isRequired,
  changeScreenSize: func.isRequired
}

const mapStateToProps = ({ auth: { authenticated, isAuthModalOpen } }) => ({
  isAuthModalOpen,
  authenticated
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openAuthModal,
      closeAuthModal,
      changeScreenSize
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page)
