import { ENTITY_FIELDS } from 'utils/constants'

const {
  INDUSTRIES,
  CATEGORIES,
  ADDRESSES,
  NAME,
  WEBSITE,
  PHONE,
  EMAIL
} = ENTITY_FIELDS

const validateEntities = ({ entities, industries, categories }) => {
  let errors = []
  for (let index = 0; index < entities.length; index++) {
    const entity = entities[index]

    if (entity[CATEGORIES].length === 0) {
      errors.push(`Organisation ${entity.name || index} has no category`)
    }

    for (let j = 0; j < entity[CATEGORIES].length; j++) {
      if (categories.indexOf(entity[CATEGORIES][j]) === -1) {
        errors.push(
          `Organisation ${entity.name || index} has an invalid category ${
            entity[CATEGORIES][j]
          }`
        )
      }
    }

    if (entity[INDUSTRIES].length === 0) {
      errors.push(`Organisation ${entity.name || index} has no industry sector`)
    }

    for (let j = 0; j < entity[INDUSTRIES].length; j++) {
      if (industries.indexOf(entity[INDUSTRIES][j]) === -1) {
        errors.push(
          `Organisation ${entity.name ||
            index} has an invalid industry sector ${entity[INDUSTRIES][j]}`
        )
      }
    }

    if (entity[ADDRESSES].length === 0) {
      errors.push(`Organisation ${entity.name || index} has no address`)
    }

    if (!entity[NAME]) {
      errors.push(`Organisation ${index} has no name`)
    }
    if (!entity[WEBSITE]) {
      errors.push(`Organisation ${entity.name || index} has no website`)
    }
    if (!entity[PHONE] && !entity[EMAIL]) {
      errors.push(
        `Organisation ${entity.name || index} has neither phone or email`
      )
    }
  }
  return errors.length ? errors : null
}

export default validateEntities
