import React from 'react'
import { func, object, number } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import Button from 'components/shared/Button'
import Wrapper from 'components/shared/Wrapper'
import SuccessScreen from 'components/shared/SuccessScreen'
import FileSelector from 'components/shared/FileSelector'
import i18n from 'i18n'

const Success = ({ history, onFileSelected, closeModal, numAdded }) => (
  <Row>
    <Col xs={10} xsOffset={1} sm={6} smOffset={3}>
      <SuccessScreen
        heading={i18n('business-massing-adding-success')(numAdded)}
        description={i18n('business-massing-adding-success-description')}
      >
        <Row>
          <Col xs={6} xsOffset={3}>
            <Wrapper>
              <Button
                onClick={() => {
                  history.push('/admin')
                  closeModal()
                }}
              >
                {i18n('return-to-admin')}
              </Button>
            </Wrapper>
          </Col>
        </Row>
        <Row>
          <Col xs={6} xsOffset={3}>
            <Wrapper>
              <FileSelector
                label={i18n('upload-another-csv')}
                onFileSelected={onFileSelected}
              />
            </Wrapper>
          </Col>
        </Row>
      </SuccessScreen>
    </Col>
  </Row>
)

Success.propTypes = {
  onFileSelected: func.isRequired,
  numAdded: number.isRequired,
  closeModal: func.isRequired,
  history: object.isRequired
}

export default withRouter(Success)
