import React from 'react'
import { func, array, string, shape, arrayOf } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import Wrapper from 'components/shared/Wrapper'
import Button from 'components/shared/Button'
import Heading from 'components/shared/Heading'
import Description from 'components/shared/Description'
import CheckboxList from './CheckboxList'
import ToggleButtonGroup from 'components/shared/ToggleButtonGroup'
import AutoCompleteInput from 'components/shared/AutoCompleteInput'
import Span from 'components/shared/Span'
import Paragraph from 'components/shared/Paragraph'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'
import media from 'utils/media'

const MAX_INDUSTRY_SELECTABLE = 2

const StyledParagraph = styled(Paragraph)`
  color: #555;
  font-size: 12px;
  ${media.tablet`
    font-size: 10px;
  `};
`

const StyledSpan = styled(Span)`
  color: #aaa;
  font-size: 16px;
  line-height: 22px;
`

const getSuggestionValue = suggestion => suggestion.name

const getSuggestions = (value, categories, selectedCategories) => {
  const inputValue = value.trim().toLowerCase()
  const selectableCategories = categories.filter(category => {
    // remove all of the selected categories out of the suggestion list
    if (selectedCategories.find(({ id }) => category.id === id)) {
      return false
    }
    return true
  })

  if (!inputValue) {
    return selectableCategories
  }

  return selectableCategories.filter(category => {
    return category.name.toLowerCase().includes(inputValue)
  })
}

const onEnter = (value, categories, addCategory) => {
  const inputValue = value.trim().toLowerCase()
  const category = categories.find(
    category => category.name.toLowerCase() === inputValue
  )

  if (category) {
    addCategory(category)
  }
}

class BusinessCapabilities extends React.Component {
  state = {
    selectableCategories: []
  }

  _changeIndustry = (id, isAdded) => {
    const { selectedIndustries, categories, changeIndustry } = this.props
    let _selectedIndustries = []
    if (isAdded) {
      _selectedIndustries = selectedIndustries.concat(id)
    } else {
      _selectedIndustries = selectedIndustries.filter(
        industry => industry !== id
      )
    }

    this.setState({
      selectableCategories: categories.filter(
        ({ industry }) => _selectedIndustries.indexOf(industry) > -1
      )
    })
    changeIndustry(id, isAdded)
  }

  render() {
    const { selectableCategories } = this.state
    const {
      onSubmit,
      industries,
      categories,
      selectedIndustries,
      selectedCategories,
      addCategory,
      removeCategory
    } = this.props

    return (
      <Row>
        <Col xs={10} xsOffset={1} sm={6} smOffset={3}>
          <Wrapper>
            <Heading className="text-center">
              {i18n('business-capabilities')}
            </Heading>
            <Description className="text-center">
              {i18n('business-capabilities-description')}
            </Description>
          </Wrapper>
          <Wrapper>
            <StyledSpan>
              {i18n('industry-sector')(MAX_INDUSTRY_SELECTABLE)}
            </StyledSpan>
            <CheckboxList
              data={industries}
              maxSelectable={MAX_INDUSTRY_SELECTABLE}
              selected={selectedIndustries}
              onChange={this._changeIndustry}
            />
          </Wrapper>
          {selectableCategories.length ? (
            <Wrapper>
              <StyledSpan>{i18n('select-categories')}</StyledSpan>
              <AutoCompleteInput
                placeholder={`${i18n('categories-placeholder')}...`}
                getSuggestionValue={getSuggestionValue}
                getSuggestions={value =>
                  getSuggestions(
                    value,
                    selectableCategories,
                    selectedCategories
                  )
                }
                onSuggestionSelected={addCategory}
                onEnter={value => onEnter(value, categories, addCategory)}
              />
              {selectedCategories.length ? (
                <ToggleButtonGroup
                  data={selectedCategories}
                  onRemoved={removeCategory}
                  getKey={({ id }) => id}
                  getName={({ name }) => name}
                />
              ) : null}
              <StyledParagraph>{i18n('category-description')}</StyledParagraph>
            </Wrapper>
          ) : null}
          <Row>
            <Col xs={12}>
              <Button
                onClick={onSubmit}
                disabled={
                  !selectedIndustries.length || !selectedCategories.length
                }
              >
                {i18n('next')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

BusinessCapabilities.propTypes = {
  onSubmit: func.isRequired,
  industries: array.isRequired,
  categories: array.isRequired,
  selectedIndustries: arrayOf(string).isRequired,
  selectedCategories: arrayOf(shape(slugShape)).isRequired,
  addCategory: func.isRequired,
  removeCategory: func.isRequired,
  changeIndustry: func.isRequired
}

export default BusinessCapabilities
