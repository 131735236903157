import React from 'react'
import PropTypes from 'prop-types'

import BootstrapInput from 'components/shared/Input'
import styled from 'styled-components'
import media from 'utils/media'

const Input = styled(BootstrapInput)`
  padding-right: 150px;
  ${media.tablet`
    padding-right: 110px;
  `};
`

const Span = styled.span`
  position: absolute;
  color: ${props => props.theme.colors['colour-error-red']}
  right: 5px;
  bottom: 5px;
  padding-left: 5px;
`

const Wrapper = styled.div`
  position: relative;
`

// Define as a stateful component to avoid warning from React when used with Autosuggest
const InputWithCaption = ({ caption, ...props }) => (
  <Wrapper>
    <Input type="text" {...props} />
    <Span>{caption}</Span>
  </Wrapper>
)

InputWithCaption.propTypes = {
  caption: PropTypes.string.isRequired
}

export default InputWithCaption
