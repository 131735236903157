import React from 'react'
import { object, string } from 'prop-types'

import Label from './Label'
import StyledSelect from 'components/shared/Select'
import Paragraph from 'components/shared/Paragraph'
import Wrapper from 'components/shared/Wrapper'

const Select = ({
  label,
  input,
  meta: { touched, error, warning },
  ...props
}) => (
  <Wrapper small>
    <Label>{label}</Label>
    <StyledSelect
      {...props}
      {...input}
      name={input.name}
      onChange={input.onChange}
      onBlur={() => {}}
      value={input.value}
      components={{ IndicatorSeparator: null }}
    />
    {touched &&
      ((error && (
        <Paragraph className="pull-right" error={touched && error}>
          {error}
        </Paragraph>
      )) ||
        (warning || <Paragraph className="pull-right">{warning}</Paragraph>))}
  </Wrapper>
)

Select.propTypes = {
  label: string.isRequired,
  input: object.isRequired
}

export default Select
