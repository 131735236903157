const admin = {
  'update-accounts': 'Update Accounts',
  'pending-tab': 'Awaiting Approval',
  'active-tab': 'Active Organisations',
  'disabled-tab': 'Disabled Organisations',
  affiliation: 'Affiliation',
  'request-date': 'Request Date',
  reject: 'Reject',
  approve: 'Approve',
  'no-entity': 'No organisation available',
  'last-active': 'Last Active',
  disable: 'Disable',
  enable: 'Enable',
  'date-disabled': 'Date disabled',
  reason: 'Reason',
  locations: 'Locations',
  'poc-email': 'Point of contact email',
  'business-number': 'Organisation Number',
  'organisation-size': 'Organisation Size',
  'year-established': 'Year established',
  'industry-sectors': 'Industry Sectors',
  'product-categories': 'Product Categories',
  'download-csv': 'Download CSV',
  'add-organisation': 'Add organisation',
  'add-organisations': num => `Add ${num} organisation${num > 1 ? 's' : ''}`,
  'add-single-organisation': 'Add single organisation',
  'upload-csv': 'Upload CSV file',
  'download-template': 'Download template',
  'business-adding-modal-title': 'Add Organisations to the Directory',
  'business-adding-modal-description':
    'You can either add a organisation one at a time or upload a CSV file of organisations you would like to add to the Australian Food and Agribusiness Directory',
  'no-business': 'There is no organisation to add',
  'business-massing-adding-success': num =>
    `${num} organisation${
      num > 1 ? 's' : ''
    } have been added to the directory!`,
  'business-massing-adding-success-description':
    'Each organisation will receive an email to notify them they have been added to the platform',
  'return-to-admin': 'Return to admin page',
  'return-to-organisation': 'Return to organisation page',
  'upload-another-csv': 'Upload another CSV file',
  'update-organisations': 'Update organisations'
}

export default admin
