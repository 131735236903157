import { handleActions } from 'redux-actions'

import {
  startLoading,
  updateData,
  loadingFailure,
  changeScreenSize
} from './actions'

export const INITIAL_STATE = {
  loaded: false,
  loading: false,
  size: {
    width: 0,
    height: 0
  },
  error: [],
  industries: [],
  sizes: [],
  categories: [],
  affiliates: [],
  topics: [],
  reasons: [],
  locations: []
}

export default handleActions(
  {
    [startLoading]: () => ({
      ...INITIAL_STATE,
      loading: true
    }),
    [updateData]: (state, { payload: { data } }) => ({
      ...state,
      loaded: true,
      ...data
    }),
    [loadingFailure]: (state, { payload: { error } }) => ({
      ...INITIAL_STATE,
      error
    }),
    [changeScreenSize]: (state, { payload: { size } }) => ({
      ...state,
      size
    })
  },
  INITIAL_STATE
)
