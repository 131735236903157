import styled from 'styled-components'

import media from 'utils/media'

const Banner = styled.div`
  width: 100%;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 300px;
  ${media.tablet`
    height: 100px;
  `};
`

export default Banner
