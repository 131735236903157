import React from 'react'
import { arrayOf, string, func, bool, object, shape } from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Field, reduxForm, getFormValues, reset } from 'redux-form'
import { bindActionCreators } from 'redux'

import Wrapper from 'components/shared/Wrapper'
import RadioList from 'components/shared/RadioList'
import Button from 'components/shared/Button'
import SuccessScreen from 'components/shared/SuccessScreen'
import { disableEntities } from 'http/entity'
import Input from 'components/shared/Form/Input'
import { notifyError } from 'utils/notify'
import errorToMessage from 'utils/errorToMessage'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'

const organisationDisablingForm = 'OrganisationDisabling'

class DisableOrganisationModal extends React.Component {
  state = {
    success: false
  }

  doDisable = async data => {
    const { id, onSuccess } = this.props
    try {
      const reason = data.reason === 'other' ? data.comment : data.reason
      await disableEntities([{ id, reason }])
      this.props.reset()
      this.setState({
        success: true
      })
      onSuccess()
    } catch (error) {
      notifyError(errorToMessage(error))
    }
  }

  render() {
    const {
      name,
      reasons,
      invalid,
      handleSubmit,
      submitting,
      formValues
    } = this.props
    if (this.state.success) {
      return (
        <SuccessScreen
          heading={i18n('disable-organisation-success')}
          description={i18n('disable-organisation-success-description')(name)}
        />
      )
    }

    return (
      <Row>
        <Col xs={12} sm={6} smOffset={3}>
          <Row>
            <Col xs={12}>
              <SuccessScreen
                heading={i18n('disable-organisation-modal-title')(name)}
                description={i18n('disable-organisation-modal-description')}
              />
            </Col>
          </Row>
          <Wrapper>
            <Row>
              <Col xs={12}>
                <form
                  name={organisationDisablingForm}
                  onSubmit={handleSubmit(this.doDisable)}
                >
                  <Wrapper>
                    <RadioList
                      name="reason"
                      label={i18n('reason-for-disabling-account')}
                      data={reasons}
                    />
                    <Field
                      name="comment"
                      type="text"
                      component={Input}
                      label={i18n('comment')}
                      disabled={submitting || formValues.reason !== 'other'}
                    />
                  </Wrapper>
                  <Row>
                    <Col xs={12}>
                      <Button
                        type="submit"
                        disabled={invalid || submitting}
                        danger="true"
                      >
                        {i18n(submitting ? 'disabling' : 'disable')}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Wrapper>
        </Col>
      </Row>
    )
  }
}

DisableOrganisationModal.propTypes = {
  onSuccess: func.isRequired,
  reasons: arrayOf(shape(slugShape)).isRequired,
  name: string.isRequired,
  id: string.isRequired,
  reset: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  invalid: bool.isRequired,
  formValues: object
}

const mapStateToProps = state => ({
  formValues: getFormValues(organisationDisablingForm)(state) || {}
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      reset: () => reset(organisationDisablingForm)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: organisationDisablingForm
  })(DisableOrganisationModal)
)
