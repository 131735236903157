import { createActions } from 'redux-actions'

export const {
  search: {
    search,
    startSearching,
    searchSuccess,
    searchFailure,
    changeIndustryFilter,
    changeSizeFilter,
    updateFilters,
    updateFilteredResult,
    setIndustryFilter,
    changeIndustryKeyword,
    changeCategoryKeyword,
    changeNameKeyword,
    changeLocationKeyword,
    updateKeywords,
    changePage,
    updatePageData
  }
} = createActions({
  '@search/search': undefined,
  '@search/startSearching': undefined,
  '@search/searchSuccess': result => ({ result }),
  '@search/searchFailure': error => ({ error }),
  '@search/changeIndustryFilter': (industries, isAdded) => ({
    industries,
    isAdded
  }),
  '@search/changeSizeFilter': (sizes, isAdded) => ({
    sizes,
    isAdded
  }),
  '@search/updateFilters': filters => ({ filters }),
  '@search/updateFilteredResult': result => ({ result }),
  '@search/setIndustryFilter': industry => ({ industry }),
  '@search/changeIndustryKeyword': (industries, isAdded) => ({
    industries,
    isAdded
  }),
  '@search/changeCategoryKeyword': (categories, isAdded) => ({
    categories,
    isAdded
  }),
  '@search/changeNameKeyword': (names, isAdded) => ({
    names,
    isAdded
  }),
  '@search/changeLocationKeyword': locations => ({ locations }),
  '@search/updateKeywords': keywords => ({ keywords }),
  '@search/changePage': page => ({ page }),
  '@search/updatePageData': data => ({ data })
})
