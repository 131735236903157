import React from 'react'
import { string, func } from 'prop-types'

import Button from 'components/shared/Button'

class FileSelector extends React.Component {
  render() {
    const { label, onFileSelected } = this.props
    return (
      <Button
        secondary="true"
        onClick={() => {
          this.upload.click()
        }}
      >
        {label}
        <input
          type="file"
          accept=".csv"
          ref={ref => (this.upload = ref)}
          style={{ display: 'none' }}
          onChange={() => {
            onFileSelected(this.upload.files[0])
            this.upload.value = ''
          }}
        />
      </Button>
    )
  }
}

FileSelector.propTypes = {
  label: string.isRequired,
  onFileSelected: func.isRequired
}

export default FileSelector
