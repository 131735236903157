import React from 'react'
import { object, arrayOf, shape, func, bool } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import Page from 'components/Page/Page'
import Title from 'components/shared/Navigation/Title'
import Result from 'components/Page/Search/Result'
import Filters from 'components/Page/Search/Filters'
import Loading from 'components/shared/Loading'
import {
  search,
  changeIndustryFilter,
  changeSizeFilter,
  changePage
} from 'components/Page/Search/actions'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'
import media from 'utils/media'

const Wrapper = styled.div`
  margin-top: 50px;
  min-height: 1000px;
  ${media.tablet`
    margin-top: 25px;
    min-height: 500px;
  `};
`

class Search extends React.Component {
  componentDidMount() {
    const { result, filters, search } = this.props
    // if result is empty while there is no filter, it means Search page may be the first loaded page.
    // Try to load all entities
    if (
      !result.all.length &&
      !Object.keys(filters).some(key => filters[key].length > 0)
    ) {
      search()
    }
  }

  render() {
    const {
      history,
      searching,
      filters,
      result,
      industries,
      categories,
      changeIndustryFilter,
      changePage
    } = this.props

    const filterSections = [
      /* Change-Request 20190313 to remove the business size filter
      {
        label: i18n('business-size'),
        filters: sizes,
        onChange: changeSizeFilter,
        checked: filters.sizes
      },
      */
      {
        label: i18n('industry-sectors'),
        filters: industries,
        onChange: changeIndustryFilter,
        checked: filters.industries
      }
    ]
    return (
      <Page>
        <Title title={`${i18n('search').toUpperCase()}`} />
        <Wrapper>
          <Row>
            <Col xs={12} lg={10} lgOffset={1}>
              <Row>
                <Col sm={4} md={3} xsHidden>
                  <Filters sections={filterSections} />
                </Col>
                <Col xs={12} sm={8} md={9}>
                  {searching ? (
                    <Loading />
                  ) : (
                    <Result
                      result={result}
                      filterSections={filterSections}
                      history={history}
                      changePage={changePage}
                      categories={categories}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Page>
    )
  }
}

Search.propTypes = {
  match: object.isRequired,
  history: object.isRequired,
  searching: bool.isRequired,
  result: object.isRequired,
  filters: object.isRequired,
  sizes: arrayOf(shape(slugShape)).isRequired,
  industries: arrayOf(shape(slugShape)).isRequired,
  categories: arrayOf(shape(slugShape)).isRequired,
  search: func.isRequired,
  changeIndustryFilter: func.isRequired,
  changeSizeFilter: func.isRequired,
  changePage: func.isRequired
}

const mapStateToProps = ({
  data: { sizes, industries },
  search: {
    searching,
    result,
    filters,
    keywords: { categories }
  }
}) => ({
  searching,
  result,
  filters,
  sizes,
  industries,
  categories
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      search,
      changeIndustryFilter,
      changeSizeFilter,
      changePage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Search))
