import { createActions } from 'redux-actions'

export const {
  newBusiness: {
    openModal,
    closeModal,
    startAdding,
    addNewBusiness,
    finishAdding,
    addAddress,
    removeAddress,
    addCategory,
    removeCategory,
    changeIndustry,
    reset
  }
} = createActions({
  '@newBusiness/openModal': organisation => ({ organisation }),
  '@newBusiness/closeModal': undefined,
  '@newBusiness/addAddress': address => ({ address }),
  '@newBusiness/removeAddress': address => ({ address }),
  '@newBusiness/addCategory': category => ({ category }),
  '@newBusiness/removeCategory': category => ({ category }),
  '@newBusiness/changeIndustry': (industry, isAdded) => ({ industry, isAdded }),
  '@newBusiness/addNewBusiness': business => ({
    business
  }),
  '@newBusiness/startAdding': undefined,
  '@newBusiness/finishAdding': error => ({ error }),
  '@newBusiness/reset': undefined
})
