import React from 'react'
import { bool, func } from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Wrapper from 'components/shared/Wrapper'
import Button from 'components/shared/Button'
import Clickable from 'components/shared/Clickable'
import Heading from 'components/shared/Heading'
import i18n from 'i18n'
import Input from 'components/shared/Form/Input'
import * as validators from 'components/shared/Form/validators'
import { logIn } from './actions'

const LoginForm = ({
  invalid,
  starting,
  handleSubmit,
  onFormToggle,
  logIn
}) => (
  <form onSubmit={handleSubmit(logIn)}>
    <Row>
      <Col xs={12}>
        <Wrapper>
          <Heading className="text-center">{i18n('login-form')}</Heading>
        </Wrapper>
      </Col>
    </Row>
    <Wrapper>
      <Field
        name="email"
        type="email"
        component={Input}
        label={i18n('email')}
        validate={[validators.emailValidator]}
        disabled={starting}
      />
      <Field
        name="password"
        type="password"
        component={Input}
        label={i18n('password')}
        validate={[validators.passwordValidator]}
        disabled={starting}
      />
    </Wrapper>
    <Row>
      <Col xs={12} sm={6} smOffset={6}>
        <Wrapper small>
          <Button type="submit" disabled={invalid || starting}>
            {i18n(starting ? 'logging-in' : 'login')}
          </Button>
        </Wrapper>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Clickable
          className="pull-right"
          style={{ textDecoration: 'underline' }}
          onClick={onFormToggle}
        >
          {i18n('forgot-password')}
        </Clickable>
      </Col>
    </Row>
  </form>
)

LoginForm.propTypes = {
  invalid: bool.isRequired,
  handleSubmit: func.isRequired,
  onFormToggle: func.isRequired,
  logIn: func.isRequired,
  authenticated: bool.isRequired,
  starting: bool.isRequired
}

const mapStateToProps = ({
  auth: { user, authenticated, starting, error }
}) => ({
  user,
  authenticated,
  starting,
  error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logIn
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'login'
  })(LoginForm)
)
