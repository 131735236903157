import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import media from 'utils/media'

export default styled(Button)`
  border-radius: 25px !important;
  margin: 7px;
  ${media.tablet`
    margin: 4px;
  `};
`
