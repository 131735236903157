import React from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'

import Input from 'components/shared/Input'
import Paragraph from 'components/shared/Paragraph'
import i18n from 'i18n'

const getPostalInfo = result => {
  if (result.address_components.length < 7) return null

  const postal = {}
  result.address_components.forEach(({ types, short_name: shortName }) => {
    if (types.indexOf('street_number') > -1) {
      postal.streetNo = shortName
    }
    if (types.indexOf('route') > -1) {
      postal.streetName = shortName
    }
    if (types.indexOf('locality') > -1) {
      postal.suburb = shortName
    }
    if (types.indexOf('administrative_area_level_1') > -1) {
      postal.state = shortName
    }
    if (types.indexOf('postal_code') > -1) {
      postal.postcode = shortName
    }
    if (types.indexOf('country') > -1) {
      postal.country = shortName
    }
  })
  // missing some key info
  if (Object.keys(postal).length < 6) return null
  return postal
}

class LocationSearchInput extends React.Component {
  state = {
    address: '',
    error: null
  }

  handleChange = address => {
    this.setState({ address, error: null })
  }

  handleSelect = async address => {
    try {
      const results = await geocodeByAddress(address)
      const { lat, lng } = await getLatLng(results[0])
      const postal = getPostalInfo(results[0])

      if (!postal) {
        // invalid address
        this.setState({
          ...this.state,
          error: i18n('invalid-address')
        })
        return
      }

      this.props.onLocationSelected({
        latitude: lat,
        longitude: lng,
        postal
      })
      this.setState({
        address: ''
      })
    } catch (error) {
      console.error('Error', error)
    }
  }

  render() {
    const { error } = this.state
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={1000}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          const _suggestions = [...suggestions]
          _suggestions.sort((a, b) => {
            if (a.description.endsWith('Australia')) return -1
            if (b.description.endsWith('Australia')) return 1
            return 0
          })
          return (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input'
                })}
              />
              {error && (
                <Paragraph className="pull-right" error={error}>
                  {error}
                </Paragraph>
              )}
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {_suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' }
                  return (
                    <div
                      key={suggestion.description}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }}
      </PlacesAutocomplete>
    )
  }
}

LocationSearchInput.propTypes = {
  onLocationSelected: PropTypes.func.isRequired
}

export default LocationSearchInput
