import client from './client'

export const resetPassword = email =>
  client.post('api/v1/auth/forgot-password', { email })

export const changePassword = ({ resetToken, password }) =>
  client.post(`api/v1/auth/reset-password?token=${resetToken}`, {
    newPassword: password,
    verifyPassword: password
  })

export const logIn = ({ email, password }) =>
  client.post('api/v1/auth/login', { email, password })

export const logOut = () => client.post('api/v1/auth/logout')

export const getToken = refreshToken =>
  client.post('api/v1/auth/token', { refreshToken })
