import { takeEvery, put, select } from 'redux-saga/effects'

import { notifyError } from 'utils/notify'
import errorToMessage from 'utils/errorToMessage'
import {
  getPendingEntities,
  getActiveEntities,
  getDisabledEntities,
  approveEntities,
  rejectEntities,
  disableEntities,
  enableEntities,
  updateAffiliates
} from 'http/entity'

import {
  start,
  finish,
  loadEntities,
  loadSuccess,
  loadFailure,
  updateEntities,
  changeFilter,
  changeFilterType,
  changeFilterText,
  changeEntities
} from './actions'

import {
  ENTITY_STATUS,
  PENDING_ENTITY,
  ACTIVE_ENTITY,
  DISABLED_ENTITY,
  ADMIN_FILTERS
} from 'utils/constants'

function* doLoadEntities() {
  try {
    yield put(start())
    const values = yield Promise.all([
      getPendingEntities(),
      getActiveEntities(),
      getDisabledEntities()
    ])
    yield put(
      loadSuccess({
        [PENDING_ENTITY]: values[0].data,
        [ACTIVE_ENTITY]: values[1].data,
        [DISABLED_ENTITY]: values[2].data
      })
    )
  } catch (error) {
    const msg = errorToMessage(error)
    yield put(loadFailure(msg))
    notifyError(msg)
  }
}

function* doUpdateEntities() {
  try {
    yield put(start())
    const entities = yield select(state => state.admin.entities)
    const toApprove = []
    const toReject = []
    const toEnable = []
    const toDisable = []
    const toUpdateAffiliates = []

    ENTITY_STATUS.forEach(status => {
      entities[status].all.forEach(entity => {
        if (entity.approving) toApprove.push(entity.id)
        if (entity.rejecting) toReject.push(entity.id)
        if (entity.enabling) toEnable.push(entity.id)
        if (entity.disabling) toDisable.push({ id: entity.id, reason: '' })
        if (
          entity.newAffiliate !== undefined &&
          entity.newAffiliate !== entity.affiliates[0]
        ) {
          toUpdateAffiliates.push({
            entity: entity.id,
            affiliates: [entity.newAffiliate]
          })
        }
      })
    })

    if (
      !toApprove.length &&
      !toReject.length &&
      !toEnable.length &&
      !toDisable.length &&
      !toUpdateAffiliates.length
    ) {
      notifyError('There were no changes to update')
      return yield put(finish())
    }

    yield Promise.all([
      approveEntities(toApprove),
      rejectEntities(toReject),
      enableEntities(toEnable),
      disableEntities(toDisable),
      updateAffiliates(toUpdateAffiliates)
    ])

    yield put(finish())
    yield put(loadEntities())
  } catch (error) {
    const msg = errorToMessage(error)
    yield put(loadFailure(msg))
    notifyError(msg)
  }
}
const doChangeFilter = type => {
  return function* doChangeFilter({ payload }) {
    const filter = yield select(state => state.admin.filter)
    const entities = yield select(state => state.admin.entities)

    // update filter
    const newFilter = {
      ...filter,
      [type]: payload.toLowerCase()
    }
    yield put(changeFilter(newFilter))

    // re-filter entities
    const newEntities = ENTITY_STATUS.reduce((acc, status) => {
      acc[status] = {
        ...entities[status],
        filtered: entities[status].all.filter(
          ADMIN_FILTERS[newFilter.type].filter(newFilter.text)
        )
      }
      return acc
    }, {})

    yield put(changeEntities(newEntities))
  }
}

export const adminSagas = [
  takeEvery(loadEntities().type, doLoadEntities),
  takeEvery(updateEntities().type, doUpdateEntities),
  takeEvery(changeFilterType().type, doChangeFilter('type')),
  takeEvery(changeFilterText().type, doChangeFilter('text'))
]
