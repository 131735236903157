import React from 'react'
import { Image } from 'react-bootstrap'
import styled from 'styled-components'

import media from 'utils/media'

const StyledImage = styled(Image)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  ${media.desktop`
    width: 15px;
    height: 15px;
    top: 10px;
    right: 10px;
  `};
`

const ExitImage = props => <StyledImage {...props} src="/icons/exit.svg" />

export default ExitImage
