import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'

const userIsPublic = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/admin',
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: ({ auth: { authenticated, user } }) =>
    !(authenticated && user),
  // A nice display name for this check
  wrapperDisplayName: 'UserIsPublic'
})

export default userIsPublic
