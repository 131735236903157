import styled from 'styled-components'

import Label from 'components/shared/Label'
import media from 'utils/media'

export default styled(Label)`
  color: #aaa;
  font-size: 16px;
  line-height: 22px;
  ${media.tablet`
    font-size: 12px;
    line-height: 17px;
  `};
`
