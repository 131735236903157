import React from 'react'
import { func, array } from 'prop-types'
import { Marker } from 'react-google-maps'

class Markers extends React.Component {
  state = {
    current: null
  }

  onClick = location => () => {
    this.setState({
      current: location
    })
    this.props.onClick(location)
  }

  render() {
    const { locations } = this.props
    const { current } = this.state

    return locations.map(location => (
      <Marker
        key={location.latitude + location.longitude}
        icon={{
          url: '/icons/pin.svg',
          scaledSize:
            location === current
              ? new window.google.maps.Size(30, 54)
              : new window.google.maps.Size(20, 36)
        }}
        position={{ lat: location.latitude, lng: location.longitude }}
        onClick={this.onClick(location)}
      />
    ))
  }
}

Markers.propTypes = {
  onClick: func,
  locations: array.isRequired
}

export default Markers
