import React from 'react'
import { object, arrayOf, shape, func } from 'prop-types'
import Papa from 'papaparse'
import { Row, Col, Image } from 'react-bootstrap'
import ReactTable, { ReactTableDefaults } from 'react-table'

import Wrapper from 'components/shared/Wrapper'
import Paragraph from 'components/shared/Paragraph'
import Button from 'components/shared/Button'
import Success from './Success'
import BusinessEditor from './BusinessEditor'
import Input from './Input'
import validateEntities from './validateEntities'
import { slugShape } from 'utils/shapes'
import i18n from 'i18n'
import { ENTITY_FIELDS } from 'utils/constants'
import { renderHeader, cellStyle, headerStyle, parseEntities } from '../helpers'
import deepClone from 'utils/deepClone'
import { notifyError } from 'utils/notify'
import { addEntities } from 'http/entity'
import errorToMessage from 'utils/errorToMessage'

const allFields = Object.values(ENTITY_FIELDS)
const {
  NAME,
  ABN,
  SIZE,
  YEAR,
  DESCRIPTION,
  PHONE,
  WEBSITE,
  EMAIL,
  ADDRESSES,
  INDUSTRIES,
  CATEGORIES,
  POC_FIRSTNAME,
  POC_LASTNAME,
  POC_EMAIL
} = ENTITY_FIELDS

const arrayFields = [INDUSTRIES, CATEGORIES, ADDRESSES]

const lowerCaseFields = [INDUSTRIES, CATEGORIES, SIZE]

const numericFields = [YEAR]

class BusinessMassAddingModal extends React.Component {
  state = {
    csvError: null,
    entities: [],
    isSortDesc: false,
    validationErrors: [],
    importing: false,
    importSuccess: false
  }

  componentDidMount() {
    this.parseCSV(this.props.csvFile)
  }

  parseCSV = csvFile => {
    this.setState({
      csvError: null,
      entities: [],
      isSortDesc: false,
      validationErrors: [],
      importing: false,
      importSuccess: false
    })
    Papa.parse(csvFile, {
      complete: results => {
        let entities = parseEntities({
          csvContent: results.data,
          allFields,
          arrayFields,
          idField: NAME,
          lowerCaseFields,
          numericFields
        })
        if (!entities) {
          return this.setState({
            ...this.state,
            csvError: i18n('csv-malformatted')
          })
        }

        this.setState({
          ...this.state,
          entities
        })
      }
    })
  }

  importEntities = async () => {
    const transform = entity => {
      return {
        [ABN]: entity[ABN],
        [NAME]: entity[NAME],
        [INDUSTRIES]: entity[INDUSTRIES],
        [CATEGORIES]: entity[CATEGORIES],
        [YEAR]: entity[YEAR],
        [SIZE]: entity[SIZE],
        [DESCRIPTION]: entity[DESCRIPTION],
        phoneAddresses: [entity[PHONE]],
        websiteAddresses: [entity[WEBSITE]],
        emailAddresses: [entity[EMAIL]],
        [ADDRESSES]: entity[ADDRESSES],
        poc: {
          [POC_FIRSTNAME]: entity[POC_FIRSTNAME],
          [POC_LASTNAME]: entity[POC_LASTNAME],
          [POC_EMAIL]: entity[POC_EMAIL]
        }
      }
    }

    const { industries, categories } = this.props
    const { entities } = this.state
    const errors = validateEntities({
      entities,
      industries: industries.map(({ id }) => id),
      categories: categories.map(({ id }) => id)
    })
    if (errors) {
      this.setState({
        ...this.state,
        validationErrors: errors
      })
      return
    }

    try {
      this.setState({
        ...this.state,
        importing: true
      })

      await addEntities(entities.map(transform))

      this.setState({
        ...this.state,
        importing: false,
        importSuccess: true
      })
    } catch (error) {
      this.setState({
        ...this.state,
        importing: false
      })
      notifyError(errorToMessage(error))
    }
  }

  removeEntity = index => {
    const { entities } = this.state
    this.setState({
      ...this.state,
      entities: entities.filter((entity, idx) => index !== idx)
    })
  }

  updateEntity = (index, field, value) => {
    const { entities } = this.state

    this.setState({
      ...this.state,
      entities: entities.map((entity, idx) => {
        if (index === idx) {
          const clone = deepClone(entities[index])
          clone[field] = value
          return clone
        }
        return entity
      })
    })
  }

  columns = [
    {
      Header: () => renderHeader(i18n('business-name'), this.state.isSortDesc),
      accessor: NAME,
      Cell: row => (
        <Input
          value={row.value}
          onChange={({ target: { value } }) =>
            this.updateEntity(row.index, NAME, value)
          }
        />
      )
    },
    {
      Header: () => renderHeader(i18n('website'), this.state.isSortDesc),
      accessor: WEBSITE,
      Cell: row => (
        <Input
          value={row.value}
          onChange={({ target: { value } }) =>
            this.updateEntity(row.index, WEBSITE, value)
          }
        />
      )
    },
    {
      Header: () => renderHeader(i18n('business-email'), this.state.isSortDesc),
      accessor: EMAIL,
      Cell: row => (
        <Input
          value={row.value}
          onChange={({ target: { value } }) =>
            this.updateEntity(row.index, EMAIL, value)
          }
        />
      )
    },
    {
      Header: () => renderHeader(i18n('business-phone'), this.state.isSortDesc),
      accessor: PHONE,
      Cell: row => (
        <Input
          value={row.value}
          onChange={({ target: { value } }) =>
            this.updateEntity(row.index, PHONE, value)
          }
        />
      )
    },
    {
      Cell: row => (
        <Button
          danger="true"
          size="small"
          onClick={() => this.removeEntity(row.index)}
        >
          {i18n('remove')}
        </Button>
      ),
      width: 100,
      sortable: false
    },
    {
      Expander: ({ isExpanded }) => (
        <Image src={`/icons/${isExpanded ? 'down' : 'right'}.svg`} />
      ),
      sortable: false,
      expander: true
    }
  ]

  render() {
    const {
      entities,
      csvError,
      validationErrors,
      importing,
      importSuccess
    } = this.state

    if (csvError) {
      return (
        <Wrapper className="text-center">
          <Paragraph error>{csvError}</Paragraph>
        </Wrapper>
      )
    }

    if (entities.length === 0) {
      return (
        <Wrapper className="text-center">
          <Paragraph error>{i18n('no-business')}</Paragraph>
        </Wrapper>
      )
    }

    if (importSuccess) {
      return (
        <Success
          numAdded={entities.length}
          closeModal={this.props.closeModal}
          onFileSelected={this.parseCSV}
        />
      )
    }

    return (
      <Wrapper>
        <Row>
          <Col xs={11}>
            <Wrapper>
              <ReactTable
                data={entities}
                columns={this.columns}
                minRows={0}
                showPagination={true}
                resizable={false}
                style={{
                  border: 'none'
                }}
                column={{
                  ...ReactTableDefaults.column,
                  headerStyle,
                  style: {
                    ...cellStyle,
                    backgroundColor: '#f3f3f3'
                  }
                }}
                collapseOnDataChange={false}
                multiSort={false}
                onSortedChange={newSorted => {
                  const isSortDesc = {}
                  newSorted.forEach(
                    column => (isSortDesc[column.id] = column.desc)
                  )
                  this.setState({
                    ...this.state,
                    isSortDesc
                  })
                }}
                SubComponent={row => (
                  <BusinessEditor
                    entity={row.original}
                    onChange={(field, value) =>
                      this.updateEntity(row.index, field, value)
                    }
                  />
                )}
              />
            </Wrapper>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} smOffset={3}>
            <Wrapper>
              <Button onClick={this.importEntities} disabled={importing}>
                {i18n('add-organisations')(entities.length)}
              </Button>
            </Wrapper>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Wrapper>
              {validationErrors.map(error => (
                <Paragraph error key={error}>
                  {error}
                </Paragraph>
              ))}
            </Wrapper>
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

BusinessMassAddingModal.propTypes = {
  csvFile: object,
  closeModal: func.isRequired,
  sizes: arrayOf(shape(slugShape)).isRequired,
  industries: arrayOf(shape(slugShape)).isRequired,
  categories: arrayOf(shape(slugShape)).isRequired
}

export default BusinessMassAddingModal
