import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Page from 'components/Page/Page'
import Title from 'components/shared/Navigation/Title'
import Paragraph from 'components/shared/Paragraph'
import i18n from 'i18n'
import media from 'utils/media'

const H1 = styled.h1`
  color: #4A4A4A;
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  ${media.tablet`
    font-size: 30px;
  `}
}
`
const StyledParagraph = styled(Paragraph)`
  color: #555;
  font-size: 16px;
  line-height: 28px;
  ${media.tablet`
    font-size: 14px;
  `}
}
`

const NotFound = () => (
  <Page>
    <Title title={`${i18n('notfound').toUpperCase()}`} />
    <Row>
      <Col xs={12} className="text-center">
        <H1>{i18n('page-not-found-header')}</H1>
        <StyledParagraph>{i18n('page-not-found-description')}</StyledParagraph>
        <StyledParagraph>
          Visit <Link to="/">HOMEPAGE</Link> or{' '}
          <Link to="/contact">CONTACT US</Link> about the problem
        </StyledParagraph>
      </Col>
    </Row>
  </Page>
)

export default NotFound
