import React from 'react'
import { func } from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import SuccessScreen from 'components/shared/SuccessScreen'
import Button from 'components/shared/Button'
import i18n from 'i18n'

const ResetPasswordSuccess = ({ openAuthModal }) => (
  <SuccessScreen heading={i18n('reset-password-success')}>
    <Row>
      <Col xs={12} sm={6} smOffset={3}>
        <Button onClick={openAuthModal}>{i18n('login')}</Button>
      </Col>
    </Row>
  </SuccessScreen>
)

ResetPasswordSuccess.propTypes = {
  openAuthModal: func.isRequired
}

export default ResetPasswordSuccess
