import React from 'react'
import { Image } from 'react-bootstrap'
import Input from 'components/shared/Input'
import styled from 'styled-components'

import media from 'utils/media'

const StyledInput = styled(Input)`
  padding: 10px 40px 13px 40px;
  ${media.tablet`
    padding-left: 30px;
    padding-right: 30px;
  `};
`

const StyledImage = styled(Image)`
  position: absolute;
  right: 5px;
  bottom: 30%;
  padding-left: 5px;
  width: 25px;
  ${media.tablet`
    width: 20px;
  `};
`

const Wrapper = styled.div`
  position: relative;
  width: '100%';
`

const InputWithSearchIcon = ({ icon, ...props }) => (
  <Wrapper>
    <StyledInput type="text" {...props} />
    <StyledImage src={`icons/${icon}.svg`} />
  </Wrapper>
)

export default InputWithSearchIcon
