import styled from 'styled-components'

import media from 'utils/media'

export default styled.div`
  width: 100%;
  padding: 20px;
  ${media.tablet`
    padding: 10px;
  `};
`
