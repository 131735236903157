import styled from 'styled-components'

import Paragraph from 'components/shared/Paragraph'
import media from 'utils/media'

export default styled(Paragraph)`
  font-size: 24px;
  line-height: 33px;
  ${media.tablet`
    font-size: 20px;
  `};
`
