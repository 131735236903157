import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import Wrapper from 'components/shared/Wrapper'
import Button from 'components/shared/Button'
import Paragraph from 'components/shared/Paragraph'
import Input from 'components/shared/Form/Input'
import * as validators from 'components/shared/Form/validators'
import * as auth from 'http/auth'
import { notifyError } from 'utils/notify'
import errorToMessage from 'utils/errorToMessage'
import i18n from 'i18n'
import media from 'utils/media'

const H1 = styled.h1`
  color: #4A4A4A;
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  ${media.tablet`
    font-size: 30px;
  `}
}
`
const StyledParagraph = styled(Paragraph)`
  color: #555;
  font-size: 16px;
  line-height: 28px;
  ${media.tablet`
    font-size: 14px;
  `}
}
`

const doChangePassword = (resetToken, onSuccess) => async ({ password }) => {
  try {
    await auth.changePassword({ resetToken, password })
    onSuccess()
  } catch (error) {
    notifyError(errorToMessage(error))
  }
}

const ResetPasswordForm = ({
  handleSubmit,
  invalid,
  submitting,
  resetToken,
  onSuccess
}) => (
  <form onSubmit={handleSubmit(doChangePassword(resetToken, onSuccess))}>
    <Row>
      <Col xs={12}>
        <Wrapper className="text-center">
          <H1 className="text-center">{i18n('reset-password-form')}</H1>
          <StyledParagraph className="text-center">
            {i18n('reset-password-form-description')}
          </StyledParagraph>
        </Wrapper>
      </Col>
    </Row>
    <Wrapper>
      <Field
        name="password"
        type="password"
        component={Input}
        label={i18n('password')}
        validate={validators.passwordValidator}
        disabled={submitting}
      />
      <Field
        name="confirmPassword"
        type="password"
        component={Input}
        label={i18n('confirm-password')}
        validate={validators.matchingPasswordValidator}
        disabled={submitting}
      />
    </Wrapper>
    <Row>
      <Col xs={12} sm={6} smOffset={6}>
        <Button type="submit" disabled={invalid || submitting}>
          {i18n(submitting ? 'resetting-password' : 'reset-password')}
        </Button>
      </Col>
    </Row>
  </form>
)

ResetPasswordForm.propTypes = {
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetToken: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default reduxForm({
  form: 'resetPassword'
})(ResetPasswordForm)
