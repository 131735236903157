import React from 'react'
import PropTypes from 'prop-types'

import ForgotPasswordForm from './ForgotPasswordForm'
import EmailSent from './EmailSent'

class ForgotPassword extends React.Component {
  state = {
    emailSent: false
  }

  render() {
    if (!this.state.emailSent) {
      return (
        <ForgotPasswordForm
          onSuccess={() => this.setState({ emailSent: true })}
          onFormToggle={this.props.onFormToggle}
        />
      )
    }
    return <EmailSent />
  }
}

ForgotPassword.propTypes = {
  onFormToggle: PropTypes.func.isRequired
}

export default ForgotPassword
